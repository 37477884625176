export default {
  checks: { welcome: true },
  createdDate: '2018-08-30T14:35:49.933Z',
  email: 'test@shareworks.nl',
  emails: ['test@shareworks.nl'],
  faculties: [],
  name: 'Test user (Mock)',
  language: 'en',
  systemAdmin: true,
  newsletter: false,
  type: 'user',
  _id: '59a6cd4545e319425568c45b',
  organizations: [{
    slug: 'share-u',
    brandColor: '#00a1e1',
    counts: { users: 0 },
    createdBy: null,
    createdByID: null,
    createdDate: '2017-08-30T12:43:40.531Z',
    emailDomains: [],
    faculties: [],
    lastModifiedDate: '2018-09-19T16:37:42.407Z',
    lms: 'canvas',
    ltiVersion: 'basic',
    saml: { domains: [], entityIds: [] },
    idps: [],
    name: { nl: 'Shareworks University', en: 'Shareworks University' },
    notificationEmail: 'info@shareworks.nl',
    role: 'admin',
    shortName: { nl: 'Share U', en: 'Share U' },
    siteUrl: 'https://projectcampus.instructure.com',
    defaultLanguage: 'en',
    languages: { en: true, nl: true },
    terminology: {
      faculty: { en: 'Faculty', nl: 'Faculteit' },
      faculties: { en: 'Faculties', nl: 'Faculteiten' },
      student: { en: 'Student', nl: 'Student' },
      students: { en: 'Students', nl: 'Studenten' },
      instructor: { en: 'Instructor', nl: 'Docent' },
      instructors: { en: 'Instructors', nl: 'Docenten' }
    },
    lmsConfig: {},
    type: 'organization',
    _id: '59a6b2fc52e5312bef1bf510'
  }]
}
