<template>
  <div>
    <el-alert type="info" class="mb-20" :title="$t('SW_SETUP_CATEGORIES')" show-icon
              :description="$t('SW_CATEGORY_INFO_TEXT', [$term('plans', 'lc')])"></el-alert>

    <!-- Create category -->
    <el-button type="primary" plain @click="addSet">
      <i class="icon-add"></i>
      <span class="hidden-xs">{{ $t('SW_CREATE_CATEGORY_SET') }}</span>
    </el-button>

    <h3 class="mt-30 mb-10">{{ $t('SW_MAIN_CATEGORIES') }}</h3>
    <draggable ghost-class="ghost" v-model="form.categories" group="sets" itemKey="index">
      <template #item="{element, index}">
        <el-card class="set-wrap category-flex mb-5">
          <div class="drag-button" v-if="form.categories.length > 1">
            <i class="icon-drag_handle"/>
          </div>

          <!-- EN category -->
          <div class="w-100" :class="{'half-category': form.languages.nl}" v-if="form.languages.en">
            <i v-if="form.categories.length > 1" class="icon-drag_handle"></i>
            <el-input class="bold" v-model="element.en" :placeholder="$t('SW_CATEGORY_SET_NAME', [index + 1])">
              <template #prepend>
                <img :src="'/img/en.png'" class="ml-5 language-icon" alt="English flag">
              </template>
            </el-input>
          </div>

          <!-- NL category -->
          <div class="w-100" :class="{'half-category': form.languages.en}" v-if="form.languages.nl">
            <el-input class="bold" v-model="element.nl" :placeholder="$t('SW_CATEGORY_SET_NAME', [index + 1])">
              <template #prepend>
                <img :src="'/img/nl.png'" class="ml-5 language-icon" alt="Netherlands flag">
              </template>
            </el-input>
          </div>
          <!-- Remove button -->
          <div>
            <el-button type="danger" @click="removeCategory(element._id)" plain class="ml-5 button-square">
              <i class="icon-delete"/>
            </el-button>
          </div>
        </el-card>
      </template>
    </draggable>

    <h3 class="mt-30 mb-10">{{ $t('SW_CATEGORIES') }}</h3>
    <!-- List Filter sets -->
    <masonry :cols="{default: 2, 1023: 1}" :gutter="{default: '30px', 1023: '20px'}" class="hide-categories">
      <div v-for="(category, index) in form.categories" class="box-card mb-30" :key="index">

        <!-- Name of filter set -->
        <strong class="block mb-5">{{ category[lang] }}</strong>

        <!-- List filters -->
        <draggable ghost-class="ghost" v-model="category.filters" group="categories" itemKey="index">
          <template #item="{element, index}">
            <el-card shadow="never" class="category-flex mb-5">
              <div class="drag-button" v-if="category.filters.length > 1">
                <i class="icon-drag_handle"/>
              </div>
              <div class="mr-5 w-100" :class="{'half-category': form.languages.nl}" v-if="form.languages.en">
                <el-input v-model="element.en" size="small" :placeholder="$t('SW_CATEGORY_NAME', [index + 1])">
                  <template #prepend>
                    <img :src="'/img/en.png'" class="language-icon" alt="English flag">
                  </template>
                </el-input>
              </div>
              <div class="w-100" :class="{'half-category': form.languages.en}" v-if="form.languages.nl">
                <el-input v-model="element.nl" size="small" :placeholder="$t('SW_CATEGORY_NAME', [index + 1])">
                  <template #prepend>
                    <img :src="'/img/nl.png'" class="language-icon" alt="Netherlands flag">
                  </template>
                </el-input>
              </div>
              <!-- Remove button -->
              <div>
                <el-button type="danger" @click="removeSubCategory(category._id, element._id)" size="small" plain class="ml-5 sub-category-delete">
                  <i class="icon-delete"/>
                </el-button>
              </div>
            </el-card>
          </template>
        </draggable>

        <!-- Add filter -->
        <el-button @click="addFilter(category)" class="block">
          <i class="icon-add"/>
          {{ $t('SW_ADD_CATEGORY') }}
        </el-button>
      </div>
    </masonry>
  </div>
</template>

<script>
import draggable from 'vuedraggable'

export default {
  name: 'OrgCategoriesSettings',
  props: ['form'],
  components: { draggable },

  data () {
    return {
      school: this.$store.state.school,
      user: this.$store.state.user,
      lang: this.$store.state.user.language
    }
  },

  methods: {
    removeSubCategory (mainCategoryId, filterId) {
      this.$confirm(this.$t('SW_DELETE_SUBCATEGORY_CONFIRM'), this.$t('SW_DELETE_SUBCATEGORY'), {
        confirmButtonText: this.$t('SW_DELETE'),
        cancelButtonText: this.$t('SW_CANCEL')
      }).then(() => {
        this.form.categories = this.form.categories.map(cat => {
          if (cat._id === mainCategoryId) {
            cat.filters = cat.filters.filter(subCat => subCat._id !== filterId)
          }
          return cat
        })

        this.$message({ type: 'success', message: this.$t('SW_DELETED_SUBCATEGORY') })
      })
    },
    removeCategory (id) {
      this.$confirm(this.$t('SW_DELETE_CATEGORY_CONFIRM'), this.$t('SW_DELETE_CATEGORY'), {
        confirmButtonText: this.$t('SW_DELETE'),
        cancelButtonText: this.$t('SW_CANCEL')
      }).then(() => {
        this.form.categories = this.form.categories.filter(cat => cat._id !== id)
        this.$message({ type: 'success', message: this.$t('SW_DELETED_CATEGORY') })
      })
    },
    addFilter (category) {
      const length = category.filters.length
      if (!length) {
        category.filters.push({ en: '', nl: '' })
      } else if (category.filters[length - 1].en !== '' || category.filters[length - 1].nl !== '') {
        category.filters.push({ en: '', nl: '' })
      }
    },
    addSet () {
      this.$message({ message: this.$t('SW_CATEGORY_SET_ADDED'), type: 'success' })
      this.form.categories.push({ nl: '', en: '', filters: [{ en: '', nl: '' }] })
    }
  }
}
</script>

<style lang="scss">
  @use 'style';
</style>
