<template>
  <div>
    <affix class="sticky-bar" relative-element-selector="#sticky-content" :offset="{ top: 128, bottom: -1000 }">
      <el-row type="flex" justify="center" align="middle">
        <el-col :xs="12" :sm="14">
          <div v-if="multipleSelection.length">
            <!-- Change status dropdown -->
            <el-badge :value="multipleSelection.length">
              <el-dropdown trigger="click" @command="handleStatus">
                <el-button type="primary" plain>
                  <span>{{ $t('SW_STATUS') }}</span>
                  <i class="icon-caret-down"/>
                </el-button>

                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item :command="{type: 'publish'}">
                      <span style="color: green">{{ $t('SW_PUBLISHED') }}</span>
                    </el-dropdown-item>
                    <el-dropdown-item :command="{type: 'unpublish'}">
                      <span style="color:#bd7b18;">{{ $t('SW_DRAFT') }}</span>
                    </el-dropdown-item>
                    <el-dropdown-item :command="{type: 'none'}">
                      <span style="color:#909399;">{{ $t('SW_RESET_APP') }}</span>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </el-badge>

            <el-badge :value="multipleSelection.length" class="hidden-xs">
              <el-button class="ml-5" type="primary" plain @click="addCategoriesDialog = true">
                <i class="icon-add"/>
              <span>{{ $t('SW_ADD_CATEGORIES') }}</span>
              </el-button>
            </el-badge>

            <!-- Remove if Admin -->
            <el-badge :value="multipleSelection.length" v-if="isAdmin">
              <el-dropdown @command="handleAppDeleting" class="ml-5" trigger="click">
                <el-button type="primary" plain>
                  <span>{{ $tc('SW_REMOVE_APP', multipleSelection.length) }}</span>
                  <i class="icon-caret-down"/>
                </el-button>

                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item command="pages">
                      <i class="icon-delete"/>
                      <span>{{ $tc('SW_REMOVE_APP_FROM_ORG', multipleSelection.length) }}</span>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </el-badge>

            <!-- REMOVE if admin -->
            <el-badge :value="multipleSelection.length" v-else>
              <el-button type="text" class="ml-10" @click="handleAppDeleting('pages')">
                <i class="icon-delete"/>
                <span class="hidden-xs">{{ $tc('SW_REMOVE_APP', multipleSelection.length) }}</span>
              </el-button>
            </el-badge>

            <!-- Clear selection -->
            <el-button type="text" class="ml-10" @click="selectionChange">
              <i class="icon-clear"/>
              <span class="hidden-xs hidden-sm">{{ $t('SW_CANCEL') }}</span>
            </el-button>
          </div>

          <div v-else>
            <!-- Create app -->
            <el-button type="primary" plain v-if="currentUser.systemAdmin" class="mr-10 button-square-xs"
                       @click="$router.push({ name: 'new-app', params: { slug: school.slug } })">
              <i class="icon-add"/>
              <span class="hidden-xs">{{ $t('SW_CREATE_APP') }}</span>
            </el-button>

            <div class="inline hidden-xs hidden-sm" v-if="status !== 'loading' || tableData.length">
              <!-- Total apps found -->
              <span class="hidden-xs hidden-sm line-height-38">
                {{ total }} {{ $t('SW_APPS').toLowerCase() }}<span class="ml-10 mr-10">&centerdot;</span>
              </span>

              <!-- Request new app -->
              <span class="hidden-xs hidden-sm">{{ $t('SW_MISS_AN_APP') }}</span>
              <a href="mailto:support@shareworks.nl" target="_blank" class="hidden-xs hidden-sm bold ml-10">
                <i class="icon-email"/>
                <span>{{ $t('SW_REQUEST_NEW_APP') }}</span>
              </a>
            </div>
          </div>
        </el-col>

        <el-col :xs="12" :sm="10">
          <!-- Search input -->
          <el-input v-model="searchText" clearable :placeholder="$t('SW_SEARCH_NAME_KEYWORD')" class="input-with-select">
            <template #prepend>
              <el-select v-model="statusFilter" clearable @change="changeFilter">
                <el-option v-for="item in statusTypes" :key="item.value" :label="$t('SW_' + item.label.toUpperCase())" :value="item.value"/>
              </el-select>
            </template>
          </el-input>
        </el-col>
      </el-row>
    </affix>
    <div class="bar-placeholder"/>

    <!-- Info box -->
    <el-alert type="info" class="mt-20" :title="$t('SW_MANAGE_EXPLAIN_TITLE')" v-if="!currentUser.checks.appsTableInfo"  @close="closedInfoBox" show-icon>
      <p class="mb-10">{{ $t('SW_MANAGE_EXPLAIN', [school.name[lang]]) }}</p>
    </el-alert>

    <!-- Table itself -->
    <el-table v-show="tableData.length" row-key="_id" ref="appsTable" @sort-change="sortChange" :data="tableData" class="mt-20"
              @selection-change="selectionChange" :default-sort="{prop: this.sort, order: this.order}" id="sticky-content">
      <!-- Expand -->
      <el-table-column type="expand" width="30">
        <template v-slot="props">
          <el-row type="flex">
            <el-col :span="12">
              <div class="mb-10" v-if="currentUser.systemAdmin"><strong class="mr-5">Page
                ID</strong> {{ props.row._id }}
              </div>
              <p class="mb-10">
                <strong class="mr-5">
                  {{ $t('SW_PAGE') }} {{ $t('SW_CREATED_DATE').toLowerCase() }}
                </strong>
                {{ $filters.fromNow(props.row.createdDate) }}
              </p>
              <p class="mb-10" v-if="props.row.modifiedBy">
                <strong class="mr-5">{{ $t('SW_MODIFIED_BY') }}</strong>
                <thumbnail :model="props.row.modifiedBy" class="thumb-user thumb-24"/>
                {{props.row.modifiedBy.name}} <span class="hidden-xs">(<a
                :href="'mailto:' + props.row.modifiedBy.email" target="_blank">{{ props.row.modifiedBy.email }}</a>)</span>
              </p>
            </el-col>
            <el-col :span="12"></el-col>
          </el-row>
        </template>
      </el-table-column>
      <!-- Selection checkboxes -->
      <el-table-column type="selection" reserve-selection width="35"/>
      <!-- Name -->
      <el-table-column property="name" :label="$t('SW_NAME_OF_APP')" min-width="200" :sort-method="sortCaseInsensitive" sortable>
        <template v-slot="props">
          <router-link :to="{name: 'app', params: {id: props.row._id, slug: school.slug }}" class="text-ellipsis block">
            <thumbnail :model="props.row" class="app-thumb thumb-24"/>
            <strong class="ml-10">{{ props.row.name }}</strong>
          </router-link>
        </template>
      </el-table-column>

      <!-- Edit/create app page -->
      <el-table-column width="100" :label="$t('SW_EDIT')">
        <template v-slot="props">
          <!-- Edit app page -->
          <el-button type="text" size="small" @click="openEditDialog(props.row._id)">
            <i class="icon-pencil"/>
            <span>{{ $t('SW_EDIT') }}</span>
          </el-button>
        </template>
      </el-table-column>

      <!-- Status -->
      <el-table-column width="120" :label="$t('SW_STATUS')">
        <template v-slot="props">
          <div :class="getStatusColor(props.row.status)" v-if="props.row.status !== 'none'">
            <strong>{{ $t('SW_' + props.row.status.toUpperCase()) }}</strong>
          </div>
          <span v-else class="text-muted">-</span>
        </template>
      </el-table-column>

      <!-- Categories -->
      <el-table-column v-if="!['available', 'dismissed'].includes(statusFilter)" property="categories" min-width="180" :label="$t('SW_CATEGORIES')">
        <template v-slot="props">
          <div class="text-ellipsis" v-if="props.row.categories && props.row.categories.length">

            <span v-if="!needShowPopover(props.row.categories)">{{ props.row.categories[0].filters[0][lang] }} </span>
            <el-popover v-else placement="top" trigger="click">
              <template #reference>
                <span class="reference-text">{{ getCategoriesAmount(props.row.categories) }} {{ $t('SW_CATEGORIES_LOWER')}}</span>
              </template>

              <span v-for="category in props.row.categories" :key="category._id">
                <span v-for="cat in category.filters" :key="cat.en">
                  {{ cat[lang] }}
                </span>
              </span>
            </el-popover>

          </div>
          <span v-else class="text-muted">-</span>
        </template>
      </el-table-column>

      <!-- Attachment count -->
      <el-table-column :label="$t('SW_ATTACHMENTS')" v-if="!['available', 'dismissed'].includes(statusFilter)" prop="attachments.length" width="90">
        <template v-slot="props">
            <i class="icon-attach_file"/>
            <span v-if="props.row.attachments && props.row.attachments.length">{{ props.row.attachments.length }}</span>
            <span v-else>0</span>
        </template>
      </el-table-column>

      <!-- View count -->
      <el-table-column :label="$t('SW_VIEWS')" prop="views" width="90">
        <template v-slot="props">
            <i class="icon-seen"/>
            {{ props.row.views }}
        </template>
      </el-table-column>

      <!-- Follower count -->
      <el-table-column :label="$t('SW_FOLLOWERS')" prop="counts.followers" width="90">
        <template v-slot="props">
            <i class="icon-heart"/>
            {{ props.row.counts.followers }}
        </template>
      </el-table-column>

      <!-- Experience count -->
      <el-table-column :label="$t('SW_EXPERIENCES')" prop="counts.experiences" width="90">
        <template v-slot="props">
            <i class="icon-comment"/>
            {{ props.row.counts.experiences }}
        </template>
      </el-table-column>

      <!-- Last modified date -->
      <el-table-column property="modifiedDate" v-if="statusFilter !== 'available'" :formatter="dateFormatter" :label="$t('SW_UPDATED')" min-width="160" sortable/>

      <template #append>
        <infinite-loading ref="infiniteLoading" @infinite="getApps(false)">
          <template #spinner><span/></template>
          <template #no-results><span/></template>
          <template #no-more><span/></template>
        </infinite-loading>
      </template>
    </el-table>

    <table-status :status="status" :noneText="$t('SW_NO_APPS_FOUND')" @clearSearch="searchText = ''"/>

    <!-- Edit app page dialog -->
    <el-dialog custom-class="edit-app-dialog" @close="closeEditDialog" show-close v-model="editDialog" :close-on-click-modal="false" :close-on-press-escape="false">
      <edit-app :closeEditDialog="closeEditDialog" :id="currentAppId"/>
    </el-dialog>

    <!-- Edit app -->
    <el-dialog custom-class="edit-app-dialog" @close="closeEditApp(null)" show-close v-model="editAppDialog" :close-on-click-modal="false" :close-on-press-escape="false">
      <edit-app :id="this.editAppId" :closeEditApp="closeEditApp"/>
    </el-dialog>

    <!-- Add categories for multiple apps -->
    <el-dialog :title="$t('SW_ADD_CATEGORIES')" show-close v-model="addCategoriesDialog">
      <div>
        <el-alert type="info" show-icon :description="$t('SW_ADD_CATEGORIES_TEXT')"/>

        <!-- Categories -->
        <el-form>
          <el-form-item class="mt-10" :label="$t('SW_SELECTED_APPS')">
              <p class="w100">
                <el-tag class="mr-5" :key="app" v-for="app in selectedApps">{{ app }}</el-tag>
              </p>
          </el-form-item>

          <el-form-item v-for="category in school.categories" :key="category._id" :label="category[lang]">
            <el-select class="w100" v-model="selectedFilters[category._id]" multiple filterable :placeholder="$t('SW_SELECT_CATEGORIES')">
              <el-option v-for="filter in category.filters" :key="filter._id" :label="filter[lang]" :value="filter._id"/>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-button type="primary">
              <span @click="addCategories">{{ $t('SW_SAVE_CHANGES') }}</span>
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import EditApp from '@/pages/EditApp'

export default {
  name: 'AppsTable',
  components: { EditApp },

  data () {
    return {
      isAdmin: this.$store.state.isAdmin,
      editAppId: null,
      currentAppId: null,
      editAppDialog: false,
      addCategoriesDialog: false,
      selectedFilters: {},
      editDialog: false,
      status: false,
      school: this.$store.state.school,
      skip: false,
      total: false,
      submitting: false,
      searchText: '',
      sort: 'name',
      order: 'ascending',
      statusTypes: [
        { label: 'all', value: 'all' },
        { label: 'published', value: 'published' },
        { label: 'draft', value: 'draft' }
      ],
      statusFilter: this.$route.query.filter || 'all',
      multipleSelection: [],
      lang: this.$store.state.user.language || 'en',
      tableData: [],
      currentRow: null,
      currentUser: this.$store.state.user,
      command: ''
    }
  },

  computed: {
    selectedApps () { return this.multipleSelection.map(app => app.name) }
  },

  watch: {
    searchText: debounce(function () {
      this.$router.replace({ name: 'admin', query: { query: this.searchText, filter: this.statusFilter }, params: { mode: 'apps' } })
    }, 400),
    $route: {
      immediate: false,
      flush: 'post',
      handler (route) {
        if (route.params.mode !== 'apps') return
        this.selectionChange()
        this.getApps(true)
      }
    }
  },

  mounted () {
    this.getApps()
  },

  methods: {
    getApps (refresh) {
      if (this.status === 'loading') return

      if (refresh) {
        this.$refs.infiniteLoading.stateChanger.reset()
        this.tableData = []
        this.skip = 0
      }

      this.status = 'loading'
      const params = {
        amount: 20,
        organization: this.school._id,
        sort: this.sort,
        order: this.order === 'ascending' ? (this.sort === 'name' ? '1' : '-1') : (this.sort === 'name' ? '-1' : '1')
      }

      if (this.skip) params.skip = this.skip
      if (this.searchText) params.filter = this.searchText
      if (['published', 'draft'].includes(this.statusFilter)) params.status = this.statusFilter

      this.$http.get('pages', { params }).then(
        res => {
          this.tableData = this.tableData.concat(res.data.list)

          this.status = res.data.total ? (res.data.done ? 'done' : 'incomplete') : (this.searchText ? 'noResults' : 'none')
          this.skip = res.data.skip
          this.total = res.data.total

          if (res.data.done) {
            this.$refs.infiniteLoading.stateChanger.loaded()
            this.$refs.infiniteLoading.stateChanger.complete()
          } else {
            this.$refs.infiniteLoading.stateChanger.loaded()
          }
        })
        .catch((e) => {
          this.$notifyBug(e)
          this.status = 'error'
        })
    },
    addCategories () {
      const chosenApps = this.tableData.map(appPage => {
        if (this.selectedApps.includes(appPage.name)) return appPage
      })

      const filteredApps = chosenApps.filter(Boolean)

      this.$http.put('pages', { pages: filteredApps, categories: this.selectedFilters })
        .then(() => {
          this.multipleSelection = []
          this.selectedFilters = {}
          this.addCategoriesDialog = false
          this.selectionChange()
          this.getApps(true)
          this.$message({ type: 'success', message: this.$t('SW_CATEGORIES_UPDATED') })
        })
        .catch((e) => {
          this.$notifyBug(e)
          this.$message({ type: 'error', message: this.$t('SW_GENERIC_ERROR') })
        })
    },
    openEditApp (id) {
      this.editAppId = id
      this.editAppDialog = true
    },
    closeEditApp (app) {
      if (app) {
        this.tableData.forEach(el => {
          if (el._id === app._id) {
            el = app
            el.name = app.name
          }
        })
      }

      this.editAppDialog = false
    },
    closeEditDialog (updatedApp) {
      if (updatedApp) {
        const index = this.tableData.findIndex(app => app._id === updatedApp._id)
        if (index === -1) this.editDialog = false
        this.tableData[index] = { ...updatedApp, counts: this.tableData[index].counts }
      }

      this.editDialog = false
    },
    openEditDialog (id) {
      this.editDialog = true
      this.currentAppId = id
    },
    getCategoriesAmount (categories) {
      const allCategories = []

      categories.forEach(cat => allCategories.push(...cat.filters))
      return allCategories.length
    },
    needShowPopover (categories) {
      const allCategories = []

      categories.forEach(cat => allCategories.push(...cat.filters))
      return allCategories.length > 1
    },
    handleStatus (command) {
      this.currentRow = command.row
      if (command.type === 'publish') this.confirmPublish()
      else if (command.type === 'unpublish') this.confirmUnpublish()
      else this.confirmClear()
    },
    handleAdvice (command) {
      this.currentRow = command.row
      if (command.type === 'dismiss') this.toggleAdvice('dismissed')
      else if (command.type === 'recommend') this.toggleAdvice('recommended')
      else if (command.type === 'warn') this.toggleAdvice('warning')
      else this.toggleAdvice('none')
    },
    confirmPublish () {
      this.$confirm(this.$t('SW_PUBLISH_CONFIRM'), this.$t('SW_PUBLISH'), {
        confirmButtonText: this.$t('SW_PUBLISH'),
        cancelButtonText: this.$t('SW_CANCEL')
      }).then(() => { this.toggleStatus('published') })
    },
    confirmUnpublish () {
      this.$confirm(this.$t('SW_UNPUBLISH_CONFIRM'), this.$t('SW_UNPUBLISH'), {
        confirmButtonText: this.$t('SW_UNPUBLISH'),
        cancelButtonText: this.$t('SW_CANCEL')
      }).then(() => { this.toggleStatus('draft') })
    },
    confirmClear () {
      this.$confirm(this.$t('SW_CLEAR_CONFIRM'), this.$t('SW_CLEAR_APPS'), {
        confirmButtonText: this.$t('SW_CLEAR_APPS'),
        cancelButtonText: this.$t('SW_CANCEL')
      }).then(() => { this.toggleStatus('none') })
    },
    handleAppDeleting (command) {
      this.command = command
      this.confirmDelete(this.multipleSelection)
    },
    confirmDelete (app) {
      this.$confirm(this.$t('SW_DELETE_CONFIRM'), this.$t('SW_DELETE'), {
        confirmButtonText: this.$t('SW_DELETE'),
        cancelButtonText: this.$t('SW_CANCEL')
      })
        .then(() => {
          const isList = Array.isArray(app)
          const appList = isList ? app : [app]
          const ids = appList.map(el => el._id)
          this.deleteApp(ids)
        })
    },
    deleteApp (appList) {
      this.submitting = true
      const path = 'pages'
      const params = { pages: appList }

      this.$http.delete(path, { params })
        .then(() => {
          this.removeFromTable(appList)
          this.multipleSelection = []
          this.$message({ type: 'success', message: this.$t('SW_TOOL_DELETED') })
        })
        .catch((e) => {
          this.$notifyBug(e)
          this.$message({ type: 'error', message: this.$t('SW_GENERIC_ERROR') })
        })
        .finally(() => { this.submitting = false })
    },

    removeFromTable (appIds) {
      this.tableData = this.tableData.filter(page => !appIds.find(_id => page._id === _id))
      this.skip = this.skip - appIds.length
      this.$emit('refreshTable')

      if (this.tableData.length === 0) {
        this.status = 'none'
      }

      this.command = ''
      this.total = this.tableData.length
    },

    toggleStatus (type) {
      const toSend = []
      for (const selection of this.multipleSelection) {
        selection.status = type
        toSend.push({ status: selection.status, _id: selection._id })
      }
      this.submitting = true
      this.$http.put('pages', { pages: toSend })
        .then(res => {
          this.$message({ type: 'success', message: this.$t('SW_APP_' + type.toUpperCase()) })
          this.selectionChange()
        })
        .catch((e) => {
          this.$notifyBug(e)
          this.$message({ type: 'error', message: this.$t('SW_GENERIC_ERROR') })
        })
        .finally(() => { this.submitting = false })
    },
    dateFormatter (row, column, value) { return this.$filters.fromNow(value) },
    changeFilter (filter) {
      this.$router.replace({ name: 'admin', query: { query: this.searchText, filter }, params: { mode: 'apps' } })
    },
    closedInfoBox () {
      this.$store.state.user.checks.appsTableInfo = true
      this.$http.put(`users/${this.currentUser._id}`, { checks: this.$store.state.user.checks })
        .then((res) => { /*  User checks updated! */ })
    },
    selectionChange (val) {
      if (Array.isArray(val)) {
        this.multipleSelection = val
        return
      }

      this.multipleSelection = []
      if (this.$refs.appsTable) this.$refs.appsTable.clearSelection()
    },
    sortChange (val) {
      if (val.prop === this.sort && val.order === this.order) return
      this.sort = val.prop
      this.order = val.order
      if (this.status !== 'done') this.getApps(true)
    },
    getTagColor (status) {
      if (status === 'recommended') return 'success'
      if (status === 'warning') return 'warning'
      if (status === 'dismissed') return 'danger'
      return 'info'
    },
    getStatusColor (status) {
      if (status === 'published') return 'text-success'
      if (status === 'draft') return 'text-warning'
      return ''
    },
    sortCaseInsensitive (a = {}, b = {}) {
      let appName1 = a.name || ''
      let appName2 = b.name || ''

      appName1 = appName1.toLowerCase()
      appName2 = appName2.toLowerCase()

      if (appName1 < appName2) return -1
      if (appName1 > appName2) return 1
      return 0
    }
  }
}
</script>

<style lang="scss">
  @use 'style';
</style>
