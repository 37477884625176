<template>
  <div class="mb-20">
    <a href="#" @click.prevent="viewProfile">
      <thumbnail :model="comment.createdBy" class="thumb-user thumb-60 pull-left"/>
    </a>
    <div class="comment-body">
      <div class="bold font-16">
        <a href="#" @click.prevent="viewProfile">
          {{comment.createdBy.name}}
        </a>
      </div>
      <div class="text-muted font-13 mb-5">
        <span>{{ $filters.fromNow(comment.createdDate, true) }}</span>
        <span v-if="comment.can.update">&nbsp;&centerdot;&nbsp;</span>
        <el-tooltip :content="$t('SW_EDIT_COMMENT')" :enterable="false" v-if="comment.can.update">
          <el-button type="text" size="small" @click="editComment(comment)">
            <i class="icon-pencil"></i>
          </el-button>
        </el-tooltip>
        <span v-if="comment.can.remove">&nbsp;&centerdot;&nbsp;</span>
        <el-tooltip :content="$t('SW_DELETE_COMMENT')" :enterable="false" v-if="comment.can.remove">
          <el-button type="text" size="small" @click="confirmRemove">
            <i class="icon-delete"></i>
          </el-button>
        </el-tooltip>
      </div>

      <p class="comment">
        <span class="comment-text">{{ comment.description }}</span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CommentTile',
  props: ['comment', 'app', 'removeComment', 'editComment'],

  data () {
    return {
      dialogDelete: false,
      dialogEmail: true,
      showMore: false,
      school: this.$store.state.school,
      lang: this.$store.state.user.language,
      currentUser: this.$store.state.user
    }
  },

  methods: {
    confirmRemove () {
      this.$confirm(this.$t('SW_DELETE_COMMENT_CONFIRM'), this.$t('SW_DELETE_COMMENT'), {
        confirmButtonText: this.$t('SW_DELETE'),
        cancelButtonText: this.$t('SW_CANCEL')
      }).then(() => { this.removeComment(this.comment) })
    },
    closeDialog () {
      this.dialogEmail = false
    },
    viewProfile () {
      this.$router.push({
        name: 'profile',
        params: { id: this.comment.createdBy._id, slug: this.school.slug }
      })
    }
  }
}
</script>

<style lang="scss">
  @use 'style';
</style>
