<template>
  <div>
    <div class="attachment-tile">
      <el-card :body-style="{ padding: '0px', height: '150px' }">
        <div class="card-body">
          <div class="attachment-visual" v-if="isImage">
            <img :src="previewImage()" @click="showEmbed = true" class="image" alt="Attachment image">
          </div>

          <div class="attachment-visual" v-if="isDocument" @click="documentViewer">
            <img :src="previewImage()" class="preview-image" alt="Attachment document">
          </div>

          <div class="attachment-visual" :class="{'visual-link': !attachment.thumbnailUrl}" v-if="isEmbed" @click="handleEmbed">
            <img v-if="attachment.thumbnailUrl" :src="attachment.thumbnailUrl" class="image" alt="Attachment link">
          </div>

          <a href class="card-title" @click.prevent="triggerAttachment">
            <i :class="getIcon()"></i>
            <span class="ml-5">{{ attachment.title }}</span>
          </a>
        </div>
      </el-card>
    </div>

    <!-- Image dialog -->
    <el-dialog :title="attachment.title" append-to-body v-model="showEmbed">
      <img v-if="isImage" :src="previewImage()" class="embed-responsive">
      <img v-if="isEmbed && attachment.mediaType === 'photo'" :src="attachment.url" class="embed-responsive">
      <div class="embed-responsive" v-if="isEmbed && attachment.mediaType === 'video' && attachment.mediaHtml && showEmbed" v-html="attachment.mediaHtml"/>
    </el-dialog>
  </div>
</template>

<script>
import * as filestack from 'filestack-js'
import secureFilestackUrl from '@/edpack-web/utils/filestack/secure-url'

export default {
  name: 'AttachmentTile',
  props: ['attachment'],
  components: {},

  data () {
    return {
      showEmbed: false,
      user: this.$store.state.user,
      isEmbed: this.attachment.attachmentType === 'link',
      isImage: this.attachment.attachmentType !== 'link' && this.attachment.mimeType.includes('image'),
      isDocument: this.attachment.attachmentType !== 'link' && this.$config.filestack.supported.find(sup => { return this.attachment.title.endsWith(sup) }),
      isOther: false,
      filestack: filestack.init(this.$config.filestack.key)
    }
  },

  created () {
    if (!this.isEmbed && !this.isImage && !this.isDocument) this.isOther = true
  },

  methods: {
    handleEmbed () {
      if (this.attachment.attachmentType === 'link') {
        if (this.attachment.mediaType === 'video' && this.attachment.mediaHtml) {
          this.showEmbed = true
          return
        }
        if (this.attachment.mediaType === 'photo') {
          this.showEmbed = true
          return
        }
        const win = window.open(this.attachment.url, '_blank')
        win.focus()
      }
    },
    triggerAttachment () {
      if (this.isImage) {
        this.showEmbed = true
        return
      }
      if (this.isDocument) {
        return this.documentViewer()
      } else {
        this.handleEmbed()
      }
    },
    getIcon () {
      if (this.attachment.attachmentType === 'link') {
        if (this.attachment.mediaType === 'video') return 'icon-video'
        return 'icon-link'
      }
      if (this.attachment.mimeType.includes('image')) return 'icon-image'
      if (this.$config.filestack.supported.find(sup => { return this.attachment.title.endsWith(sup) })) return 'icon-file'
      return 'el-icon-upload2'
    },
    previewImage () {
      if (this.isImage) return secureFilestackUrl(this.attachment.url, this.user)
      if (this.isDocument) return secureFilestackUrl(this.attachment.handle, this.user, true)
    },
    documentViewer () {
      const security = { policy: this.user.filestack.policy, signature: this.user.filestack.signature }
      this.filestack.setSecurity(security)
      this.filestack.preview(this.attachment.handle, {})
    }
  }
}
</script>

<style lang="scss">
  @use 'style';
</style>
