<template>
  <el-form label-position="top">
    <p class="mb-10">
      <span v-if="app">{{ $t('SW_EXPERIENCE_INVITE_APP', [app.name]) }}</span>
    </p>

    <!-- User email -->
    <el-form-item :label="$tc('SW_EMAIL', 1)">
      <el-input :placeholder="$tc('SW_EMAIL', 1)" v-model="emailForm.userEmail"/>
    </el-form-item>

    <el-form-item>
      <el-checkbox :label="$t('SW_SEND_TO_SELF')" v-model="emailForm.toSelf" name="type"/>
    </el-form-item>

    <!-- Send or cancel -->
    <el-form-item>
      <el-button type="primary" @click="sendEmail" :loading="submitting">
        <i class="icon-send"/>
        {{ $t('SW_SEND_EMAIL') }}
      </el-button>
      <el-button type="text" @click="closeDialog()">{{ $t('SW_CANCEL') }}</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  name: 'EmailDialog',
  props: ['app', 'closeDialog'],

  data () {
    return {
      school: this.$store.state.school,
      lang: this.$store.state.user.language,
      currentUser: this.$store.state.user,
      submitting: false,
      emailForm: {
        userEmail: '',
        toSelf: true
      }
    }
  },

  mounted () {
    if (this.app) {
      this.emailForm.subject = this.$t('SW_EMAIL_APP_SUBJECT', [this.app.name])
    }
  },

  methods: {
    sendEmail () {
      // Basic validation
      if (!this.emailForm.userEmail) {
        return this.$message({ message: this.$t('SW_FILL_EMAIL'), type: 'error' })
      }

      const userEmail = this.emailForm.userEmail
      const toSelf = this.emailForm.toSelf
      const id = this.app._id

      this.submitting = true

      this.$http.post(`pages/${id}/invite/experience`, {}, {
        params: {
          userEmail,
          toSelf
        }
      })
        .then(res => {
          this.submitting = false
          this.emailForm = {
            userEmail: '',
            toSelf: true
          }
          this.$message({ message: this.$t('SW_EXPERIENCE_INVITATION_SENT'), type: 'success' })
          this.closeDialog(false)
        }).catch(err => {
          this.$notifyBug(err)
          if (err && err.data && err.data.errors && err.data.errors[0] && err.data.errors[0].msg && err.data.errors[0].translation.includes('NOT_FOUND')) {
            this.$message({ message: this.$t('SW_USER_NOT_FOUND'), type: 'error' })
          } else {
            this.$message({ message: this.$t('SW_GENERIC_ERROR'), type: 'error' })
          }
          this.submitting = false
        })
    }
  }
}
</script>

<style lang="scss">
  @use 'style';
</style>
