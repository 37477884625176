<template>
  <div class="app-footer">
    <a v-if="school.logoUrl && school.websiteUrl" :href="school.websiteUrl">
      <img :src="logoImage()" width="160"/>
    </a>

    <img v-else-if="school.logoUrl" :src="logoImage()" width="160"/>

    <!-- Credit -->
    <div class="mt-20 credits">
      <div class="mb-20" v-if="school && school.colofon && school.colofon[lang]">{{ $t('SW_AS_CLIENT_OF') }} {{ school.name[lang] }} &centerdot;
        <a class="footer-link" href="#" v-if="school && school.colofon" @click.prevent="toggleColofon">{{ $t('SW_COLOFON') }}</a>
      </div>

      &copy; {{ (new Date()).getFullYear() }} &centerdot;

      <a v-if="aboutUrl" class="footer-link" target="_blank" rel="noreferrer" :href="aboutUrl">{{ productName }}</a>
      <a v-else href="#" class="footer-link" @click.prevent="openPage('about')">{{ productName }}</a>

      <span>&nbsp;{{ $t('SW_IS_BUILT_BY') }}&nbsp;</span>
      <a :href="business.url" rel="noreferrer" class="footer-link" target="_blank">{{ business.shortName }}</a>.
      <p class="mb-20">
        <el-button type="text" aria-label="Open terms page" class="footer-link" @click="openPage('terms')">
          {{ $t('SW_TERMS') }}
        </el-button> |
        <el-button type="text" aria-label="Open privacy page" class="footer-link" @click="openPage('privacy')">
          {{ $t('SW_PRIVACY') }}
        </el-button> |
        <el-button type="text" aria-label="Open contact us dialog" class="footer-link" @click="openContact">
          {{ $t('SW_CONTACT_US') }}
        </el-button>

        <skip-to-main/>
      </p>

      <!-- About logo -->
      <a v-if="aboutUrl" :href="aboutUrl" target="_blank">
        <span class="footer-logo"/>
      </a>
      <router-link v-else to="/about" target="_blank" aria-hidden="true" tabindex="-1">
        <span class="footer-logo"/>
      </router-link>
    </div>

    <!-- Colofon dialog -->
    <el-dialog :title="$t('SW_COLOFON')" v-if="school && school.colofon" append-to-body v-model="dialogColofon">
      <div class="redactor-in" v-html="school.colofon[lang]"/>
    </el-dialog>
  </div>
</template>

<script>
import secureFilestackUrl from '@/edpack-web/utils/filestack/secure-url'

export default {
  name: 'AppFooter',

  data () {
    return {
      aboutUrl: this.$config.aboutUrl,
      dialogColofon: false,
      productName: this.$config.name,
      school: this.$store.state.school,
      lang: this.$store.state.lang,
      business: this.$config.business
    }
  },

  methods: {
    logoImage () { return secureFilestackUrl(this.school.logoUrl, this.$store.state.user) },
    openPage (page) { this.$router.push({ name: page }) },
    openContact () { this.$store.dispatch('setContactForm', true) },
    toggleColofon () { this.dialogColofon = !this.dialogColofon }
  }
}
</script>

<style lang="scss">
  @use 'style';
</style>
