<template>
  <page-cover class="app-page-header">
    <!-- App thumb -->
    <div class="page-thumb pull-left">
      <thumbnail :model="appPage" :large="true" class="app-thumb thumb-80"/>
    </div>

    <div class="no-overflow">
      <div class="pull-right">
        <!-- Edit button -->
        <el-button class="button-square-xs" v-if="user.role === 'admin' || user.systemAdmin"
                   @click="$router.push({ name: 'edit-page', params: { id: appPage._id, slug: school.slug } })">
          <i class="icon-pencil"/>
          <span class="hidden-xs hidden-sm">{{ $t('SW_EDIT') }}</span>
        </el-button>

        <app-language class="ml-10"/>
      </div>

      <!-- App title -->
      <h1 class="app-title">
        {{ appPage.name }}
      </h1>

      <!-- Description -->
      <div class="app-sub mt-5 mb-5" v-if="appPage.description && appPage.description[lang]" v-html="appPage.description[lang]"/>

      <!-- Page details -->
      <app-page-info class="mt-10" :collapsed="collapsed" :app="appPage"/>

      <!-- Show details button -->
      <a href="#" @click.prevent="toggleCollapsed">
        <span v-if="!collapsed">{{ $t('SW_HIDE_DETAILS') }}</span>
        <span v-if="collapsed">{{ $t('SW_MORE_DETAILS') }}</span>
        <span :class="{'icon-caret-up': !collapsed, 'icon-caret-down': collapsed}"/>
      </a>

    </div>

    <div class="header-btns">
      <!-- Follow button -->
      <el-button-group class="mr-10">
        <el-tooltip :content="appPage.following ? $t('SW_FOLLOWER_TIP'): $t('SW_FOLLOW_TIP')" :enterable="false">
          <el-button class="button-square-xs" type="primary" @click="toggleFollow" :loading="submitting" :disabled="appPage.status === 'dismissed'">
            <i class="icon-heart" :class="{'text-danger': appPage.following}"/>
            <span class="hidden-xs">{{ appPage.following ? $t('SW_FOLLOWER') : $t('SW_FOLLOW') }}</span>
          </el-button>
        </el-tooltip>

        <!-- Followers count -->
        <el-button type="primary" :disabled="appPage.status === 'dismissed'" @click="dialogFollowers = !dialogFollowers">
          <div class="hidden-xs" v-if="appPage.counts.followers">
            <span class="text-faded mr-5">{{ $t('SW_FOLLOWERS') }}:</span>
            <el-tooltip v-for="follower in appPage.followers.slice(0, 2)" :content="follower.user.name" :enterable="false" :key="follower.user._id">
              <thumbnail :model="follower.user" class="hidden-xs thumb-user follow-thumb thumb-24"/>
            </el-tooltip>
            <span class="not-bold" v-if="appPage.counts.followers > 4"> +{{ appPage.counts.followers - 4}}</span>
          </div>

          <div v-else class="hidden-xs text-faded">0</div>
          <span class="visible-xs">
            <i class="icon-users"/>{{appPage.counts.followers}}
          </span>
        </el-button>
      </el-button-group>

      <!-- Download -->
      <el-button type="primary" plain>
        <i class="icon-download ml-5"/>
        <strong>{{ $t('SW_DOWNLOAD') }}</strong>
      </el-button>
    </div>

    <!-- Followers dialog -->
    <el-collapse-transition>
      <div v-if="dialogFollowers">
        <div class="followers-dialog">
          <h3 class="mb-10">{{ $t('SW_FOLLOWERS') }}</h3>
          <followers-dialog :app="appPage" :closeDialog="closeDialog"/>
        </div>
      </div>
    </el-collapse-transition>
  </page-cover>
</template>

<script>
import AppPageInfo from '@/components/AppPageInfo'
import FollowersDialog from '@/components/FollowersDialog'

export default {
  name: 'AppPageHeader',
  props: ['appPage'],
  components: { AppPageInfo, FollowersDialog },

  data () {
    return {
      user: this.$store.state.user,
      school: this.$store.state.school,
      collapsed: true,
      lang: this.$store.state.user.language,
      submitting: false,
      dialogFollowers: false,
      dialogExperience: false
    }
  },

  methods: {
    handleCommand (command) { window.open(command) },
    closeDialog () { this.dialogFollowers = false },
    toggleCollapsed () { this.collapsed = !this.collapsed },

    toggleFollow () {
      this.submitting = true

      // Unfollow app
      if (this.appPage.following) {
        return this.$http.put(`pages/${this.appPage._id}/subscription/unfollow`, {}, { params: { organization: this.school._id } }).then(
          () => {
            this.$message({ type: 'success', message: this.$t('SW_APP_UNFOLLOWED') })
            this.appPage.following = false
            this.appPage.counts.followers--
            this.appPage.followers = this.appPage.followers.filter(follower => follower.user._id !== this.user._id)
            this.submitting = false
          }).catch(
          (e) => {
            this.$notifyBug(e)
            this.$message({ type: 'error', message: this.$t('SW_GENERIC_ERROR') })
            this.submitting = false
          })
      }

      // Follow app
      this.$http.put(`pages/${this.appPage._id}/subscription/follow`, {}, { params: { organization: this.school._id } })
        .then(() => {
          this.$message({ type: 'success', message: this.$t('SW_APP_FOLLOWED') })
          this.appPage.following = true
          this.appPage.counts.followers++
          this.appPage.followers.push({
            user: this.user,
            followingDate: new Date()
          })
          this.submitting = false
        })
        .catch((e) => {
          this.$notifyBug(e)
          this.$message({ type: 'error', message: this.$t('SW_GENERIC_ERROR') })
          this.submitting = false
        })
    }
  }
}
</script>

<style lang="scss">
  @use 'style';
</style>
