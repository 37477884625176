<template>
  <div>
    <p class="mb-20">{{ $t('SW_COMMENTS_TEXT', [$term('plan', 'lc')]) }}</p>

    <comment-tile v-for="comment in comments" :key="comment._id" :plan="plan" :comment="comment" :editComment="editComment" :removeComment="removeComment"/>

    <!-- None yet -->
    <div v-if="!comments.length && !loading" class="text-muted">
      <p>{{ $t('SW_NO_COMMENTS') }}</p>

      <el-button type="primary" v-if="canCreate" @click="createComment" class="mt-20" size="small">
        <i class="icon-comment"/>
        {{ $t('SW_BE_FIRST') }}
      </el-button>
    </div>

    <div v-if="comments.length">
      <!-- Show more comments -->
      <el-button size="small" @click="showMore" :disabled="loading" v-if="total > limit">
        <span>{{ $t('SW_MORE') }}</span>
        <span class="text-muted">&nbsp;+{{ total - limit }}</span>
      </el-button>

      <!-- Share comment -->
      <el-button type="primary" v-if="canCreate" @click="createComment">
        <i class="icon-add"/>
        <span>{{ $t('SW_SHARE_COMMENT') }}</span>
      </el-button>
    </div>

    <!-- Create comment dialog -->
    <el-dialog :title="$t('SW_SHARE_COMMENT')" append-to-body v-model="dialogComment">
      <comment-dialog v-if="dialogComment" :form="form" :closeDialog="closeDialog"/>
    </el-dialog>

    <!-- Edit dialog -->
    <el-dialog :title="$t('SW_EDIT_COMMENT')" append-to-body v-model="dialogEdit">
      <comment-dialog v-if="dialogEdit" :form="form" :closeDialog="closeDialog"/>
    </el-dialog>

    <!-- Loading -->
    <spinner v-if="loading"/>
  </div>
</template>

<script>
import CommentDialog from '@/components/CommentDialog'
import CommentTile from '@/components/CommentTile'

export default {
  name: 'Comments',
  props: ['plan', 'user', 'startLimit'],
  components: { CommentDialog, CommentTile },

  data () {
    return {
      canCreate: this.$store.state.user.role !== 'student',
      currentUser: this.$store.state.user,
      lang: this.$store.state.user.language,
      school: this.$store.state.school,
      dialogComment: false,
      dialogEdit: false,
      comments: [],
      form: false,
      total: false,
      loading: false,
      skip: 0,
      limit: this.startLimit
    }
  },

  mounted () {
    this.getComments(this.limit)
  },

  methods: {
    getComments (amount) {
      const params = { amount }

      if (this.school) params.organization = this.school._id
      if (this.plan) params.plan = this.plan._id
      if (this.user) params.user = this.user._id
      if (this.skip) params.skip = this.skip

      this.loading = true
      this.$http.get('comments', { params }).then(res => {
        this.loading = false
        this.total = res.data.total
        this.skip = res.data.skip
        this.comments = this.comments.concat(res.data.list)
      })
    },
    closeDialog (comment) {
      this.dialogComment = false
      this.dialogEdit = false

      if (comment) {
        this.form = false

        const index = this.comments.findIndex(ex => ex._id === comment._id)
        // Update comment or add comment
        if (index > -1) {
          comment.createdBy = this.comments[index].createdBy
          this.comments[index] = comment
        } else {
          this.comments.unshift(comment)
          this.total++
          this.limit++
        }
      }
    },
    showMore () {
      const newLimit = this.limit + 5
      this.limit = newLimit
      this.getComments(newLimit)
    },
    editComment (comment) {
      this.form = JSON.parse(JSON.stringify(comment))
      this.dialogEdit = true
    },
    createComment () {
      const newForm = {
        organization: this.$store.state.school,
        plan: this.plan,
        createdBy: this.$store.state.user,
        description: ''
      }

      if (!this.form) this.form = JSON.parse(JSON.stringify(newForm))
      this.dialogComment = true
    },
    removeComment (item) {
      this.$http.delete(`comments/${item._id}`)
        .then(() => {
          this.$message({ type: 'success', message: this.$t('SW_COMMENT_DELETED') })
          this.comments = this.comments.filter(comment => comment._id !== item._id)
          this.total--
        }).catch((e) => { this.$notifyBug(e) })
    }
  }
}
</script>

<style lang="scss">
  @use 'style';
</style>
