export class WebStorage {
  constructor (storage) {
    this.storage = storage
    this.options = {
      namespace: '',
      events: ['storage']
    }

    Object.defineProperty(this, 'length', {
      get () { return this.storage.length }
    })
  }

  setOptions (options = {}) {
    this.options = Object.assign(this.options, options)
  }

  /** Set value in local storage */
  set (name, value, expire = null) {
    const stringifyValue = JSON.stringify({ value, expire: expire !== null ? new Date().getTime() + expire : null })
    this.storage.setItem(this.options.namespace + name, stringifyValue)
  }

  /** Get value in local storage */
  get (name, def = null) {
    const item = this.storage.getItem(this.options.namespace + name)

    if (item !== null) {
      try {
        const data = JSON.parse(item)

        if (data.expire === null) return data.value
        if (data.expire >= new Date().getTime()) return data.value

        this.remove(name)
      } catch (err) {
        return def
      }
    }

    return def
  }

  /** Return key in local storage */
  key (index) { return this.storage.key(index) }

  /** Remove value from local storage */
  remove (name) {
    return this.storage.removeItem(this.options.namespace + name)
  }

  /** Clear whole local storage values */
  clear () {
    if (this.length === 0) return

    const removedKeys = []

    for (let i = 0; i < this.length; i++) {
      const key = this.storage.key(i)
      const regexp = new RegExp(`^${this.options.namespace}.+`, 'i')

      if (regexp.test(key) === false) continue
      removedKeys.push(key)
    }

    for (const key in removedKeys) {
      this.storage.removeItem(removedKeys[key])
    }
  }
}
