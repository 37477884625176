<template>
  <div>
    <!-- Animated BG -->
    <animated-landing/>
    <el-row type="flex" justify="center" align="top" class="minimum-page landing-wrapper">
    <el-col>
        <div class="public-background"/>

        <!-- Logo -->
        <a v-if="aboutUrl" :href="aboutUrl" :aria-label="$t('SW_LOGO_IMAGE')" target="_blank">
          <span class="horizontal-logo"/>
        </a>

        <router-link v-else to="/about" class="inline" :aria-label="$t('SW_LOGO_IMAGE')">
          <span class="horizontal-logo"/>
        </router-link>

        <!-- Text -->
        <p class="landing-text bold font-20">{{ $t('SW_SLOGAN_SHORT') }}</p>

        <!-- Footer links -->
        <footer-links class="mt-10 mb-20 landing-buttons"/>

        <!-- Log in panel -->
        <login class="mb-30"/>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Login from '@/edpack-web/components/Login'
import AnimatedLanding from '@/components/AnimatedLanding'
import FooterLinks from '@/edpack-web/components/FooterLinks'

export default {
  name: 'Landing',
  components: { Login, FooterLinks, AnimatedLanding },
  data () {
    return {
      aboutUrl: this.$config.aboutUrl
    }
  }
}
</script>

<style lang="scss">
    @use 'style';
</style>
