// import store from '@/edpack-web/store'

export default {
  install (app, options) {
    // store.registerModule('AppNameHere', {
    //   state: () => ({
    //     // props here
    //   }),
    //
    //   mutations: {
    //     SET_STATE_NAME (state, data) {
    //       state.data = data
    //     }
    //   },
    //
    //   actions: {
    //     setData ({ commit }, payload) {
    //       commit('SET_STATE_NAME', payload)
    //     }
    //   }
    // })
  }
}
