import Axios from 'axios'

export {
  loadCsrfToken,
  setCsrfToken,
  removeCsrfToken
}

// Load from local storage
function loadCsrfToken ($ls) {
  const csrfToken = $ls.get('csrfToken')
  if (csrfToken) Axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken
}

// Set token from header
function setCsrfToken (res, $ls) {
  const csrfToken = res.data.csrfToken
  if (!csrfToken) return

  Axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken
  $ls.set('csrfToken', res.data.csrfToken)
  delete res.data.csrfToken
}

// Remove from storage
function removeCsrfToken ($ls) {
  $ls.remove('csrfToken')
  Axios.defaults.headers.common['X-CSRF-TOKEN'] = ''
}
