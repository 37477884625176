<template>
  <Suspense>
    <template #default>
      <slot/>
    </template>
    <template #fallback>
      <spinner class="mx-auto mt-20"/>
    </template>
  </Suspense>
</template>

<script>
export default {
  name: 'SuspenseSpinner'
}
</script>
