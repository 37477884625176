<template>
  <div class="plans-list">
    <!-- Plan tiles -->
    <masonry :cols="cols" :gutter="{default: '30px', 1023: '20px'}">
      <plan-tile v-for="(page, index) in plans" :type="type" :plan="page" :key="page._id" :class="{'hidden-sm': index > 0, 'hidden-md': index > 1}"/>
    </masonry>

    <!-- Show more -->
    <el-button class="block mt-10 mb-30" v-if="total > 3" @click="$router.push({ name: 'search', params: { type: 'plans', slug: school.slug } })">
      {{ $t('SW_ALL_PLANS', [$term('plans', 'lc')]) }}
    </el-button>

    <!-- No results -->
    <span class="text-muted" v-if="status === 'none'">{{ $t('SW_RESULTS_NONE') }}</span>

    <!-- Loading -->
    <spinner v-if="status === 'loading'"></spinner>
  </div>
</template>

<script>
import PlanTile from '@/components/PlanTile'

export default {
  name: 'plansList',
  props: ['type', 'appPage', 'user', 'single'],
  components: { PlanTile },

  data () {
    return {
      status: 'done',
      lastElement: false,
      total: false,
      limit: 3,
      cols: this.single ? { default: 1 } : { default: 3, 1023: 2, 767: 1 },
      plans: [],
      school: this.$store.state.school,
      params: false,
      currentUser: this.$store.state.user,
      lang: this.$store.state.user.language
    }
  },

  created () {
    this.getPlans(this.limit)
  },

  methods: {
    getPlans (limit) {
      if (status === 'loading') return
      this.status = 'loading'

      this.params = {
        amount: limit,
        status: 'published',
        organization: this.school._id
      }

      if (this.user) this.params.user = this.user._id
      if (this.appPage) this.params.pages = [this.appPage._id]

      this.$http.get('plans', { params: this.params })
        .then((res) => {
          this.plans = res.data.list
          if (this.limit) this.plans = this.plans.slice(0, this.limit)
          this.total = res.data.total
          this.status = res.data.done ? 'done' : 'resolved'
          if (!this.total) this.status = 'none'
        })
        .catch((e) => {
          this.$notifyBug(e)
          this.status = 'error'
        })
    },
    showMore () {
      const newLimit = this.limit + 50
      this.limit = newLimit
      this.getPlans(newLimit)
    }
  }
}
</script>
