<template>
  <div>
    <p v-if="appPage" class="mb-20">{{ $t('SW_I_USE', [appPage.name, school.shortName[lang]]) }}</p>
    <experience-tile v-for="experience in experiences" :key="experience._id" :app="appPage" :experience="experience" :editExp="editExp" :removeExp="removeExp"/>

    <!-- None yet -->
    <div v-if="!experiences.length && !loading" class="text-muted">
      <p>{{ $t('SW_NO_EXPERIENCES') }}</p>

      <el-button v-if="appPage && canCreate" type="primary" @click="createExp" class="mt-20" size="small">
        <i class="icon-comment"/>
        {{ $t('SW_BE_FIRST') }}
      </el-button>

      <!-- TODO: Invite user to leave experience -->
      <el-button class="hide" v-if="appPage" @click="inviteExp">
        <i class="icon-email"/>
        <span>{{ $t('SW_INVITE_TO_LEAVE_EXPERIENCE') }}</span>
      </el-button>
    </div>

    <div v-if="experiences.length">
      <!-- Show more experiences -->
      <el-button size="small" @click="showMore" :disabled="loading" v-if="total > limit">
        <span>{{ $t('SW_MORE') }}</span>
        <span class="text-muted">&nbsp;+{{ total - limit }}</span>
      </el-button>

      <!-- Share experience -->
      <el-button type="primary" v-if="appPage && canCreate" size="small" @click="createExp">
        <i class="icon-comment"/>
        <span>{{ $t('SW_SHARE_EXPERIENCE') }}</span>
      </el-button>
    </div>

    <!-- Create experience dialog -->
    <el-dialog :title="$t('SW_SHARE_EXPERIENCE')" append-to-body v-model="dialogExperience">
      <experience-dialog v-if="dialogExperience" :experienceForm="experienceForm" :closeDialog="closeDialog"/>
    </el-dialog>

    <!-- Invite a user to leave experience dialog -->
    <el-dialog :title="$t('SW_EXPERIENCE_INVITE')" append-to-body v-model="dialogInvite">
      <experience-invite-dialog v-if="dialogInvite" :app="appPage" :closeDialog="closeDialog"/>
    </el-dialog>

    <!-- Edit dialog -->
    <el-dialog :title="$t('SW_EDIT_EXPERIENCE')" append-to-body v-model="dialogEdit">
      <experience-dialog v-if="dialogEdit" :experienceForm="experienceForm" :closeDialog="closeDialog"/>
    </el-dialog>

    <!-- Loading -->
    <spinner v-if="loading"/>
  </div>
</template>

<script>
import ExperienceTile from '@/components/ExperienceTile'
import ExperienceDialog from '@/components/ExperienceDialog'
import ExperienceInviteDialog from '@/components/ExperienceInviteDialog'

export default {
  name: 'Experiences',
  props: ['appPage', 'user', 'startLimit'],
  components: { ExperienceDialog, ExperienceTile, ExperienceInviteDialog },

  data () {
    return {
      canCreate: this.$store.state.user.role !== 'student',
      currentUser: this.$store.state.user,
      lang: this.$store.state.user.language,
      school: this.$store.state.school,
      dialogExperience: false,
      dialogEdit: false,
      dialogInvite: false,
      experiences: [],
      experienceForm: false,
      total: false,
      loading: false,
      skip: 0,
      limit: this.startLimit
    }
  },

  mounted () {
    this.getExperiences(this.limit)
  },

  methods: {
    getExperiences (amount) {
      const params = { amount }

      if (this.school) params.organization = this.school._id
      if (this.appPage) params.page = this.appPage._id
      if (this.user) params.user = this.user._id
      if (this.skip) params.skip = this.skip

      this.loading = true
      this.$http.get('experiences', { params }).then(res => {
        this.loading = false
        this.total = res.data.total
        this.skip = res.data.skip
        this.experiences = this.experiences.concat(res.data.list)
      })
    },
    closeDialog (experience) {
      this.dialogExperience = false
      this.dialogInvite = false
      this.dialogEdit = false

      if (experience) {
        const index = this.experiences.findIndex(ex => ex._id === experience._id)
        // Update experience or add experience
        if (index > -1) {
          this.experiences[index] = experience
        } else {
          this.experiences.unshift(experience)
          this.total++
          this.limit++
        }
      }
    },
    showMore () {
      const newLimit = this.limit + 5
      this.limit = newLimit
      this.getExperiences(newLimit)
    },
    editExp (experience) {
      this.experienceForm = JSON.parse(JSON.stringify(experience))
      this.dialogEdit = true
    },
    createExp () {
      const newForm = {
        organization: this.$store.state.school,
        page: this.appPage,
        createdBy: this.$store.state.user,
        positive: [''],
        negative: [''],
        oneliner: '',
        remarks: ''
      }

      this.experienceForm = JSON.parse(JSON.stringify(newForm))
      this.dialogExperience = true
    },
    removeExp (item) {
      this.$http.delete(`experiences/${item._id}`)
        .then(() => {
          this.$message({ type: 'success', message: this.$t('SW_EXPERIENCE_DELETED') })
          this.experiences = this.experiences.filter(experience => experience._id !== item._id)
          this.total--
        }).catch(e => { this.$notifyBug(e) })
    },
    inviteExp () {
      this.dialogInvite = true
    }
  }
}
</script>

<style lang="scss">
  @use 'style';
</style>
