import { WebStorage } from './webStorage'
import { MemoryStorage } from './memoryStorage'

// eslint-disable-next-line
const _global = (typeof window !== 'undefined' ? window : global || {})

const VueStorage = {
  install (app) {
    let store

    // Try to set and access localStorage
    try {
      store = 'localStorage' in _global ? _global.localStorage : null

      if ('localStorage' in window && window.localStorage !== null) {
        localStorage.setItem('testitem', true)
        localStorage.removeItem('testitem')
      }
    } catch (e) {
      store = MemoryStorage
      console.error('Storage "localStorage" is not supported your system, using Memory storage')
    }

    const ls = new WebStorage(store)
    ls.setOptions({ namespace: 'sw_' })

    app.config.globalProperties.$ls = ls
  }
}

export default VueStorage
