import config from 'config'
import getResizeQuery from './resize-query'
import getFileKey from './url-to-apiurl'

export default function (url, options = {}, user) {
  if (!url) return ''
  const fileKey = getFileKey(config, url)
  const domain = config.filestack.resizeUrl
  const queryString = getResizeQuery(options)
  const API_KEY = config.filestack.key

  const result = `${domain}${API_KEY}/security=p:${user.filestack.policy},s:${user.filestack.signature}/${queryString}/cache=expiry:31536000/${fileKey}`
  return result
}
