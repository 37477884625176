<template>
  <div class="search-bar-wrap">
    <div class="search-bar">
      <el-autocomplete class="block" select-when-unmatched :loading-text="$t('SW_LOADING')" v-model="searchQuery" :fetch-suggestions="search" :placeholder="$t('SW_SEARCH_NAME_KEYWORD')" @select="selectItem">
        <template #prefix class="mr-5">
          <i class="icon-search el-input__icon"/>
        </template>

        <template #suffix>
          <i class="icon-cancel el-input__icon" v-if="$route.name === 'search'" @click="clearSearch"/>
        </template>

        <template #default="{ item }">
          <div v-if="item.type === 'app'">
            <thumbnail :model="item" class="app-thumb thumb-24"/>
            <span class="ml-10 mr-5">{{ item.name }}</span>
            <span class="text-muted pull-right hidden-xs">{{ $t('SW_APP').toLowerCase() }}</span>
            <span v-if="item.adviceLabel === 'recommended'" class="text-success">
              <i class="icon-ok-sign"/>
            </span>
            <span v-else-if="item.adviceLabel === 'dismissed'" class="text-danger">
              <i class="icon-forbidden"/>
            </span>
            <span v-else-if="item.adviceLabel === 'warning'" class="text-warning">
              <i class="icon-warning-sign"/>
            </span>
          </div>

          <div v-else-if="item.type === 'label'">
            <i class="icon-label"/>
            <span>{{ item.name }}</span>
            <span class="text-muted pull-right hidden-xs">label</span>
          </div>
          <div v-else-if="item.type === 'category'">
            <i class="icon-categories"/>
            <span>{{ item[lang] }}</span>
            <span
              class="text-muted pull-right hidden-xs">{{ $t('SW_CATEGORY').toLowerCase() }}</span>
          </div>
          <div v-else>
            <i class="icon-notes"/>
            <span>{{ item.title[lang] }}</span>
            <span class="text-muted pull-right hidden-xs">{{ $term('plan', 'lc') }}</span>
          </div>
        </template>
      </el-autocomplete>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SearchBar',
  data () {
    return {
      user: this.$store.state.user,
      lang: this.$store.state.user.language,
      school: this.$store.state.school,
      loading: false,
      searchQuery: this.$route.query.query || this.$route.query.label || ''
    }
  },

  watch: {
    $route: {
      immediate: false,
      flush: 'post',
      handler (to) {
        this.searchQuery = to.query.query || to.query.label || ''
      }
    }
  },

  methods: {
    search (query, cb) {
      // eslint-disable-next-line standard/no-callback-literal
      if (!query || query.length < 2 || this.loading) return cb([])

      this.loading = true

      this.$http.get('pages/suggestions', { params: { filter: query, organization: this.school._id } })
        .then(response => {
          this.loading = false
          const categories = response.data.categories.map(category => {
            category.type = 'category'
            return category
          })
          const labels = response.data.labels.map(label => {
            return {
              type: 'label',
              name: label
            }
          })
          const plans = response.data.plans.map(plan => {
            plan.type = 'plan'
            return plan
          })
          const apps = response.data.apps.map(app => {
            app.type = 'app'
            if (app.status === 'published') app.recommended = this.$t('SW_ADVICE_RECOMMENDED')
            if (app.status === 'dismissed') app.recommended = this.$t('SW_ADVICE_DISMISSED')
            return app
          })
          const list = apps.concat([], categories, labels, plans)
          cb(list)
        })
        .catch((e) => {
          this.$notifyBug(e)
          this.loading = false
        })
    },
    selectItem (item) {
      if (item.type === 'app') {
        return this.$router.push({
          name: 'app',
          params: { id: item._id, slug: this.school.slug }
        })
      }
      if (item.type === 'plan') {
        return this.$router.push({
          name: 'plan',
          params: { id: item._id, slug: this.school.slug }
        })
      }

      const queryParams = {}

      if (item.value) {
        // basic search
        queryParams.query = item.value
      } else if (item.type === 'label') {
        // select label
        queryParams.label = item.name
      } else {
        // select category
        queryParams.categories = [item._id]
      }

      return this.$router.push({
        name: 'search',
        query: queryParams,
        params: { type: this.$route.params.type, slug: this.school.slug }
      })
    },
    clearSearch () {
      this.$router.push({
        name: 'search',
        params: { type: this.$route.params.type, slug: this.school.slug }
      })
    }
  }
}
</script>

<style lang="scss">
  @use 'style';
</style>
