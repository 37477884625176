import router from '@/router'
import scrollTop from 'simple-scrolltop'
import store from '@/edpack-web/store'
import { loadLanguages } from '@/edpack-web/utils/load-languages'

export default {
  install (app, options) {
    const i18n = app.config.globalProperties.$i18n

    router.afterEach((to, from) => {
      // Scroll to top for all pages
      scrollTop(0)

      // Check visitor language in local storage
      const visitorLanguage = app.config.globalProperties.$ls.get('visitorLanguage')
      if (visitorLanguage && !store.state.user && i18n.language !== visitorLanguage) {
        loadLanguages(i18n.global, visitorLanguage)
        store.dispatch('setLanguage', visitorLanguage)
      }
    })
  }
}
