export default {
  releaseStage: 'production',
  web_url: 'https://www.mijnjuridischadvies.nl',
  api_url: 'https://api.mijnjuridischadvies.nl/api/v1',
  analytics: 'UA-39220612-9',
  secondaryAnalytics: 'G-3LHBKYVGD4',

  // Ackee
  ackee: {
    enabledTracking: true,
    domain_id: '768d7ac1-47fb-4d31-b05a-07db4d614ec1',
    api_url: 'https://stats.shareworks.nl/',
    token: '499bcd8e-66f1-44ec-9193-afa7e808a399',
    options: {
      ignoreLocalhost: false,
      ignoreOwnVisits: false,
      detailed: true
    }
  }
}
