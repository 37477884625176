<template>
  <div>
    <page-cover class="page-header">
      <!-- Go back button -->
      <router-link v-if="!closeEditDialog" :to="{ name: 'admin', params: { mode: 'apps', slug: school.slug }}" class="btn-back">
        <i class="icon-arrow_back"/>
        <span>{{ $t('SW_BACK') }}</span>
      </router-link>

      <page-header :title="$t('SW_EDIT_PAGE')" :intro="$t('SW_EDIT_PAGE_TIP', [page.name, school.name[lang]])" icon="icon-lock" :area="$t('SW_ADMIN_ONLY')"/>
    </page-cover>

    <div class="page-body" v-if="status === 'done'">
      <el-form ref="form" :model="page" label-width="180px">
        <!-- Page name & icon -->
        <el-form-item :label="$t('SW_APP')">
          <div class="pull-left mr-10">
            <thumbnail :model="page" :large="true" class="app-thumb thumb-42"/>
          </div>

          <div class="no-overflow">
            <!-- App title -->
            <strong>{{page.name}}</strong>
            &centerdot;
            <router-link :to="{ name: 'app', params: { id: page._id, slug: school.slug } }" target="_blank">{{ $t('SW_APP_NEW_TAB' )}}</router-link>
          </div>
        </el-form-item>

        <app-page-form :form="page" class="mb-20"/>

        <!-- Save changes or cancel -->
        <el-form-item>
          <el-button type="primary" @click="updateApp(false)" :loading="submitting">
            <strong>{{ $t('SW_SAVE_CHANGES') }}</strong>
          </el-button>

          <el-button type="success" v-if="page.status !== 'published'" @click="updateApp(true)" :loading="submitting">
            <i class="icon-send"/>
            <strong>{{ $t('SW_PUBLISH') }}</strong>
          </el-button>

          <el-button class="ml-10" type="text" @click="onCancelClick">
            {{ $t('SW_CANCEL') }}
          </el-button>
        </el-form-item>
      </el-form>
    </div>

    <!-- Loading -->
    <spinner v-if="status === 'loading'" class="mt-30"/>

    <!-- Error -->
    <div v-if="status === 'error'" class="mt-30 text-muted text-center">
      {{ $t('SW_ERROR_LOADING') }}
    </div>
  </div>
</template>

<script>
import AppPageForm from '@/components/AppPageForm'

export default {
  name: 'EditApp',
  props: ['id', 'closeEditDialog'],
  components: { AppPageForm },

  data () {
    return {
      dialogConfirm: false,
      submitting: false,
      status: false,
      school: this.$store.state.school,
      lang: this.$store.state.lang,
      page: {}
    }
  },

  watch: {
    id: {
      immediate: true,
      flush: 'post',
      handler () { this.getPage(this.id || this.$route.params.id) }
    }
  },

  methods: {
    onCancelClick () {
      if (this.closeEditDialog) this.closeEditDialog()
      else this.$router.push({ name: 'admin', params: { mode: 'apps', slug: this.school.slug } })
    },

    getPage (id) {
      this.status = 'loading'

      this.$http.get(`pages/${id}`).then(
        res => {
          const page = res.data.list[0]
          page.selectedFilters = {}
          for (const cat of this.school.categories) page.selectedFilters[cat._id] = []
          for (const cat of page.categories) for (const filter of cat.filters) page.selectedFilters[cat._id].push(filter._id)

          this.page = page
          this.status = 'done'
        })
        .catch((e) => {
          this.$notifyBug(e)
          this.status = 'error'
        })
    },
    updateApp (publish) {
      if (this.submitting) return
      this.submitting = true

      if (publish) {
        this.page.status = 'published'
        this.school.counts.publishedPages++
        this.school.counts.draftPages--
      } else if (this.page.status === 'none') {
        this.page.status = 'draft'
      }

      const filters = []
      for (const key of Object.keys(this.page.selectedFilters)) for (const filter of this.page.selectedFilters[key]) filters.push(filter)
      this.page.categories = filters

      this.$http.put(`pages/${this.page._id}`, this.page).then(
        (res) => {
          this.submitting = false
          this.$message({ message: this.$t('SW_CHANGES_SAVED'), type: 'success' })

          if (this.closeEditDialog) return this.closeEditDialog(res.data.list[0])

          if (this.page.status === 'published') return this.$router.push({ name: 'app', params: { id: this.page._id, slug: this.school.slug } })
          this.$router.push({ name: 'admin', params: { mode: 'apps', slug: this.school.slug } })
        })
        .catch((e) => {
          this.$notifyBug(e)
          this.submitting = false
        })
    }
  }
}
</script>
