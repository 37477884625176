import secureFilestackUrl from './secure-url'

export default function (html, user) {
  if (!html || !user) return

  const urlRegex = /(\b(https:\/\/cdn.filestackcontent.com)[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig
  const urls = html.match(urlRegex)

  if (!urls?.length) return html

  urls.forEach(function (url) {
    const cleanUrl = url.split('?')[0]
    const newUrl = secureFilestackUrl(cleanUrl, user)
    html = html.replace(url, newUrl)
  })

  return html
}
