<template>
  <div :class="{ 'multi-lang': !isJustOneLanguage }">
    <el-alert type="info" class="mb-20" :title="$t('SW_HOW_TEMPLATES')" show-icon :description="$t('SW_HOW_TEMPLATES_TEXT', [$term('plans', 'lc')])"/>

    <div v-for="langItem in languages" :key="langItem">
      <el-form-item :class="`form-${langItem}`" :label="$t('SW_TEMPLATE_TEXT')" v-if="form.languages[langItem]">
        <el-row :gutter="20" class="mb-20">
          <el-col :span="14">
            <!-- Page Template -->
            <p class="form-help-text">{{ $t('SW_EXPLAIN_TEMPLATE_SHORT') }}</p>
            <redactor :config="editorOptions" ref="templateEN" v-model="form.template[langItem]"/>
          </el-col>
          <el-col :span="9">
            <!-- Page Explainer -->
            <p>{{ $t('SW_TEMPLATE_EXPLAINER') }}</p>
            <div class="font-13">
              <redactor :config="editorOptionsExplainer" v-model="form.explainer[langItem]"/>
            </div>
          </el-col>
        </el-row>
      </el-form-item>

      <el-form-item v-if="form.languages[langItem]" :class="`form-${langItem}`" :label="$t('SW_PLAN_TEMPLATE_TEXT', [$term('plan')])">
        <el-row :gutter="20" class="mb-20">
          <el-col :span="14">
            <!-- Plan Template -->
            <p class="form-help-text">{{ $t('SW_EXPLAIN_TEMPLATE_SHORT') }}</p>
            <redactor :config="editorOptions" v-model="form.planTemplate[langItem]"/>
          </el-col>
          <el-col :span="9">
            <!-- Plan Explainer -->
            <p>{{ $t('SW_TEMPLATE_EXPLAINER') }}</p>
            <div class="font-13">
              <redactor :config="editorOptionsExplainer" ref="planExplainerEN" v-model="form.planExplainer[langItem]"/>
            </div>
          </el-col>
        </el-row>
      </el-form-item>
    </div>
  </div>
</template>

<script>

import { defineAsyncComponent } from 'vue'
const Redactor = defineAsyncComponent(() => import('@/edpack-web/components/Redactor'))

export default {
  name: 'OrgTemplateSettings',
  props: ['form'],
  components: { Redactor },

  data () {
    return {
      school: this.$store.state.school,
      user: this.$store.state.user,
      lang: this.$store.state.user.language,
      languages: this.$store.state.languages,
      editorOptions: {},
      editorOptionsExplainer: {
        buttons: ['format', 'bold', 'italic', 'deleted', 'lists', 'link'],
        plugins: ['table', 'alignment']
      }
    }
  },

  computed: {
    isJustOneLanguage () { return this.$store.state.languages.length === 1 }
  },

  methods: {
    generateSecret () {
      this.processing = true

      this.$http.get(`organizations/${this.form._id}/secret`, {})
        .then((res) => {
          this.dialogConfirmLtiSecret = false
          this.form.lti.secret = res.data.list[0]
          this.processing = false
          this.$message({ message: this.$t('SW_LTISECRET_SAVED'), type: 'success' })
        })
        .catch((e) => {
          this.$notifyBug(e)
          this.processing = false
        })
    },
    showInput (type) {
      this.inputVisible[type] = true
      this.$nextTick((_) => { this.$refs[type].$refs.input.focus() })
    },
    handleInputConfirm (type) {
      const inputValue = this.inputValue[type]
      if (inputValue) this.form[type].push(inputValue)
      this.inputVisible[type] = false
      this.inputValue[type] = ''
    },
    handleClose (type, item) {
      this.form[type].splice(this.form[type].indexOf(item), 1)
      this.$nextTick()
    },
    clipboardSuccess () {
      this.$message({ message: this.$t('SW_COPIED_TO_CLIPBOARD'), type: 'success' })
    }
  }
}
</script>
