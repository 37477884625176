module.exports = {
  web_url: 'https://www.mijnjuridischadvies.nl',
  name: 'Mijn Juridisch Advies',
  description: 'Op zoek naar modelcontracten? Mijn Juridisch advies levert professionele Nederlandse modelcontracten.',
  keywords: 'modelcontracten, modellen, modelcontract, model contracten, juridische voorbeelden, voorbeeldcontracten, voorbeeldcontract, voorbeeldovereenkomst, voorbeeldovereenkomsten',
  themeColor: '#4A90E2',
  defaultAdminTab: 'apps',

  // Optional Edpack functionality
  hideCourses: true,
  hasUserProfiles: true,
  introBySchool: true,
  hideSchoolSignIn: true,

  // lms types
  lmsTypes: [],
  ltiTypes: [],

  // terminology
  terminologyList: ['faculty', 'student', 'instructor', 'plan'], //  'faculty', 'student', 'instructor', 'plan'

  // Testing stuff
  mock_user: false,
  debug: false,
  maintenance: false,

  business: {
    name: 'Mijn Juridisch Advies',
    shortName: 'Mijn Juridisch Advies',
    mail: 'info@mijnjuridischadvies.nl',
    postAddress: '3051 AS Rotterdam',
    streetAddress: 'Prinses Margietlaan 91 B',
    country: 'Netherlands',
    url: 'https://www.mijnjuridischadvies.nl'
  },

  map: {
    position: { lat: 51.9456062, lng: 4.4888894 },
    centerMap: { lat: 51.9456062, lng: 4.4888894 },
    mapsUrl: 'https://goo.gl/maps/VLt8a45efrmZA2VS8'
  },

  lastUpdated: ['May', '10', '2022'],

  // Extending Edpack component configs
  orgCounts: [
    { type: 'publishedPages', icon: 'icon-box_view' },
    { type: 'publishedPlans', icon: 'icon-chat-lines' }
  ],
  usersTableRoles: [
    { label: 'all', value: 'all' },
    { label: 'students', value: 'student' },
    { label: 'staffs', value: 'staff' },
    { label: 'admins', value: 'admin' }
  ],

  // Language
  languages: ['nl'],
  defaultLanguage: 'nl',

  // Freshchat
  freshchatConfig: {
    cssNames: {
      widget: 'fc_frame',
      open: 'fc_open',
      expanded: 'fc_expanded'
    },
    showFAQOnOpen: false,
    hideFAQ: false,
    headerProperty: {
      backgroundColor: '#4A90E2',
      foregroundColor: '#FFFFFF'
    },
    content: {
      placeholders: {
        search_field: 'Search FAQs',
        reply_field: 'Reply here...',
        csat_reply: 'Enter your comments here'
      },
      actions: {
        csat_yes: 'Yes',
        csat_no: 'No',
        push_notify_yes: 'Yes',
        push_notify_no: 'No',
        tab_faq: 'FAQ',
        tab_chat: 'CHAT',
        csat_submit: 'Submit'
      },
      // Change all the customer facing strings available below.
      headers: {
        chat: 'Message Us',
        chat_help: 'Hi there! We\'d love to help you out!',
        faq: 'Frequently asked questions',
        faq_help: 'Browse our FAQ',
        faq_not_available: 'No articles',
        faq_search_not_available: 'No articles found for {{query}}',
        faq_useful: 'Was this useful?',
        faq_thankyou: 'Thank you for your feedback!',
        faq_message_us: 'Message Us',
        push_notification: 'Don\'t miss out on any replies! Allow push notifications?',
        csat_question: 'Did we address your concerns?',
        csat_yes_question: 'How would you rate your experience with us?',
        csat_no_question: 'How could we have helped better?',
        csat_thankyou: 'Thanks for your feedback!',
        csat_rate_here: 'Rate here',
        channel_response: {
          offline: 'We are currently away',
          online: {
            minutes: {
              one: 'Currently replying in under a minute',
              more: 'Currently replying in under {{time}} minutes'
            },
            hours: {
              one: 'Currently replying in under an hour',
              more: 'Currently replying in under {{time}} hours'
            }
          }
        }
      }
    }
  },

  // File handling
  filestack: {
    key: 'A7m0AxXRqQ5qJFDQ7OKB0z',
    supported: ['pdf', 'ppt', 'pptx', 'xls', 'xlsx', 'doc', 'docx', 'odt', 'odp', 'ai', 'psd'],
    api_url: 'https://www.filestackapi.com/api/file/',
    transformation_url: 'https://process.filestackapi.com/',
    resizeUrl: 'https://cdn.filestackcontent.com/',
    default_settings: {
      maxFiles: 1,
      uploadInBackground: true,
      maxSize: 2000 * (1024 * 1024),
      displayMode: 'overlay',
      container: '#filestack-container',
      storeTo: {
        access: 'private',
        location: 's3',
        container: 'files.projectcamp.us',
        region: 'eu-west-1',
        path: 'mja/'
      },
      transformations: {
        crop: true,
        rotate: true
      },
      fromSources: ['local_file_system', 'googledrive', 'onedrive', 'dropbox', 'webcam'],
      lang: 'en',
      imageMax: [800, 800]
    }
  },
  s3: {
    url: 'https://s3-eu-west-1.amazonaws.com/files.projectcamp.us/'
  },

  // Ackee
  ackee: {
    enabledTracking: false,
    domain_id: '1c110323-a4ee-425b-a572-294e5bbf8bc2',
    api_url: 'http://localhost:3001/',
    token: '815b6433-9d49-49cd-8a65-0d1995be0a48',
    options: {
      ignoreLocalhost: false,
      ignoreOwnVisits: false,
      detailed: true
    }
  },

  // 3rd party keys
  google_maps_key: 'AIzaSyCuYvBo76_SZIAcmov00uf7KIM1DSH8D7A',
  freshchatToken: 'f3cf6f92-c25b-4add-83eb-ff9b31cc992b',
  analytics: 'UA-39220612-6',
  bugsnag: '2524995947d4c7edf659212f6a231099',
  giphy: '17097e73ddb849a0813cf378aaea381f',
  oembed_key: '8554c002-df31-417a-9f75-bb14d0c063b8',
  oembed_extract: 'https://api.embed.rocks/api/'
}
