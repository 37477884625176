<template>
  <div class="mb-20">
    <a href="#" @click.prevent="viewProfile">
      <thumbnail :model="experience.createdBy" class="thumb-user thumb-60 pull-left"/>
    </a>

    <div class="experience-body">
      <div class="bold font-16">
        <a href="#" @click.prevent="viewProfile">
          {{experience.createdBy.name}}
        </a>
        <span v-if="!app">
          @
          <router-link :to="{name: 'app', params: {id: experience.page._id, slug: school.slug}}">
            {{ experience.page.name }}
          </router-link>
        </span>
      </div>

      <div class="text-muted font-13 mb-5">
        <span>{{ $filters.fromNow(experience.createdDate, true) }}</span>
        <span v-if="canEdit">&nbsp;&centerdot;&nbsp;</span>

        <el-tooltip :content="$t('SW_EDIT_EXPERIENCE')" :enterable="false" v-if="canEdit">
          <el-button type="text" size="small" @click="editExp(experience)">
            <i class="icon-pencil"/>
          </el-button>
        </el-tooltip>

        <span v-if="canEdit">&nbsp;&centerdot;&nbsp;</span>
        <el-tooltip :content="$t('SW_DELETE_EXPERIENCE')" :enterable="false" v-if="canEdit">
          <el-button type="text" size="small" @click="dialogDelete = true">
            <i class="icon-delete"/>
          </el-button>
        </el-tooltip>
      </div>

      <!-- Oneliner -->
      <p class="oneliner">
        <span class="oneliner-text">{{experience.oneliner}}</span>
        <a href="#" class="ml-5" @click.prevent="toggleMore" v-if="experience.remarks && !showMore && $route.name !== 'home'" type="text">
          {{ $t('SW_MORE') }}
        </a>
      </p>
    </div>

    <!-- Remarks -->
    <el-collapse-transition>
      <div v-show="showMore">
        <p class="remarks mt-5 mb-10" v-html="experience.remarks"/>

        <div v-for="(positivePoint, index) in experience.positive" :key="'pos-' + index">
          <i class="icon-add-circle-full text-success mr-10"/>
          <span>{{positivePoint}}</span>
        </div>

        <div v-for="(negativePoint, index) in experience.negative" :key="'neg-' + index">
          <i class="icon-minus-circle-full text-danger mr-10"/>
          <span>{{negativePoint}}</span>
        </div>
      </div>
    </el-collapse-transition>

    <!-- Delete dialog -->
    <el-dialog :title="$t('SW_DELETE')" append-to-body v-model="dialogDelete">
      <span>{{ $t('SW_DELETE_EXPERIENCE_CONFIRM') }}</span>

      <template #footer>
        <div class="dialog-footer">
          <el-button type="text" @click="dialogDelete = false">{{ $t('SW_CANCEL') }}</el-button>
          <el-button type="primary" @click="removeExperience">{{ $t('SW_DELETE') }}</el-button>
        </div>
      </template>
    </el-dialog>

  </div>
</template>

<script>
export default {
  name: 'ExperienceTile',
  props: ['experience', 'app', 'removeExp', 'editExp'],

  data () {
    return {
      dialogDelete: false,
      showMore: false,
      school: this.$store.state.school,
      lang: this.$store.state.user.language,
      currentUser: this.$store.state.user
    }
  },

  computed: {
    canEdit () {
      if (this.currentUser.systemAdmin || this.currentUser.organizationAdmin) return true
      return this.experience.createdBy && (this.currentUser._id === this.experience.createdBy._id)
    }
  },

  methods: {
    removeExperience () {
      this.dialogDelete = false
      this.removeExp(this.experience)
    },
    toggleMore () {
      this.showMore = true
    },
    viewProfile () {
      this.$router.push({
        name: 'profile',
        params: { id: this.experience.createdBy._id, slug: this.school.slug }
      })
    }
  }
}
</script>

<style lang="scss">
  @use 'style';
</style>
