<template>
  <el-alert type="error" class="no-border-radius text-center" effect="dark" show-icon :closable="false" v-if="!serverOnline">
    <spinner v-if="checkConnectionLoading"/>
    <p v-else>
      <span class="mr-5">{{ $t('SW_SERVER_MAINTENANCE', [appName]) }} <a href="https://status.shareworks.nl/" class="check-status-button bold is-underline" target="_blank">{{ $t('SW_CHECK_STATUS') }}</a>.</span>
      <span>{{ $t('SW_RETRY_IN', [countdownNumber]) }} </span>
      <el-button class="inline ml-5" size="small" @click="checkConnection">{{ $t('SW_RETRY_NOW') }}</el-button>
    </p>
  </el-alert>
</template>

<script>
export default {
  name: 'DowntimeAlert',
  data () {
    return {
      appName: this.$config.name,
      checkConnectionLoading: false,
      countdownFunction: null,
      countdownNumber: 30,
      serverOnline: true
    }
  },

  created () {
    this.addErrorsIntercept()
  },

  mounted () {
    this.checkConnection()
  },

  methods: {
    addErrorsIntercept () {
      // we intercept error,
      // the error when api doesn't response is undefined
      // if error exist but === undefined -> do checkConnection()
      this.$http.interceptors.response.use(
        (response) => { return response },
        (error) => {
          // debounce didn't work here ;(
          if (error === undefined && !this.checkConnectionLoading) this.checkConnection()
          return Promise.reject(error)
        })
    },
    checkConnection () {
      this.checkConnectionLoading = true
      clearInterval(this.countdownFunction)

      this.$http.get('status')
        .then(() => { this.serverOnline = true })
        .catch((e) => {
          this.$notifyBug(e)
          this.serverOnline = false
          this.countdownNumber = 30
          this.countdownFunction = setInterval(() => { this.checkCountdown() }, 1000)
        })
        .finally(() => { this.checkConnectionLoading = false })
    },
    checkCountdown () {
      if (this.countdownNumber > 1) {
        return this.countdownNumber--
      }
      this.checkConnection()
    }
  }
}
</script>
