<template>
  <div class="page-tile" @mouseenter="hovered = true" @mouseleave="hovered = false">
    <router-link class="thumb-wrap" :to="{ name: 'app', params: { id: app._id, slug: school.slug } }">
      <thumbnail :model="app" class="thumb-60 app-thumb"/>
    </router-link>

    <div class="tile-info">
      <router-link :to="{ name: 'app', params: { id: app._id, slug: school.slug } }" class="title text-ellipsis mb-5">
        {{ app.name }}
      </router-link>

      <div class="categories font-13 text-ellipsis" v-if="app.categories && app.categories.length">
        <span v-for="(category, index) in app.categories[0].filters" :key="category._id">
          <router-link :to="{name: 'search', query: { categories: [category._id] }, params: { type: $route.params.type || 'apps', slug: school.slug }}" class="category">
            {{ category[lang] }}
          </router-link>
          <span v-if="app.categories[0].filters.length > (index +1)"> & </span>
        </span>
      </div>

      <!-- Labels -->
      <transition name="fade" mode="out-in">
        <div v-if="!hovered" :key="'labels'" class="go-top-animation">

        </div>

        <!-- Counts -->
        <div v-else class="tile-btns go-bottom-animation" :key="'counts'">
          <el-tooltip :content="app.counts.followers + ' ' + $t('SW_POSTS_COMMENTS')" :enterable="false" :show-after="300">
            <span class="tile-btn">
              <i class="icon-heart text-muted" :class="{'text-danger': app.following}"/>
              <span>{{app.counts.followers}}</span>
            </span>
          </el-tooltip>

          <el-tooltip :content="app.views + ' ' + $t('SW_VIEWS')" :enterable="false" :show-after="300">
            <span class="tile-btn ml-10">
              <i class="icon-eye text-muted"/>
              <span>{{ app.views }}</span>
            </span>
          </el-tooltip>
        </div>
      </transition>
    </div>

    <!-- Show description -->
    <p class="tile-desc mb-10" v-if="app.description && app.description[lang]">{{ $filters.truncate(app.description[lang], 100) }}</p>
    <p class="tile-desc mb-10 text-muted" v-else>{{ $t('SW_NO_DESCRIPTION_YET') }}</p>
  </div>
</template>
<script>

export default {
  name: 'AppTile',
  props: ['app'],

  data () {
    return {
      hovered: false,
      school: this.$store.state.school,
      lang: this.$store.state.user.language
    }
  },

  created () {
    console.log(this.app)
  }
}
</script>

<style lang="scss">
  @use 'style';
</style>
