<template>
  <div id="sidebar" :class="{'open': active}">
    <el-menu class="sidebar-menu" :default-active="activeTab">

      <!-- Logo -->
      <div class="logo-container">
        <img class="logo" src="/images/icon.png" alt="" />
      </div>

      <!-- Sidebar menu -->
      <el-menu-item v-if="!hideHomeTab" index="home" @click="tabClick('home')">
        <span>{{ $t('SW_HOME') }}</span>
      </el-menu-item>
      <el-menu-item v-if="isAdmin" index="admin" @click="tabClick('admin')">
        <span>{{ $t('SW_DASHBOARD') }}</span>
      </el-menu-item>
      <el-menu-item index="" class="hide"/>
    </el-menu>
  </div>
</template>

<script>
export default {
  name: 'AppSidebar',
  props: ['active', 'closeSidebar'],

  data () {
    return {
      hideHomeTab: this.$config.hideHomeTab,
      school: this.$store.state.school,
      isAdmin: this.$store.state.isAdmin,
      activeTab: 'home'
    }
  },

  watch: {
    $route (to) {
      if (['admin', 'home'].includes(to.name)) this.activeTab = to.name
      else this.activeTab = ''
    }
  },

  methods: {
    tabClick (tab) {
      this.activeTab = tab
      const route = { name: tab, params: { slug: this.school.slug } }
      this.$router.push(route)
      this.closeSidebar()
    }
  }
}
</script>

<style lang="scss">
@use 'style';
</style>
