<template>
  <div class="followers-list">
    <div v-for="follower in app.followers" :key="follower._id" class="mb-20 clearfix follower-tile">
      <a href="#" @click.prevent="viewProfile(follower.user._id)">
        <thumbnail :model="follower.user" class="thumb-user thumb-60 pull-left"></thumbnail>
      </a>
      <div class="tile-body">
        <a href @click.prevent="viewProfile(follower.user._id)" class="bold block text-ellipsis">
          {{follower.user.name}}
        </a>
        <el-button type="primary" size="small" plain @click="openEmailDialog(follower.user)">
          <i class="icon-email"></i>
          <span class="hidden-xs">{{ $tc('SW_EMAIL', 1)}}</span>
        </el-button>
      </div>
    </div>

    <div v-if="!app.followers.length">
      <span class="text-muted">{{ $t('SW_NO_FOLLOWERS_YET') }}</span>
    </div>

    <!-- Email dialog -->
    <el-dialog :title="$tc('SW_EMAIL', 1)" append-to-body v-model="dialogEmail">
      <email-users :closeDialog="closeEmailDialog" v-if="selectedUser" :selectedUsers="[selectedUser]">
        <template v-slot>
          <p class="mb-30">{{ $t('SW_EMAIL_FOLLOWER', [selectedUser.name, app.name]) }}</p>
        </template>
      </email-users>
    </el-dialog>
  </div>
</template>

<script>
import EmailUsers from '@/edpack-web/components/EmailUsers'

export default {
  name: 'FollowersDialog',
  props: ['app', 'closeDialog'],
  components: { EmailUsers },

  data () {
    return {
      dialogEmail: false,
      school: this.$store.state.school,
      lang: this.$store.state.user.language,
      currentUser: this.$store.state.user,
      selectedUser: false
    }
  },

  methods: {
    openEmailDialog (user) {
      this.dialogEmail = true
      this.selectedUser = user
    },
    viewProfile (id) {
      this.$router.push({ name: 'profile', params: { id, slug: this.school.slug } })
    },
    closeEmailDialog () {
      this.dialogEmail = false
    }
  }
}
</script>

<style lang="scss">
  @use 'style';
</style>
