<template>
  <div class="text-muted text-center" :class="{'mt-30': status !== 'done'}">

    <!-- Loading -->
    <spinner v-if="status === 'loading'"/>

    <!-- Error -->
    <span v-if="status === 'error'">{{ $t('SW_ERROR_LOADING') }}</span>

    <!-- None -->
    <span v-if="status === 'none'">{{ noneText }}</span>

    <!-- No results -->
    <div v-if="status === 'noResults'">
      {{ $t('SW_NO_MATCH_FOUND') }}
      <el-button type="text" @click="clearSearch">{{ $t('SW_CLEAR_SEARCH') }}</el-button>
    </div>

    <!-- Incomplete -->
    <span v-if="status === 'incomplete'">{{ $t('SW_SCROLL_FOR_MORE') }}</span>
  </div>
</template>

<script>
export default {
  name: 'TableStatus',
  props: ['status', 'noneText'],

  methods: {
    clearSearch () {
      this.$emit('clearSearch')
    }
  }
}
</script>
