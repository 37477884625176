import config from 'config'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'

export default {
  install (app, options) {
    // Init Bugsnag for staging and production
    if (config.releaseStage !== 'development') {
      Bugsnag.start({
        apiKey: config.bugsnag,
        enabledReleaseStages: ['staging', 'production'], // Add 'development' to test it
        releaseStage: config.releaseStage || process.env.NODE_ENV,
        plugins: [new BugsnagPluginVue()],
        onError: function (event) {
          if (!event || !event.errors || !event.errors[0] || !event.errors[0].errorMessage) return
          const error = event.errors[0].errorMessage.toLowerCase()
          if (error.includes('resizeobserver') || error.includes('chunk')) {
            event.severity = 'info'
            event.unhandled = false
          }
        }
      })

      const bugsnagVue = Bugsnag.getPlugin('vue')

      app.use(bugsnagVue)
    }
  }
}
