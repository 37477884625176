// Plugin to run (async) functions every x time
// - for now only used for refreshing third party credentials such as Filestack

// Dependencies
import $store from '@/edpack-web/store'

// Local vars
const ONE_MINUTE = 60000 // in ms
const ONE_HOUR = ONE_MINUTE * 60 // in ms

// Simple class that calls function every x time
class Scheduler {
  constructor (app, timeout) {
    // important!: timout must be set (else it will be called every 1ms)
    if (!timeout) throw new Error('timeout require!')

    this.app = app // App, as it is passed to the plugin.install
    this.timeout = timeout // In ms

    this.interval = null
    this.jobs = []

    // Directly start scheduler
    this.start()
  }

  // Add a new job (can be a async function or a regular JS function)
  addJob (job) {
    this.jobs.push(job)
  }

  // Remove a job (by function.name, example: 'refreshThirdPartyCredentials')
  removeJob (name) {
    this.jobs = this.jobs.filter(job => job.name !== name)
  }

  // Remove all jobs
  cleanJobs () {
    this.jobs = []
  }

  // Clear and release interval
  clear () {
    if (this.interval) clearInterval(this.interval)
    this.interval = null
  }

  // Start the scheduler by running the interval
  start () {
    const self = this

    // Check if interval is already running
    if (this.interval) return

    // Start interval
    this.interval = setInterval(function () {
      for (const job of self.jobs) {
        // Support running async functions and regular JS functions
        const constructorName = job.constructor.name
        if (constructorName === 'Function') job(self.app)
      }
    }, self.timeout)
  }
}

// Exposure
export default {
  install: (app) => {
    // Create scheduler that runs every hour, and add jobs to it
    const hourlyScheduler = new Scheduler(app, ONE_HOUR)
    hourlyScheduler.addJob(refreshThirdPartyCredentials)

    // Expose schedulers so you have access to it in runtime
    app.config.globalProperties.$jobScheduler = {
      hourlyScheduler
    }
  }
}

// Function/job to refresh user credentials for third party apps
function refreshThirdPartyCredentials (app) {
  // Get (new) third party credentials (like filestack)
    app.config.globalProperties.$http.get('users/third-party-credentials')
      .then(response => {
        // Stop if payload is not valid
        if (response.status !== 200) return
        if (!response.data?.list.length) return

        // Extract credentials from payload (like filestack credentials)
        const credentials = response.data.list[0]

        // Update user props
        if (credentials) $store.dispatch('setUserProperties', credentials)
      })
      .catch(err => {
        // Catch errors (because of Axios, errors are already catched, showed as message and sended to bugsnag
        console.error(err)
      })
}
