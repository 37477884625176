import config from 'config'
import Bugsnag from '@bugsnag/js'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import localizedFormat from 'dayjs/plugin/localizedFormat'

dayjs.extend(relativeTime)
dayjs.extend(localizedFormat)

export default {
  install: (app, options, store) => {
    app.config.globalProperties.$dayjs = dayjs

    app.config.globalProperties.$term = (key, size = 'default') => {
      const lang = store.state.lang
      const terminology = store.state.school.terminology

      if (!lang || !terminology) return ''

      if (size === 'default') return terminology[key][lang]
      if (size === 'lc') return terminology[key][lang].toLowerCase()
      if (size === 'uc') return terminology[key][lang].toUpperCase()
    }

    app.config.globalProperties.$getRoleText = (role, returnEmpty = false) => {
      if (role === 'student') return app.config.globalProperties.$term('student')
      else if (role === 'participant') return app.config.globalProperties.$term('students')
      else if (role === 'staff') return app.config.globalProperties.$term('instructor')
      else if (role === 'owner') return app.config.globalProperties.$i18n.global.t('SW_OWNER')
      else if (role === 'viewer') return app.config.globalProperties.$i18n.global.t('SW_VIEWER')
      else if (role === 'editor') return app.config.globalProperties.$i18n.global.tc('SW_EDITOR', 1)
      else if (role === 'none') return app.config.globalProperties.$i18n.global.t('SW_NONE')
      else if (role === 'assessor') return app.config.globalProperties.$i18n.global.tc('SW_ASSESSOR', 1)
      else if (role === 'admin') return app.config.globalProperties.$i18n.global.tc('SW_ADMIN', 1)
      else if (returnEmpty) return '-'
    }

    app.config.globalProperties.$getRoleOptionsText = (role, returnEmpty = false) => {
      if (role === 'all') return app.config.globalProperties.$i18n.global.t('SW_ALL')
      else if (role === 'students') return app.config.globalProperties.$term('students')
      else if (role === 'staffs') return app.config.globalProperties.$term('instructors')
      else if (role === 'admins') return app.config.globalProperties.$i18n.global.tc('SW_ADMIN', 2)
      else if (role === 'managers') return app.config.globalProperties.$i18n.global.tc('SW_MANAGER', 2)
      else if (role === 'editors') return app.config.globalProperties.$i18n.global.tc('SW_EDITOR', 2)
      else if (role === 'assessors') return app.config.globalProperties.$i18n.global.tc('SW_ASSESSOR', 2)
      else if (returnEmpty) return '-'
    }

    app.config.globalProperties.$notifyBug = error => {
      if (!error || config.releaseStage === 'development') return

      let errorMessage = 'Unknown WEB error'
      if (error.data?.errors && error.data?.errors.length) errorMessage = error.data.errors[0].message

      Bugsnag.notify(new Error(errorMessage), function (event) {
        event.addMetadata('dataError', error)
      })
      console.log(error)
    }
  }
}
