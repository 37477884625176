<template>
  <div>
    <!-- CATEGORY filter -->
    <section v-if="school.filterByCategories">
      <div v-if="school.categories.length">
        <div v-for="(category, index) in school.categories" class="mb-20" :key="category._id">
          <div>
            <strong class="ml-10 mr-5">{{ category[lang] }}</strong>
            <el-tag v-if="selectedCategories.length > 0 && getSelectedCategoriesAmount(category) > 0" size="small" v-memo="[category, selectedCategories]">{{ getSelectedCategoriesAmount(category) }}</el-tag>

            <ul class="categories-list mt-10">
              <li v-for="cat in category.filters.slice(0, visibleAmount)" :key="cat._id" class="category-item" :class="{active: isCategorySelected(cat._id)}" @click="onFilterClick(cat)">
                <span>{{ cat[lang] }}</span>
                <el-button v-show="isCategorySelected(cat._id)" class="remove-filter-button" type="text">
                  <i class="icon-close"/>
                </el-button>
              </li>

              <el-collapse-transition>
                <section v-show="categoriesStatus[index]">
                  <li v-for="cat in category.filters.slice(visibleAmount, category.filters.length)" :key="cat._id" class="category-item" :class="{active: isCategorySelected(cat._id)}" @click="onFilterClick(cat)">
                    <span>{{ cat[lang] }}</span>
                    <el-button v-show="isCategorySelected(cat._id)" class="remove-filter-button" type="text">
                      <i class="icon-close"/>
                    </el-button>
                  </li>
                </section>
              </el-collapse-transition>

              <!-- More button -->
              <el-button type="text" class="ml-10" v-if="category.filters.length > visibleAmount" @click="categoriesStatus[index] = !categoriesStatus[index]" size="small">
                <span v-if="!categoriesStatus[index]">{{ $t('SW_MORE') }}</span>
                <span v-else>{{ $t('SW_LESS') }}</span>
                <span :class="categoriesStatus[index] ? 'icon-caret-up': 'icon-caret-down'"/>
              </el-button>
            </ul>
          </div>
        </div>
      </div>
      <div v-else class="text-muted ml-10">{{ $t('SW_NO_CATEGORIES_FOUND') }}</div>
    </section>

    <!-- Sorting -->
    <div class="mt-20 mb-5 ml-10 bold" v-if="$route.name === 'home'">{{ $t('SW_SORT') }}</div>
    <el-tabs tab-position="left" v-model="selectedSort" @tab-click="updateFilters({ sort: selectedSort })" class="filter-section hidden-xs" v-if="$route.name === 'home'">
      <el-tab-pane name="category" :disabled="categories !== []" :label="$t('SW_SORT_CATEGORY')"/>
      <el-tab-pane name="alphabetical" :label="$t('SW_SORT_ALPHABETICAL')"/>
    </el-tabs>
  </div>
</template>

<script>
export default {
  name: 'FilterPanel',
  props: ['sort', 'categories', 'updateFilters', 'modeType'],

  data () {
    return {
      visibleAmount: 3,
      school: this.$store.state.school,
      currentUser: this.$store.state.user,
      lang: this.$store.state.user.language,
      selectedSort: this.sort,
      selectedCategories: this.categories,
      categoriesStatus: []
    }
  },

  watch: {
    $route: {
      immediate: true,
      flush: 'post',
      handler () {
        if (!this.$route.query.categories) this.resetCategories()
      }
    }
  },

  methods: {
    getSelectedCategoriesAmount (category) {
      const amount = category.filters.reduce((acc, curr) => {
        if (this.selectedCategories.includes(curr._id)) acc += 1
        return acc
      }, 0)

      return amount
    },
    onFilterClick (category) {
      // Change state
      if (this.selectedCategories.indexOf(category._id) > -1) {
        this.selectedCategories = this.selectedCategories.filter(cat => cat !== category._id)
      } else this.selectedCategories.push(category._id)

      // Get filtered apps from this category
      this.updateFilters({ categories: this.selectedCategories, sort: this.selectedSort })
    },
    resetCategories () { this.selectedCategories = [] },
    isCategorySelected (id) { return this.selectedCategories.indexOf(id) > -1 }
  }
}
</script>

<style lang="scss">
  @use 'style';
</style>
