// Polyfills
import 'intersection-observer'
import 'whatwg-fetch'
import 'promise-polyfill'

// Config
import config from 'config'

// Styling
import '@/styles/edpack-web.scss'
import '@/styles/index.scss'

// Core (Vue) functionality
import { createApp } from 'vue'
import dayjs from 'dayjs'
import 'dayjs/locale/nl'
import { sync } from 'vuex-router-sync'
import VueProgressBar from '@aacassandra/vue3-progressbar'
import ElementPlus from 'element-plus'
import elLocaleEn from 'element-plus/lib/locale/lang/en'
import VueFullscreen from 'vue-fullscreen'
import InfiniteLoading from 'vue-infinite-loading'
import { ObserveVisibility } from 'vue-observe-visibility'

// Main app component
import App from './components/App'

// SW Components
import Spinner from '@/edpack-web/components/Spinner'
import Thumbnail from '@/edpack-web/components/Thumbnail'
import AppLanguage from '@/edpack-web/components/AppLanguage'
import PageCover from '@/edpack-web/components/PageCover'
import PageHeader from '@/edpack-web/components/PageHeader'
import Affix from '@/edpack-web/components/Affix'
import TableStatus from '@/edpack-web/components/TableStatus'
import SkipToMain from '@/edpack-web/components/SkipToMain'
import SuspenseSpinner from '@/edpack-web/components/SuspenseSpinner'
import AnimatedTabs from '@/edpack-web/components/AnimatedTabs'

// Store & Router stuff
import store from '@/edpack-web/store'
import router from '@/router'

// Custom Plugins
import LanguagePlugin from '@/edpack-web/plugins/language'
import AxiosPlugin from '@/edpack-web/plugins/axios'
import RouterAfterEachPlugin from '@/edpack-web/plugins/router-after-each'
import RouterBeforeEachPlugin from '@/edpack-web/plugins/router-before-each'
import RouterUnsavedChanges from '@/edpack-web/plugins/router-unsaved-changes'
import BugsnagPlugin from '@/edpack-web/plugins/bugsnag'
import GlobalComponentsPlugin from '@/plugins/global-components'
import GlobalStorePlugin from '@/plugins/global-store'
import localStorage from '@/edpack-web/plugins/localStorage/localStorage'
import Masonry from '@/edpack-web/plugins/masonry'
import globalFunctions from '@/edpack-web/plugins/global-function'
import filters from '@/edpack-web/plugins/filters'
import JobSchedulerPlugin from '@/edpack-web/plugins/job-scheduler'

import VueAnnouncer from '@vue-a11y/announcer'
// import { FocusTrap } from 'focus-trap-vue'

// polyfill install
(async () => {
  if ('ResizeObserver' in window) {
    // Loads polyfill asynchronously, only if required.
    const module = await import('@juggle/resize-observer')
    window.ResizeObserver = module.ResizeObserver
  }
})()

sync(store, router)

// Create app with store and router
const app = createApp(App)

app.use(store)
app.use(router)

// Init Custom plugins
app.use(LanguagePlugin)
app.use(localStorage)
app.use(AxiosPlugin)
app.use(GlobalComponentsPlugin)
app.use(GlobalStorePlugin)
app.use(BugsnagPlugin)

// Init Vue dependencies
app.use(ElementPlus, { locale: elLocaleEn })
app.use(VueProgressBar, { color: config.themeColor, failedColor: 'red', height: '2px' })
app.use(VueFullscreen)
app.use(VueAnnouncer, {}, router)

app.config.globalProperties.$filters = filters
app.config.globalProperties.$config = config

// Run in debug when not in production
app.config.debug = process.env.NODE_ENV !== 'production'
app.config.performance = process.env.NODE_ENV !== 'production'
app.config.devtools = process.env.NODE_ENV !== 'production'

// Tuning compilation
app.config.compilerOptions.whitespace = 'preserve'

// Internationalization
dayjs.locale(config.defaultLanguage)

// Init Custom routes plugins
app.use(RouterBeforeEachPlugin)
app.use(RouterAfterEachPlugin)
app.use(RouterUnsavedChanges)
app.use(globalFunctions, {}, store)
app.use(JobSchedulerPlugin)

// Init global components
app.component('spinner', Spinner)
app.component('thumbnail', Thumbnail)
app.component('appLanguage', AppLanguage)
app.component('pageCover', PageCover)
app.component('pageHeader', PageHeader)
app.component('tableStatus', TableStatus)
app.component('affix', Affix)

// TODO: wait till FocusTrap fix vue-3 issues
// app.component('FocusTrap', FocusTrap)
app.component('SkipToMain', SkipToMain)
app.component('Masonry', Masonry)
app.component('InfiniteLoading', InfiniteLoading)
app.component('SuspenseSpinner', SuspenseSpinner)
app.component('AnimatedTabs', AnimatedTabs)

app.directive('observe-visibility', {
  beforeMount: ObserveVisibility.beforeMount,
  update: ObserveVisibility.update,
  unmounted: ObserveVisibility.unbind
})

export default app
