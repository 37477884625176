<template>
  <section>
    <el-form-item :label="$t('SW_ATTACHMENTS')" class="no-overflow mb-30">
      <el-button-group>
        <el-button type="default" @click="toggleDiscoveryDialog">
          <span class="icon-link"/>
          {{ $t('SW_URL') }}
        </el-button>
        <el-button type="default" @click="pickFile">
          <span class="icon-files"/>
          {{ $t('SW_FILE') }}
        </el-button>
      </el-button-group>

      <el-table :data="form.attachments" :empty-text="$t('SW_NO_ATTACHMENTS')">
        <el-table-column prop="title" label="Name">
          <template v-slot="props">
            <div>
              <el-popover placement="top" width="400" trigger="click">
                <template #reference>
                  <span @mouseover="inputValue = props.row.title || props.row.url">
                    <i :class="getIcon(props.row)"/>
                    <span class="ml-5 attachment-name">{{ props.row.title || props.row.url }}</span>
                  </span>
                </template>

                <!-- Popup -->
                <div class="change-name-form">
                  <h4 class="change-name-title">{{ $t('SW_SET_FILE_NAME') }}</h4>
                  <el-input placeholder="name" v-model="inputValue" @input="changeFileName(inputValue, props.$index)"/>
                </div>
              </el-popover>
            </div>
          </template>
        </el-table-column>

        <el-table-column fixed="right" label="Operations" width="120">
          <template v-slot="props">
            <el-button type="text" size="small" @click="confirmRemove(props.row)">
              <i class="icon-delete"/>
              {{ $t('SW_DELETE') }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-form-item>

    <!-- Discovery dialog -->
    <el-dialog :title="$t('SW_EXTERNAL_URL')" append-to-body v-model="dialogDiscovery">
      <el-input placeholder="https://www.example-url.com" v-model="discovery"/>
      <template #footer>
        <div class="mt-20">
          <el-button type="primary" @click="discover" :loading="discovering">{{ $t('SW_ADD') }}</el-button>
          <el-button type="text" @click="dialogDiscovery = false">{{ $t('SW_CANCEL') }}</el-button>
        </div>
      </template>
    </el-dialog>

    <!-- Delete dialog -->
    <el-dialog :title="$t('SW_DELETE')" append-to-body v-model="dialogDelete">
      <span>{{ $t('SW_DELETE_ATTACH_CONFIRM') }}</span>
      <template #footer>
        <div class="dialog-footer">
          <el-button type="text" @click="dialogDelete = false">{{ $t('SW_CANCEL') }}</el-button>
          <el-button type="primary" @click="removeAttachment">{{ $t('SW_DELETE') }}</el-button>
        </div>
      </template>
    </el-dialog>
  </section>
</template>

<script>
import convertDiacritics from '@/edpack-web/utils/convert-diacritics'
import * as filestack from 'filestack-js'

export default {
  name: 'FormAttachments',
  props: ['form'],

  data () {
    return {
      inputValue: '',
      dialogDelete: false,
      dialogDiscovery: false,
      attachmentToRemove: false,
      discovery: '',
      discovering: false,
      filepicker: filestack.init(this.$config.filestack.key)
    }
  },

  methods: {
    toggleDiscoveryDialog () { this.dialogDiscovery = !this.dialogDiscovery },
    changeFileName (name, index) { this.form.attachments[index].title = name },
    confirmRemove (attachment) {
      this.attachmentToRemove = attachment
      this.dialogDelete = true
    },
    getIcon (attachment) {
      if (attachment.attachmentType === 'link') return 'icon-link'
      if (attachment.mimeType.includes('image')) return 'icon-image'
      if (this.$config.filestack.supported.find(sup => { return attachment.title.endsWith(sup) })) return 'icon-file'
      return 'el-icon-upload2'
    },
    pickFile () {
      const security = { policy: this.$store.state.user.filestack.policy, signature: this.$store.state.user.filestack.signature }
      this.filepicker.setSecurity(security)

      const settings = Object.assign(
        {}, this.$config.filestack.default_settings, {
          accept: ['application/*', 'image/*', 'video/*', 'text/*'],
          imageMax: [1400, 1400],
          onFileSelected: (file) => {
            file.name = convertDiacritics(file.filename.toLowerCase()).replace(/[^a-zA-Z0-9]/g, '_').replace(/_+/g, '_')
            return file
          },
          onFileUploadFailed: (file, err) => this.onError(err),
          onUploadDone: (files) => this.doneUploading(files)
        })

      this.filepicker.picker(settings).open()
    },
    doneUploading (result) {
      result.filesUploaded.forEach(file => {
        if (!this.form.attachments) this.form.attachments = []
        file.title = file.originalFile.name
        file.previewUrl = 'https://cdn.filestackcontent.com/' + this.$config.filestack.key + '/output=format:png/cache=expiry:31536000/' + file.handle
        file.mimeType = file.mimetype
        file.id = this.form.attachments.length
        this.form.attachments.push(file)
      })
      this.$forceUpdate()
    },
    removeAttachment () {
      this.form.attachments = this.form.attachments.filter(att => {
        if (this.attachmentToRemove._id) {
          return att._id !== this.attachmentToRemove._id
        } else if (this.attachmentToRemove.id) return att.id !== this.attachmentToRemove.id
        return att.key !== this.attachmentToRemove.key
      })
      this.dialogDelete = false
    },
    discover () {
      const api = this.$config.oembed_extract
      const key = this.$config.oembed_key

      let url = this.discovery

      const httpConfig = {
        method: 'GET',
        url: api,
        headers: {
          'x-api-key': key
        },
        params: {
          autoplay: 1,
          url,
          skip: 'article'
        },
        timeout: 10000,
        withCredentials: false
      }

      const embed = this.$http.create(httpConfig)

      // Stop if no URL  || !/[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/i.test(url)
      if (!url) {
        return this.$message({ type: 'error', message: this.$t('SW_INVALID_URL') })
      }

      this.discovering = true
      if (!/^https?:\/\//i.test(url)) url = 'http://' + url
      if (!this.form.attachments) this.form.attachments = []

      // Get giphy GIF
      embed.get(api, httpConfig).then(res => {
        // Stop if not successful
        if (res.data.type === 'error') {
          this.$message({ type: 'error', message: this.$t('SW_GENERIC_DISCOVERY_ERROR') })
          this.discovering = false
          return res.data
        }

        const title = res.data.title
        const thumb = res.data.images && res.data.images[0] ? res.data.images[0].url : false
        const mediaType = res.data.oembed && res.data.oembed.type ? res.data.oembed.type : res.data.type
        const mediaHtml = res.data.oembed && res.data.oembed.html ? res.data.oembed.html : false
        const favicon = res.data.favicon ? res.data.favicon.url : false

        const info = {
          id: this.form.attachments.length,
          url: res.data.url,
          description: res.data.description,
          provider: res.data.site,
          mediaType,
          name: title,
          attachmentType: 'link'
        }

        if (title) info.title = title
        if (mediaHtml) info.mediaHtml = mediaHtml
        if (thumb) info.thumbnailUrl = thumb
        if (favicon) info.faviconUrl = favicon

        // Fix for twitter
        if (res.data.site === 'Twitter') delete info.mediaHtml

        this.form.attachments.push(info)
        this.discovery = ''
        this.discovering = false
        this.dialogDiscovery = false
      })
        .catch((e) => {
          this.$notifyBug(e)
          this.$message({ type: 'error', message: this.$t('SW_GENERIC_ERROR') })
          this.discovering = false
        })
    },
    onError (error) {
      console.error(error.toString())
      this.$message({ message: this.$t('SW_ERROR_LOADING'), type: 'error' })
    }
  }
}
</script>

<style lang="scss">
  @use 'style';
</style>
