<template>
  <el-form label-position="top" :model="form">

    <el-form-item :label="$t('SW_COMMENT_MESSAGE')" prop="description" required>
      <el-input type="textarea"
                :autosize="{ minRows: 3, maxRows: 8}"
                :placeholder="$t('SW_COMMENT_MESSAGE_PLACEHOLDER')"
                v-model="form.description">
      </el-input>
    </el-form-item>

    <el-form-item>
      <el-button v-if="!form._id" type="primary" @click="submitComment" :loading="submitting">
        <i class="icon-send"></i>
        {{ $t('SW_SHARE') }}
      </el-button>
      <el-button v-else type="primary" @click="updateComment" :loading="submitting">
        {{ $t('SW_SAVE_CHANGES') }}
      </el-button>
      <el-button type="text" @click="closeDialog(false)">{{ $t('SW_CANCEL') }}</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  name: 'CommentDialog',
  props: ['form', 'closeDialog'],

  data () {
    return {
      submitting: false,
      school: this.$store.state.school,
      lang: this.$store.state.user.language
    }
  },

  methods: {
    submitComment () {
      this.submitting = true

      this.$http.post('comments', this.form)
        .then((res) => {
          this.$message({
            type: 'success',
            message: this.$t(this.form._id ? 'SW_COMMENT_UPDATED' : 'SW_COMMENT_SUBMITTED', [this.$term('plan', 'lc')])
          })
          this.submitting = false
          this.closeDialog(res.data.list[0])
        })
        .catch((e) => {
          this.$notifyBug(e)
          this.submitting = false
        })
    },
    updateComment () {
      this.submitting = true

      this.$http.put(`comments/${this.form._id}`, this.form)
        .then((res) => {
          this.$message({
            type: 'success',
            message: this.$t(this.form._id ? 'SW_COMMENT_UPDATED' : ('SW_COMMENT_SUBMITTED', [this.$term('plan', 'lc')]))
          })
          this.submitting = false
          this.closeDialog(res.data.list[0])
        })
        .catch((e) => {
          this.$notifyBug(e)
          this.submitting = false
        })
    }
  }
}
</script>
