import OrgTemplateSettings from '@/components/OrgTemplateSettings'
import OrgCategoriesSettings from '@/components/OrgCategoriesSettings'

import AppsTable from '@/components/AppsTable'
import PlansTable from '@/components/PlansTable'

export default {
  install (app, options) {
    app.component('OrgTemplateSettings', OrgTemplateSettings)
    app.component('OrgCategoriesSettings', OrgCategoriesSettings)

    app.component('AppsTable', AppsTable)
    app.component('PlansTable', PlansTable)
  }
}
