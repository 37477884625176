<template>
  <div>
    <page-cover class="relative">
      <!-- Logo -->
      <div v-if="school.logoUrl" class="org-logo hidden-xs">
        <a v-if="school.logoLink" :href="school.logoLink" target="_blank">
          <img :src="secureFilestackUrl(school.logoUrl)" class="logo-img" alt="school logo">
        </a>

        <span v-else>
          <img :src="secureFilestackUrl(school.logoUrl)" class="logo-img" alt="school logo">
        </span>
      </div>

      <!-- Header text -->
      <h1 class="text-center slogan">
        <div class="inline" v-if="school.appHeader">
          <span>{{ school.appHeader}}</span>
        </div>
        <div class="inline" v-else>
          {{ $t('SW_FIND') }}
          <strong v-if="school.enablePlans">{{ $t('SW_APPS_AND_PLANS', [$term('plans', 'lc')]) }} </strong>
          <strong v-else>{{ $t('SW_APPS').toLowerCase() }} </strong>
          <span class="hidden-xs hidden-sm">&nbsp;{{ $t('SW_AT') }} {{ school.shortName[lang] }}</span>
        </div>

        <!-- Select language -->
        <app-language class="ml-20"/>
      </h1>

      <!-- Search bar with mini nav -->
      <search-bar/>
    </page-cover>

    <div class="page-body">
      <!-- Explainer by school for new staff members -->
      <el-alert :title="$t('SW_FIRST_TIME')" effect="dark" v-if="!currentUser.checks.orgCourseIntro && school.orgCourseIntro[lang]" class="mb-20 no-border-radius" @close="closeInfoBox" show-icon>
        <div class="redactor-in" v-html="school.orgCourseIntro[lang]"/>
      </el-alert>

      <!-- Latest lesson plans -->
      <div class="mb-10" v-if="school.enablePlans">
        <h2 class="mb-20">
          <span class="hidden-xs hidden-sm">{{ $t('SW_HOME_PLANS', [$term('plans', 'lc')]) }}</span>
          <span class="visible-xs-inline visible-sm-inline">{{ $term('plans') }}</span>

          <!-- Create lesson plan -->
          <el-button v-if="canCreate" class="ml-20" type="text" @click="$router.push({name: 'new-plan', params: { slug: school.slug }})">
            <i class="icon-add"/>
            <span class="hidden-xs hidden-sm">{{ $t('SW_MAKE_OWN_PLAN', [$term('plan', 'lc')]) }}</span>
            <span class="visible-xs-inline visible-sm-inline">{{ $term('plan') }}</span>
          </el-button>
        </h2>

        <!-- Lessons -->
        <plans-list/>
      </div>
      <br>

      <!-- Recommended apps -->
      <h2 class="bold mb-20">
        <span class="hidden-xs hidden-sm">{{ $t('SW_RECOMMENDED_APPS', [school.shortName]) }}</span>
        <span class="visible-xs-inline visible-sm-inline">{{ $t('SW_APPS') }}</span>

        <!-- Give app suggestion -->
        <el-button type="text" @click="toggleDialog" class="ml-20" v-if="school.notificationEmail">
          <i class="icon-light-bulb"/>
          <span class="hidden-xs hidden-sm">{{ $t('SW_GIVE_SUGGESTION') }}</span>
          <span class="visible-xs-inline visible-sm-inline">{{ $t('SW_SUGGESTION') }}</span>
        </el-button>
      </h2>

      <!-- Filter and apps -->
      <el-row :gutter="20">
        <el-col :sm="9" :md="6" :lg="5" class="hidden-xs">
          <filter-panel :categories="categories" :sort="sort" :updateFilters="updateFilters"/>
        </el-col>
        <el-col :xs="24" :sm="15" :md="18" :lg="19">
          <div v-if="!loading" class="apps-grid">
            <section class="mb-20" v-for="(value, key) in apps" :key="key">
              <h3 class="mb-20">{{key}}</h3>
              <masonry :cols="{default: 3, 1199: 3, 1023: 2, 767: 1}"
                       :gutter="{default: '30px', 767: '20px'}" class="hide-categories">
                <app-tile v-for="page in apps[key]" :app="page" :key="page._id"/>
              </masonry>
            </section>
          </div>
          <div v-if="!loading && !apps" class="text-muted mt-30 text-center">{{ $t('SW_NO_APPS_FOUND') }}</div>

          <!-- Loading -->
          <spinner v-if="loading"/>
        </el-col>
      </el-row>
    </div>

    <!-- Suggestions dialog -->
    <el-dialog :title="$t('SW_GIVE_SUGGESTION')" custom-class="text-left" append-to-body v-model="dialogSuggestion">
      <suggestion-form :close-dialog="toggleDialog" v-if="dialogSuggestion"/>
    </el-dialog>
  </div>
</template>

<script>
import AppTile from '@/components/AppTile'
import SearchBar from '@/components/SearchBar'
import FilterPanel from '@/components/FilterPanel'
import PlansList from '@/components/PlansList'
import SuggestionForm from '@/components/SuggestionForm'
import secureFilestackUrl from '@/edpack-web/utils/filestack/secure-url'

export default {
  name: 'Home',
  components: { SuggestionForm, SearchBar, FilterPanel, PlansList, AppTile },

  data () {
    return {
      dialogSuggestion: false,
      canCreate: this.$store.state.school.enablePlans && this.$store.state.school.enablePlansByStaff && this.$store.state.user.role !== 'student',
      lang: this.$store.state.user.language,
      school: this.$store.state.school,
      currentUser: this.$store.state.user,
      loading: false,
      apps: false,
      categories: [],
      sort: 'category'
    }
  },

  created () {
    this.getApps()
  },

  methods: {
    getApps () {
      this.loading = true

      const params = {
        organization: this.school._id,
        categories: 'all',
        amount: 0,
        status: 'published',
        sort: 'name',
        order: 1
      }

      this.$http.get('pages', { params }).then(
        res => {
          this.apps = this.groupByCategory(res.data.list)
          this.loading = false
        })
        .catch((e) => {
          this.$notifyBug(e)
          this.loading = false
        })
    },
    closeInfoBox () {
      this.$store.state.user.checks.orgCourseIntro = true
      this.$http.put(`users/${this.currentUser._id}`, { checks: this.$store.state.user.checks })
        .then((res) => { /*  User checks updated! */ })
    },
    groupByCategory (apps) {
      if (!apps.length) return false

      const appsByCategory = {}
      for (const app of apps) {
        let category = this.$t('SW_NO_CATEGORY')
        const mainCategory = app.categories.find(cat => cat._id === this.school.categories[0]._id)
        if (mainCategory) category = app.categories[0].filters[0][this.lang]

        if (!appsByCategory[category]) appsByCategory[category] = []
        appsByCategory[category].push(app)
      }
      return appsByCategory
    },
    updateFilters (filters) {
      // redirect user to Search page and do all search request there
      this.$router.push({
        name: 'search',
        query: filters,
        params: { type: 'apps', slug: this.school.slug }
      })
    },
    toggleDialog () { this.dialogSuggestion = !this.dialogSuggestion },
    secureFilestackUrl (url) { return secureFilestackUrl(url, this.currentUser) }
  }
}
</script>

<style lang="scss">
  @use 'style';
</style>
