import numeral from 'numeral'
import truncate from '@/edpack-web/utils/truncate'
import 'numeral/locales/en-gb'

import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import localizedFormat from 'dayjs/plugin/localizedFormat'
dayjs.extend(relativeTime)
dayjs.extend(localizedFormat)

numeral.locale('en-gb')

const filters = {
  /**
   * Transform value with a given format, otherwise return 0
   * for example numeral(3,0123012313) to 3,01, etc
   *
   * @param value {number} the number which you want to transform
   * @param format {string} represent the return format of number
   * all formats variants listed here: http://numeraljs.com/ in Format section
   * @return {number} formatted number
   * */
  numeral (value, format = '0a') {
    if (!value) return 0
    return numeral(value).format(format)
  },

  /**
   * Make first letter uppercase
   * @param value {string} the sentence which first letter you want to capitalize
   * @return {string} sentence with capitalized first letter
   * */
  capitalize (value) {
    if (!value) return ''
    return value.charAt(0).toUpperCase() + value.slice(1)
  },

  /**
   * Calculate time distance from NOW to specific dayjs in a time
   * @param date {Object} the target date
   * @param withoutSuffix {boolean} pass true if you want to have not 'ago' in the end of result '2 years || 2 years ago'
   * @param emptyResponse {string} what use if value is null, default ''
   * @return {string} sentence with distance in time
   * */
  fromNow (date, withoutSuffix = false, emptyResponse = '') {
    if (!date) return emptyResponse
    return dayjs(date).fromNow(withoutSuffix)
  },

  /**
   * Truncate string to prevent overflow from block or design purpose
   * @param text {string} the text for truncate
   * @param length {number} the maximum text length
   * @param clamp {string} what to add in the end in case of truncating
   * @return {string} truncated sentence
   * */
  truncate (text = '', length = 30, clamp = '...') {
    if (!text) return ''
    return truncate(text, length, clamp)
  },

  /**
   * Format date to correct format
   * @param date {Object} the date object you want to format
   * @param format {string} what to add in the end in case of truncating, default value is 'lll' which means "October 21, 2021 12:05 PM"
   * Available formats list here: https://www.dayjs.org/ section 'Multiple Locale Support'
   * @return {Object} truncated sentence
   * */
  startEndFormat (date, format = 'lll') {
    return dayjs(date).format(format)
  }
}

export default filters
