<template>
  <div @scroll="updateParallaxDistance">
    <!-- Plan cover -->
    <section>
      <section :class="plan.coverUrl ? 'parallax-wrapper' : 'parallax-wrapper-small'">
        <div v-if="plan.coverUrl" class="plan-cover" :style="{top: `-${scrollAmount}%`, backgroundImage: 'url(' + getResizeUrl(plan.coverUrl, { width: 1800, height: 820 }) + ')'}"/>

        <div class="plan-header-content">
          <div class="plan-header-inner">
            <div class="pull-right">
              <!-- Edit button -->
              <el-button class="button-square-xs" v-if="user.role === 'admin' || user.systemAdmin"
                         @click="$router.push({ name: 'edit-plan', params: { id: plan._id, slug: school.slug } })">
                <i class="icon-pencil"/>
                <span class="hidden-xs hidden-sm">{{ $t('SW_EDIT') }}</span>
              </el-button>

              <app-language class="ml-10"/>
            </div>

            <!-- App title -->
            <h1 class="app-title text-ellipsis">
              {{plan.title[lang]}}
            </h1>

            <!-- Categories -->
            <div class="text-ellipsis">
              <span class="hidden-xs">{{$term('plan') }} &centerdot; </span>
              <span class="hidden-xs" v-if="plan.author"> {{ $t('SW_BY') }} {{ plan.author }} &centerdot; </span>
              <span v-for="(category, index) in plan.categories" :key="index">
              <span v-for="(filter, filterIndex) in category.filters" :key="filterIndex">
                <router-link :to="{name: 'search', query: {categories: [filter._id]}, params: {type: 'plans', slug: school.slug}}" class="category">
                  {{ filter[lang] }}
                </router-link>
                <span v-if="category.filters.length > (filterIndex + 1)"> & </span>
              </span>
              <span v-if="plan.categories.length > (index + 1)"> & </span>
            </span>
              <span v-if="!plan.categories.length">{{ $t('SW_NO_CATEGORY') }}</span>
            </div>
          </div>
        </div>
      </section>
    </section>
  </div>
</template>

<script>
import getResizeUrl from '@/edpack-web/utils/filestack/get-resize-url'

export default {
  name: 'PlanHeader',
  props: ['plan'],

  data () {
    return {
      scrollAmount: 0,
      user: this.$store.state.user,
      school: this.$store.state.school,
      lang: this.$store.state.user.language
    }
  },
  mounted () {
    document.addEventListener('scroll', this.updateParallaxDistance)
  },

  unmounted () {
    document.removeEventListener('scroll', this.updateParallaxDistance)
  },

  methods: {
    getResizeUrl (url, options) {
      return getResizeUrl(url, options, this.user)
    },
    updateParallaxDistance () {
      if (!this.plan.coverUrl) return
      const SCROLL_COEF = 20 // setup scroll speed, higher number - slower speed
      const MAX_VALUE = 10 // image height 110%, so we can scroll for 10%
      const value = window.scrollY / SCROLL_COEF
      if (value <= MAX_VALUE) this.scrollAmount = value
    }
  }
}
</script>

<style lang="scss">
  @use 'style';
</style>
