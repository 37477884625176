<template>
  <div>
    <affix class="sticky-bar" relative-element-selector="#sticky-content" :offset="{ top: 128, bottom: -1000 }">
      <el-row type="flex" justify="center" align="middle">
        <el-col :xs="11" :sm="16">

          <div v-if="multipleSelection.length">
            <!-- Change status dropdown -->
            <el-badge :value="multipleSelection.length">
              <el-dropdown trigger="click" @command="handleStatus">
                <el-button type="primary" class="mr-5" plain>
                  <span>{{ $t('SW_STATUS') }}</span>
                  <i class="icon-caret-down"/>
                </el-button>

                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item :command="{type: 'publish'}">
                      <span>{{ $t('SW_PUBLISHED') }}</span>
                    </el-dropdown-item>
                    <el-dropdown-item :command="{type: 'unpublish'}">
                      <span>{{ $t('SW_DRAFT') }}</span>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </el-badge>

            <!-- Email users -->
            <el-badge :value="multipleSelection.length" v-if="user.systemAdmin">
              <el-button @click="dialogEmail = true" class="mr-5 button-square-xs">
                <i class="icon-email"/>
                <span class="hidden-xs">{{ $tc('SW_EMAIL', 1) }}</span>
              </el-button>
            </el-badge>

            <!-- Remove plans -->
            <el-badge :value="multipleSelection.length">
              <el-button type="danger" plain @click="confirmRemove" class="mr-5 button-square-xs">
                <i class="icon-delete"/>
                <span class="hidden-xs hidden-sm">{{ $t('SW_REMOVE') }}</span>
              </el-button>
            </el-badge>

            <!-- Clear selection -->
            <el-button type="text" @click="selectionChange" class="ml-10">
              <i class="icon-clear"/>
              <span class="hidden-xs hidden-sm">{{ $t('SW_CANCEL') }}</span>
            </el-button>
          </div>

          <div v-else>
            <!-- Create plan -->
            <el-button type="primary" class="button-square-xs" plain @click="$router.push({ name: 'new-plan', params: { slug: school.slug } })">
              <i class="icon-add"/>
              <span class="hidden-xs">{{ $t('SW_CREATE_NEW_PLAN', [$term('plan')]) }}</span>
            </el-button>

            <!-- Total plans found -->
            <span v-show="status !== 'loading' || tableData.length" class="hidden-xs hidden-sm ml-10 line-height-38">
              {{ total }} {{ $term('plans', 'lc') }}
            </span>
          </div>
        </el-col>

        <el-col :xs="13" :sm="8">
          <!-- Search input -->
          <el-input v-model="searchText" clearable :placeholder="$t('SW_SEARCH_PLANS', [$term('plan', 'lc')])"></el-input>
        </el-col>
      </el-row>
    </affix>
    <div class="bar-placeholder"/>

    <el-alert type="info" class="mt-20" :title="$t('SW_PLANS_TIP', [$term('plans', 'lc')])" v-if="!user.checks.plansTableInfo"  @close="closedInfoBox" show-icon>
      <p class="mb-10">{{ $t('SW_PLANS_EXPLAIN', [$term('plans', 'lc'), school.name[lang].toLowerCase(), $term('students', 'lc')]) }}</p>
    </el-alert>

    <!-- Plans table -->
    <el-table v-show="tableData.length" :data="tableData" row-key="_id" ref="plansTable" @sort-change="sortChange" id="sticky-content"
              @selection-change="selectionChange" :default-sort="{prop: this.sort, order: this.order}" class="mt-20">
      <!-- Selection -->
      <el-table-column type="selection" reserve-selection width="35"/>

      <!-- Name -->
      <el-table-column property="title" :label="$t('SW_TITLE')" min-width="160" :sort-method="sortCaseInsensitive" sortable>
        <template v-slot="scope">
          <router-link class="text-ellipsis" :to="{ name: 'plan', params: { id: scope.row._id, slug: school.slug } }">
            <strong class="block text-ellipsis">{{ scope.row.title[lang] }}</strong>
          </router-link>
        </template>
      </el-table-column>

      <!-- Edit lesson plan -->
      <el-table-column width="130" :label="$t('SW_EDIT')">
        <template v-slot="scope">
          <el-button type="text" size="small" @click="$router.push({ name: 'edit-plan', params: { id: scope.row._id, slug: school.slug } })">
            <i class="icon-pencil"/>
            <span>{{ $t('SW_EDIT') }}</span>
          </el-button>
        </template>
      </el-table-column>

      <!-- Status -->
      <el-table-column width="130" :label="$t('SW_STATUS')">
        <template v-slot="scope">
          <div class="text-warning" v-if="scope.row.status === 'draft'">
            <strong>{{ $t('SW_DRAFT') }}</strong>
          </div>
          <div class="text-success" v-else-if="scope.row.status === 'published'">
            <strong>{{ $t('SW_PUBLISHED') }}</strong>
          </div>
        </template>
      </el-table-column>

      <!-- Apps -->
      <el-table-column :label="$t('SW_APPS')" prop="pages" min-width="160">
        <template v-slot="scope">
          <el-tooltip :show-arrow="false" :show-after="300" :enterable="false" :content="page.name" class="pull-left" v-for="page in scope.row.pages" :key="page._id">
            <thumbnail :model="page" class="app-thumb thumb-24 mr-5"/>
          </el-tooltip>
          <span v-if="!scope.row.pages || !scope.row.pages.length" class="text-muted">-</span>
        </template>
      </el-table-column>

      <!-- Categories -->
      <el-table-column property="categories" min-width="160" :label="$t('SW_CATEGORIES')">
        <template v-slot="scope">
          <div class="text-ellipsis" v-if="scope.row.categories && scope.row.categories.length">
            <span v-for="(category, index) in scope.row.categories[0].filters" :key="category.en">
            {{ category[lang] }}<span class="text-muted" v-if="index < (scope.row.categories[0].filters.length -1)">,</span>
          </span>
          </div>
          <span v-else class="text-muted">-</span>
        </template>
      </el-table-column>

      <!-- Attachment count -->
      <el-table-column :label="$t('SW_ATTACHMENTS')" prop="attachments.length" width="90">
        <template v-slot="scope">
          <i class="icon-file"/>
          {{ scope.row.attachments.length }}
        </template>
      </el-table-column>

      <!-- View count -->
      <el-table-column :label="$t('SW_VIEWS')" prop="views" width="90">
        <template v-slot="scope">
          <i class="icon-seen"/>
          {{ scope.row.views }}
        </template>
      </el-table-column>

      <!-- Comment count -->
      <el-table-column :label="$t('SW_COMMENTS')" prop="counts.comments" width="90">
        <template v-slot="scope">
          <i class="icon-chat"/>
          {{ scope.row.counts.comments }}
        </template>
      </el-table-column>

      <!-- Created date -->
      <el-table-column property="createdDate" :formatter="dateFormatter" :sort-method="sortCreatedDate" :label="$t('SW_CREATED_DATE')" min-width="160" sortable/>
    </el-table>

    <!-- Infinite loading -->
    <infinite-loading ref="infiniteLoading" @infinite="getPlans(false)">
      <template #spinner><span/></template>
      <template #no-results><span/></template>
      <template #no-more><span/></template>
    </infinite-loading>

    <table-status :status="status" :noneText="$t('SW_NO_PLANS_FOUND', [$term('plans', 'lc')])" @clearSearch="searchText = ''"/>

    <!-- Email dialog -->
    <el-dialog :title="$tc('SW_SEND_EMAIL_TO_SELECTION_USERS', multipleSelection.length)" append-to-body v-model="dialogEmail">
      <email-users v-if="dialogEmail" :selectedUsers="multipleSelection" :closeDialog="closeDialog"/>
    </el-dialog>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import dateSorter from '@/edpack-web/utils/date-sorter'
import EmailUsers from '@/edpack-web/components/EmailUsers'
import TableStatus from '@/edpack-web/components/TableStatus'

export default {
  name: 'PlansTable',
  components: { TableStatus, EmailUsers },

  data () {
    return {
      status: false,
      searchText: this.$route.query.query || '',
      sort: 'createdDate',
      order: 'ascending',
      tableData: [],
      inLTI: (window.self !== window.top),
      skip: false,
      total: false,
      plan: false,
      multipleSelection: [],
      user: this.$store.state.user,
      lang: this.$store.state.user.language,
      school: this.$store.state.school,
      currentRow: null,
      dialogEmail: false
    }
  },

  watch: {
    searchText: debounce(function () {
      this.$router.replace({ query: { query: this.searchText } })
    }, 400),
    $route: {
      immediate: false,
      flush: 'post',
      handler (route) {
        if (route.params.mode !== 'plans') return
        this.selectionChange()
        this.getPlans(true)
      }
    }
  },

  mounted () {
    this.getPlans()
  },

  methods: {
    getPlans (refresh) {
      if (this.status === 'loading') return
      this.status = 'loading'

      const params = {
        organization: this.school._id,
        amount: 30,
        sort: this.sort,
        order: this.order === 'ascending' ? (this.sort === 'name' ? '1' : '-1') : (this.sort === 'name' ? '-1' : '1')
      }

      if (refresh) {
        this.$refs.infiniteLoading.stateChanger.reset()
        this.tableData = []
        this.skip = 0
      }

      if (this.skip) params.skip = this.skip
      if (this.searchText) params.filter = this.searchText

      this.$http.get('plans', { params })
        .then((res) => {
          this.tableData = this.tableData.concat(res.data.list)
          this.status = res.data.total ? (res.data.done ? 'done' : 'incomplete') : (this.searchText ? 'noResults' : 'none')
          this.skip = res.data.skip
          this.total = res.data.total

          if (res.data.done) {
            this.$refs.infiniteLoading.stateChanger.loaded()
            this.$refs.infiniteLoading.stateChanger.complete()
          } else {
            this.$refs.infiniteLoading.stateChanger.loaded()
          }
        })
        .catch((e) => {
          this.$notifyBug(e)
          this.status = 'error'
        })
    },
    selectionChange (val) {
      if (Array.isArray(val)) {
        this.multipleSelection = val
        return
      }

      this.multipleSelection = []
      if (this.$refs.plansTable) this.$refs.plansTable.clearSelection()
    },
    handleStatus (command) {
      this.currentRow = command.row
      command.type === 'publish' ? this.confirmPublish() : this.confirmUnpublish()
    },
    sortChange (val) {
      if (val.prop === this.sort && val.order === this.order) return
      this.sort = val.prop
      this.order = val.order
      if (this.status === 'done') return
      this.getPlans(true)
    },
    confirmPublish () {
      this.$confirm(this.$t('SW_PUBLISH_CONFIRM'), this.$t('SW_PUBLISH'), {
        confirmButtonText: this.$t('SW_PUBLISH'),
        cancelButtonText: this.$t('SW_CANCEL')
      }).then(() => { this.toggleStatus('published') })
    },
    confirmUnpublish () {
      this.$confirm(this.$t('SW_UNPUBLISH_CONFIRM'), this.$t('SW_UNPUBLISH'), {
        confirmButtonText: this.$t('SW_UNPUBLISH'),
        cancelButtonText: this.$t('SW_CANCEL')
      }).then(() => { this.toggleStatus('draft') })
    },
    confirmRemove () {
      const text = this.$t('SW_DELETE_PLANS_CONFIRM', [this.$term('plans', 'lc')])
      this.$confirm(text, this.$t('SW_DELETE_PLANS', [this.$term('plans', 'lc')]), {
        confirmButtonText: this.$t('SW_DELETE'),
        cancelButtonText: this.$t('SW_CANCEL')
      }).then(() => { this.removePlans() })
    },
    removePlans () {
      const plans = this.multipleSelection.map(plan => plan._id)

      this.submitting = true
      this.$http.delete('plans', { params: { plans } })
        .then(res => {
          this.removeFromTable(plans)
          this.multipleSelection = []
          this.$message({
            type: 'success',
            message: this.$t('SW_PLAN_DELETED', [this.$term('plans')])
          })
        })
        .catch((e) => {
          this.$notifyBug(e)
          this.$message({ type: 'error', message: this.$t('SW_GENERIC_ERROR') })
        })
        .finally(() => { this.submitting = false })
    },
    removeFromTable (plans) {
      this.tableData = this.tableData.filter(plan => !plans.find(_id => plan._id === _id))
      this.skip = this.skip - plans.length
      this.$emit('refreshTable')
      if (this.tableData.length === 0) this.status = 'none'
    },
    closeDialog (refresh) {
      this.dialogEmail = false

      if (refresh) {
        this.searchText = ''
        this.selectionChange()
        this.getPlans(true)
      }
    },
    closedInfoBox () {
      this.$store.state.user.checks.plansTableInfo = true
      this.$http.put(`users/${this.user._id}`, { checks: this.$store.state.user.checks })
        .then((res) => { /*  User checks updated! */ })
    },
    toggleStatus (type) {
      const toSend = []
      for (const selection of this.multipleSelection) {
        selection.status = type
        toSend.push({ status: selection.status, _id: selection._id })
      }
      this.submitting = true
      this.$http.put('plans', { plans: toSend })
        .then(res => {
          this.$message({ type: 'success', message: this.$t('SW_PLANS_' + type.toUpperCase()) })
          this.getPlans()
        })
        .catch((e) => {
          this.$notifyBug(e)
          this.$message({ type: 'error', message: this.$t('SW_GENERIC_ERROR') })
        })
        .finally(() => { this.submitting = false })
    },
    sortCaseInsensitive (a = { title: {} }, b = { title: {} }) {
      let planName1 = a.title[this.lang] || ''
      let planName2 = b.title[this.lang] || ''

      planName1 = planName1.toLowerCase()
      planName2 = planName2.toLowerCase()

      if (planName1 < planName2) return -1
      if (planName1 > planName2) return 1
      return 0
    },
    goToHome () { this.$router.push({ name: 'home', params: { slug: this.school.slug } }) },
    dateFormatter (row, column, value) { return this.$filters.fromNow(value) },
    sortCreatedDate (a, b) { return dateSorter(a.createdDate, b.createdDate) }
  }
}
</script>
