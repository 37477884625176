<template>
  <div>
    <page-cover>
      <search-bar/>
      <mini-nav class="text-center mt-10"/>
    </page-cover>

    <div class="page-body">
      <div class="mb-30 clearfix">
        <!-- Toggle plans - apps -->
        <el-radio-group v-model="shadowType" class="pull-left mr-20" v-if="school.enablePlans" @change="updateFilters">
          <el-radio-button label="apps">
            <i class="icon-box_view"/>
            {{ $t('SW_APPS') }}
          </el-radio-button>
          <el-radio-button label="plans">
            <i class="icon-chat-lines"/>
            {{$term('plans')}}
          </el-radio-button>
        </el-radio-group>

        <strong v-show="status !== 'loading'" v-if="type === 'apps'" class="line-height-40 hidden-xs">
          {{ $t('SW_APP_RESULTS_FOUND', [items.length]) }}
        </strong>
        <strong v-show="status !== 'loading'" v-else class="line-height-40 hidden-xs">
          {{$t('SW_PLAN_RESULTS_FOUND', [items.length, $term('plans')]) }}
        </strong>
        <el-button v-if="Object.keys($route.query).length !== 0 && status !== 'loading'" type="text" class="ml-10"
                   @click="$router.push({name: 'search', params: { type: type, slug: school.slug }, query: { }})">
          <i class="icon-clear"/>{{ $t('SW_CLEAR_FILTERS') }}
        </el-button>
      </div>

      <el-row :gutter="20">
        <!-- Filter sidebar -->
        <el-col :sm="9" :md="6" :lg="5" class="hidden-xs">
          <filter-panel :modeType="type" :categories="categories" :faculty="faculty" :sort="sort" :advice="advice" :updateFilters="updateFilters"/>
        </el-col>

        <!-- Items grid -->
        <el-col :xs="24" :sm="15" :md="18" :lg="19">
          <div v-show="status !== 'loading' && items.length">
            <!-- Apps -->
            <div v-if="type === 'apps'" class="apps-grid">
              <masonry :cols="{default: 3, 1199: 3, 1023: 2, 767: 1}" :gutter="{default: '30px', 767: '20px'}" :class="{'hide-categories': categories !== []}">
                <app-tile v-for="page in items" :app="page" :key="page._id"/>
              </masonry>
            </div>
            <!-- Plans -->
            <div v-if="type === 'plans'" class="plans-grid">
              <masonry :cols="{default: 3, 1199: 3, 1023: 2, 767: 1}" :gutter="{default: '30px', 767: '20px'}" :class="{'hide-categories': categories !== 'all'}">
                <plan-tile v-for="plan in items" :plan="plan" :key="plan._id"/>
              </masonry>
            </div>
          </div>

          <!-- No items -->
          <div v-if="status === 'none'" class="text-muted">
            {{ $t('SW_RESULTS_NONE') }}
            <span v-if="query || label">{{ $t('SW_FOR') }} '{{ query || label }}'</span>
            <span v-if="selectedFaculty">{{ $t('SW_FOR') }} {{ $term('faculty', 'lc') }} '{{ selectedFaculty[lang] }}'</span>
          </div>

          <!-- Loading -->
          <spinner v-if="status === 'loading'" class="mt-30"/>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import AppTile from '@/components/AppTile'
import MiniNav from '@/components/MiniNav'
import PlanTile from '@/components/PlanTile'
import SearchBar from '@/components/SearchBar'
import FilterPanel from '@/components/FilterPanel'

export default {
  name: 'Search',
  components: { SearchBar, MiniNav, FilterPanel, AppTile, PlanTile },

  data () {
    return {
      lang: this.$store.state.user.language,
      school: this.$store.state.school,
      username: this.$store.state.user.name,
      inLTI: (window.self !== window.top),
      status: 'loading',
      items: [],
      type: this.$route.params.type || 'apps',
      shadowType: this.$route.params.type || 'apps',
      label: this.$route.query.label || '',
      faculty: this.$route.query.faculty || 'all',
      categories: this.$route.query.categories ? Array.isArray(this.$route.query.categories) ? this.$route.query.categories : [this.$route.query.categories] : [],
      advice: this.$route.query.advice || 'all',
      query: this.$route.query.query || '',
      sort: this.$route.query.sort || 'alphabetical'
    }
  },

  watch: {
    $route: {
      immediate: false,
      flush: 'post',
      handler (to) {
        if (to.name !== 'search') return
        this.sort = to.query.sort || 'alphabetical'
        this.query = to.query.query || ''
        this.label = to.query.label || ''
        this.categories = to.query.categories || []
        this.advice = to.query.advice || 'all'
        this.faculty = to.query.faculty || 'all'
        this.getItems()
      }
    }
  },

  computed: {
    selectedFaculty () {
      return this.school.faculties.find(fac => this.faculty === fac._id)
    }
  },

  created () {
    this.getItems()
  },

  methods: {
    getItems () {
      this.status = 'loading'

      const getType = this.type === 'apps' ? 'pages' : 'plans'

      // Remove undefined elements in categories
      if (Array.isArray(this.categories)) this.categories = this.categories.filter(el => el)

      const params = {
        organization: this.school._id,
        amount: 0,
        status: 'published',
        sort: 'name',
        order: 1
      }

      if (this.sort === 'views') {
        params.sort = 'views'
        params.order = -1
      } else if (this.sort === 'followers') {
        params.sort = 'staticCounts.followers'
        params.order = -1
      }

      if (this.categories && this.categories !== []) params.categories = this.categories
      if (this.faculty && this.faculty !== 'all') params.faculties = [this.faculty]
      if (this.label) params.labels = [this.label]
      if (this.query) params.filter = this.query
      if (this.advice) params.advice = this.advice

      this.$http.get(getType, { params }).then(
        res => {
          this.items = res.data.list.map(item => {
            item.categories = item.categories[0] ? item.categories[0][this.lang] : false
            return item
          })
          this.status = this.items.length ? 'done' : 'none'
        })
        .catch((e) => {
          this.$notifyBug(e)
          this.status = 'error'
        })
    },
    updateFilters (filters) {
      this.items = []
      this.type = this.shadowType

      // Remove undefined elements in categories
      if (Array.isArray(this.categories)) this.categories = this.categories.filter(el => el)

      const query = {
        faculty: filters.faculty || this.faculty,
        categories: this.categories,
        label: this.label,
        query: this.query,
        advice: this.advice
      }

      if (filters.categories) { query.categories = filters.categories }
      if (filters.sort) query.sort = filters.sort
      if (filters.advice) query.advice = filters.advice

      // delete advice query if type plans
      if (this.type === 'plans') { delete query.advice }

      this.$router.push({
        name: 'search',
        query,
        params: { type: this.type, slug: this.school.slug }
      })
    }
  }
}
</script>
