<template>
  <el-form label-position="top" :model="experienceForm">
    <p class="mb-10">
      {{ $t('SW_SHARE_TEXT', [experienceForm.page.name, school.shortName[lang]]) }}
    </p>

    <el-form-item :label="$t('SW_EXPERIENCE_ONELINER')" prop="oneliner" required>
      <el-input
        :placeholder="$t('SW_EXPERIENCE_ONELINER_PLACEHOLDER', [experienceForm.page.name])"
        v-model="experienceForm.oneliner">
      </el-input>
    </el-form-item>

    <el-form-item :label="$t('SW_EXPERIENCE_MESSAGE')">
      <el-input type="textarea"
                :autosize="{ minRows: 3, maxRows: 8}"
                :placeholder="$t('SW_EXPERIENCE_MESSAGE_PLACEHOLDER', [experienceForm.page.name])"
                v-model="experienceForm.remarks">
      </el-input>
    </el-form-item>

    <el-form-item :label="$t('SW_EXPERIENCE_POSITIVE_POINTS')">
      <el-row :gutter="20">
        <el-col :span="12">
          <div v-for="(positivePoint, index) in experienceForm.positive" :key="index">
            <el-input v-model="experienceForm.positive[index]" :placeholder="$t('SW_ADD_POSITIVE_PLACEHOLDER')" @clear="clearingPoints" :clearable="true">
              <template #prepend>
                <i class="icon-add-circle-full text-success"/>
              </template>
            </el-input>
          </div>
          <!-- Add experience -->
          <el-button @click="addExperiencePoint('positive')" class="mt-10 block">
            <i class="icon-add"/>
            {{ $t('SW_ADD_POSITIVE') }}
          </el-button>
        </el-col>

        <el-col :span="12">
          <div v-for="(negativePoint, index) in experienceForm.negative" :key="index">
            <el-input v-model="experienceForm.negative[index]" :placeholder="$t('SW_ADD_NEGATIVE_PLACEHOLDER')" @clear="clearingPoints" :clearable="true">
              <template #prepend>
                <i class="icon-minus-circle-full text-danger"/>
              </template>
            </el-input>
          </div>
          <!-- Add experience -->
          <el-button @click="addExperiencePoint('negative')" class="mt-10 block">
            <i class="icon-add"/>
            {{ $t('SW_ADD_NEGATIVE') }}
          </el-button>
        </el-col>
      </el-row>

    </el-form-item>

    <el-form-item>
      <el-button v-if="!experienceForm._id" type="primary" @click="submitExperience" :loading="submitting">
        <i class="icon-send"/>
        {{ $t('SW_SHARE') }}
      </el-button>
      <el-button v-else type="primary" @click="updateExperience" :loading="submitting">
        {{ $t('SW_SAVE_CHANGES') }}
      </el-button>
      <el-button type="text" class="ml-10" @click="closeDialog(false)">{{ $t('SW_CANCEL') }}</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  name: 'ExperienceDialog',
  props: ['experienceForm', 'closeDialog'],

  data () {
    return {
      submitting: false,
      school: this.$store.state.school,
      lang: this.$store.state.user.language
    }
  },

  methods: {
    submitExperience () {
      this.submitting = true

      this.experienceForm.positive = this.experienceForm.positive.filter(point => point)
      this.experienceForm.negative = this.experienceForm.negative.filter(point => point)

      this.$http.post('experiences', this.experienceForm)
        .then((res) => {
          this.$message({
            type: 'success',
            message: this.$t(this.experienceForm._id ? 'SW_EXPERIENCE_UPDATED' : 'SW_EXPERIENCE_SUBMITTED')
          })
          this.submitting = false
          this.closeDialog(res.data.list[0])
        })
        .catch((e) => {
          this.$notifyBug(e)
          this.submitting = false
        })
    },
    addExperiencePoint (type) {
      if (!['positive', 'negative'].includes(type)) return

      const length = this.experienceForm[type].length
      if (!length) {
        this.experienceForm[type].push('')
      } else if (this.experienceForm[type][length - 1]) this.experienceForm[type].push('')
    },
    clearingPoints () {
      const newPositive = []
      const newNegative = []

      for (const point of this.experienceForm.positive) if (point) newPositive.push(point)
      for (const point of this.experienceForm.negative) if (point) newNegative.push(point)

      this.experienceForm.positive = newPositive
      this.experienceForm.negative = newNegative
    },
    updateExperience () {
      this.submitting = true

      this.clearingPoints()

      this.$http.put(`experiences/${this.experienceForm._id}`, this.experienceForm)
        .then((res) => {
          this.$message({
            type: 'success',
            message: this.$t(this.experienceForm._id ? 'SW_EXPERIENCE_UPDATED' : 'SW_EXPERIENCE_SUBMITTED')
          })
          this.submitting = false
          this.closeDialog(res.data.list[0])
        })
        .catch((e) => {
          this.$notifyBug(e)
          this.submitting = false
        })
    }
  }
}
</script>
