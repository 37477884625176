import { createRouter, createWebHistory } from 'vue-router'

// Primary page components
import Home from '@/pages/Home'
import AppPage from '@/pages/AppPage'
import Plan from '@/pages/Plan'
import Search from '@/pages/Search'
import Landing from '@/edpack-web/pages/Landing'
import UserProfile from '@/pages/UserProfile'
import About from '@/pages/About'

// Use lazy loading for secondary page components
const OrgSettings = () => import('@/pages/OrgSettings')
const AdminPanel = () => import('@/pages/AdminPanel')
const StaticPrivacy = () => import('@/pages/StaticPrivacy')
const StaticTerms = () => import('@/pages/StaticTerms')
const UserAccount = () => import('@/edpack-web/pages/UserAccount')
const Error = () => import('@/edpack-web/pages/Error')
const Invitation = () => import('@/edpack-web/pages/Invitation')
const Reset = () => import('@/edpack-web/pages/Reset')
const Forgot = () => import('@/edpack-web/pages/Forgot')
const NewApp = () => import('@/pages/NewApp')
const EditApp = () => import('@/pages/EditApp')
const NewPlan = () => import('@/pages/NewPlan')
const EditPlan = () => import('@/pages/EditPlan')
const OauthSucceeded = () => import('@/edpack-web/pages/OauthSucceeded')

export const routes = [
  {
    path: '/', name: 'root', component: About, meta: { abortInLTI: true, t: 'SW_ABOUT' }
  },
  {
    path: '/about', name: 'about', component: About, meta: { abortInLTI: true, t: 'SW_ABOUT' }
  },
  {
    path: '/signin', alias: '/login', name: 'landing', component: Landing, meta: { abortInLTI: true, t: 'SW_LOGIN' }
  },
  {
    path: '/:slug?/account', name: 'account', component: UserAccount, meta: { auth: true, footer: true, t: 'SW_ACCOUNT' }
  },
  {
    path: '/:slug?/home', name: 'home', component: Home, meta: { auth: true, footer: true, t: 'SW_HOME', minimumOrgRole: 'student', section: 'app', abortInLTI: true }
  },
  {
    path: '/:slug?/admin/settings/:settingsMode?', name: 'settings', component: OrgSettings, meta: { auth: true, footer: true, t: 'SW_SETTINGS', minimumOrgRole: 'admin' }
  },
  {
    path: '/:slug?/admin/apps/new', name: 'new-app', component: NewApp, meta: { auth: true, t: 'SW_NEW_APP', minimumOrgRole: 'admin', section: 'admin' }
  },
  {
    path: '/:slug?/admin/pages/edit/:id', name: 'edit-page', component: EditApp, meta: { auth: true, footer: true, t: 'SW_PAGE', minimumOrgRole: 'admin', section: 'admin' }
  },
  {
    path: '/:slug?/admin/:mode?', name: 'admin', component: AdminPanel, meta: { auth: true, footer: true, minimumOrgRole: 'admin', t: 'SW_DASHBOARD', section: 'admin' }
  },
  {
    path: '/:slug?/search/:type?', name: 'search', component: Search, meta: { auth: true, footer: true, t: 'SW_SEARCH', section: 'app' }
  },
  {
    path: '/:slug?/plans/new', name: 'new-plan', component: NewPlan, meta: { auth: true, t: 'SW_PLANS', minimumOrgRole: 'staff', section: 'admin' }
  },
  {
    path: '/:slug?/apps/:id', name: 'app', component: AppPage, meta: { auth: true, footer: true, t: 'SW_APPS', section: 'app' }
  },
  {
    path: '/:slug?/plans/:id', name: 'plan', component: Plan, meta: { auth: true, footer: true, t: 'SW_PLANS', section: 'app' }
  },
  {
    path: '/:slug?/plans/edit/:id', name: 'edit-plan', component: EditPlan, meta: { auth: true, footer: true, t: 'SW_PLANS', section: 'app' }
  },
  {
    path: '/:slug?/profile/:id', name: 'profile', component: UserProfile, meta: { auth: true, t: 'SW_PROFILE', section: 'app' }
  },
  {
    path: '/privacy', name: 'privacy', component: StaticPrivacy, meta: { hideNav: true, t: 'SW_PRIVACY' }
  },
  {
    path: '/terms', name: 'terms', component: StaticTerms, meta: { hideNav: true, t: 'SW_TERMS' }
  },
  {
    path: '/invitation', name: 'invitation', component: Invitation, meta: { t: 'SW_INVITATION' }
  },
  {
    path: '/oauth-succeeded', name: 'oauth-succeeded', component: OauthSucceeded, meta: {}
  },
  {
    path: '/forgot', name: 'forgot', component: Forgot, meta: { t: 'SW_FORGOT' }
  },
  {
    path: '/reset', name: 'reset', component: Reset, meta: { t: 'SW_RESET' }
  },
  {
    path: '/error', name: 'error', component: Error, meta: { t: 'SW_ERROR' }
  },
  {
    path: '/:pathMatch(.*)', redirect: { name: 'error', query: { type: 'NOT_FOUND' } }
  }
]

const router = createRouter({
  history: createWebHistory(),
  base: process.env.BASE_URL,
  routes
})

export default router
