<template>
  <div/>
</template>

<script>
import dayjs from 'dayjs'
export default {
  name: 'ReloadAfterDeploy',
  data () {
    return {
      appName: this.$config.name
    }
  },

  mounted () {
    window.addEventListener('error', this.checkReloadFailure)
  },

  methods: {
    checkReloadFailure (error) {
      const errorMessage = error.message.toLowerCase()
      const isChunkLoadFailure = errorMessage.includes('chunk') || errorMessage.includes('unexpected token')

      if (isChunkLoadFailure) {
        const reloadedDate = this.$ls.get('reloaded')
        const justReloaded = reloadedDate && (dayjs(new Date()).diff(dayjs(reloadedDate), 'seconds') < 100)

        // If not already reloaded in last minute, ask for reload.
        if (!justReloaded) this.askForReload()
      }
    },
    askForReload () {
      this.$confirm(this.$t('SW_APP_JUST_UPDATED_TEXT', [this.appName]), this.$t('SW_APP_JUST_UPDATED'), {
        showCancelButton: false,
        confirmButtonText: this.$t('SW_RELOAD')
      }).then(() => {
        const now = new Date()
        this.$ls.set('reloaded', now)
        window.location.reload()
      })
    }
  }
}
</script>
