/**
 * Update document.body class for different situation, for example: contrast mode and so on
 * @param {string} className
 * @param {boolean} value
 */
const updateBodyClass = (className, value) => {
  if (value) document.body.classList.add(className)
  else document.body.classList.remove(className)
}

export default updateBodyClass
