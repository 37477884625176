import AxiosPlugin from './axios'
import isAuthorized from './is-authorized'
import router from '@/router'
import config from 'config'
import mockUser from '@/edpack-web/json/mock-user'
import store from '@/edpack-web/store'
import { setCsrfToken } from '@/edpack-web/utils/csrf-handling'
import Axios from 'axios'
import { loadLanguages } from '@/edpack-web/utils/load-languages'
import dayjs from 'dayjs'

export default {
  install (app, options) {
    const inLTI = store.state.inLTI
    const i18n = app.config.globalProperties.$i18n

    // Mock user
    if (config.mock_user) {
      mockUser.organization = mockUser.organizations[0]
      return store.dispatch('setUser', mockUser)
    }

    router.beforeEach(async (to, from) => {
      // Abort some routes in LTI mode
      if (to.meta.abortInLTI && inLTI && from.name) return false

      // Renew self after expired
      const renewSelf = store.state.user?.expireSessionDate && dayjs().isAfter(store.state.user.expireSessionDate)

      // If user in store and not expired, stop checking and just continue route
      if (!renewSelf && store.state.user) {
        if (!isAuthorized.check(store.state.user, to)) return { replace: true, name: 'error', query: { type: 'restricted_access' } }
        return true
      }

      // Check user when auth required, but also when user enters on landing
      const checkUser = to.meta.auth || to.name === 'landing' || to.name === 'root'
      if (!checkUser) return true

      // Set params to prepare getting user
      const params = {}

      // In LTI flows, add these to headers and params
      if ((to.query.ltiForwardToken || to.query.ltiAccessToken) && to.query.organization) {
        store.dispatch('setLti', true)
        AxiosPlugin.addLtiOrigin()
        params.params = { organization: to.query.organization }
        params.headers = to.query.ltiForwardToken ? { 'Lti-Forward-Token': to.query.ltiForwardToken } : { 'Lti-Access-Token': to.query.ltiAccessToken }
      }

      // Get user
      try {
        const res = await Axios.get('users/self', params)
        const user = res.data.list[0]

        // Set CSRF token
        if (!inLTI) setCsrfToken(res, app.config.globalProperties.$ls)

        // Check if LTI token is on res.data, and set header
        if (inLTI && res.data.ltiAccessToken) Axios.defaults.headers.common['Lti-Access-Token'] = res.data.ltiAccessToken

        // Check if org exists
        if (!user.organization) return { replace: true, name: 'error', query: { type: 'no_organization' } }

        // Set user if in correct org
        if (!to.params.slug || user.organization.slug === to.params.slug) return setUser(user, to)

        // Switch org or stop
        const switchToOrg = user.organizations.find(org => org.slug === to.params.slug)
        if (!switchToOrg) return { replace: true, name: 'error', query: { type: 'restricted_access' } }
        user.organization = switchToOrg

        // Update API session with other org
        await Axios.put(`session/context?organization=${switchToOrg._id}`)
        window.location = `${window.location.origin}${to.fullPath}`
        return false
      } catch (error) {
        return onError(to)
      }
    })

    function setUser (user, to) {
      // Fall back to default organization language
      if (!user.organization.languages[user.language]) user.language = user.organization.defaultLanguage

      // Set user and organization/school in store
      store.dispatch('setUser', user)
      loadLanguages(i18n.global, user.language)

      // Redirect to home or admin
      const redirectTo = { replace: true, name: 'home', params: { slug: user.organization.slug } }
      if (store.state.isAdmin) redirectTo.name = 'admin'
      if (['landing', 'root'].includes(to.name)) return redirectTo

      // Check authorization for other locations
      else if (isAuthorized.check(user, to)) return true
      else return { replace: true, name: 'error', query: { type: 'restricted_access' } }
    }

    function onError (to) {
      // When no auth was necessary, continue
      if (!to.meta.auth) return true

      // In LTI, send to error page
      if (inLTI) return { replace: true, name: 'error', query: { type: 'lti_error' } }

      return { replace: true, name: 'landing', query: { redirect: window.location.pathname } }
    }
  }
}
