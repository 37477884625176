<template>
  <div class="mini-nav" v-if="canCreate">
    <!-- Back to home -->
    <router-link to="/home" v-if="isIntegrated">
      <el-button type="text">
        <i class="icon-home"></i>
        <span class="hidden-xs hidden-sm">Home</span>
      </el-button>
    </router-link>

    <span class="link-separator" v-if="isIntegrated">|</span>

    <!-- Give app suggestion -->
    <el-button type="text" @click="toggleDialog" v-if="school.notificationEmail">
      <i class="icon-light-bulb"></i>
      <span class="hidden-xs hidden-sm">{{ $t('SW_GIVE_SUGGESTION') }}</span>
      <span class="visible-xs-inline visible-sm-inline">{{ $t('SW_SUGGESTION') }}</span>
    </el-button>

    <span class="link-separator">|</span>

    <!-- Create lesson plan -->
    <el-button type="text" @click="$router.push({name: 'new-plan', params: { slug: school.slug }})">
      <i class="icon-add"></i>
      <span class="hidden-xs hidden-sm">{{ $t('SW_MAKE_OWN_PLAN', [$term('plan', 'lc')]) }}</span>
      <span class="visible-xs-inline visible-sm-inline">{{ $term('plan') }}</span>
    </el-button>

    <span class="link-separator hidden-xs"
          v-if="canSwitch">|</span>

    <!-- Select language -->
    <app-language class="inline"></app-language>

    <span class="link-separator" v-if="canSwitch && isIntegrated">|</span>

    <router-link :to="{ name: 'profile', params: { id: currentUser._id, slug: school.slug } }" v-if="isIntegrated">
      <el-button type="text" class="footer-link" size="small">
        <thumbnail :model="currentUser" class="thumb-user footer-img thumb-24"></thumbnail>
        <span class="hidden-xs hidden-sm font-14 ml-5" v-if="$route.name === 'home'">{{currentUser.name}}</span>
      </el-button>
    </router-link>

    <!-- Suggestions dialog -->
    <el-dialog :title="$t('SW_GIVE_SUGGESTION')" custom-class="text-left" append-to-body
               v-model="dialogSuggestion">
      <suggestion-form :close-dialog="toggleDialog" v-if="dialogSuggestion"></suggestion-form>
    </el-dialog>
  </div>
</template>

<script>
import SuggestionForm from '@/components/SuggestionForm'

export default {
  name: 'MiniNav',
  components: { SuggestionForm },

  data () {
    return {
      dialogSuggestion: false,
      lang: this.$store.state.user.language,
      school: this.$store.state.school,
      canCreate: this.$store.state.school.enablePlans && this.$store.state.school.enablePlansByStaff && this.$store.state.user.role !== 'student',
      canSwitch: this.$store.state.school.languages.en && this.$store.state.school.languages.nl,
      isIntegrated: (window.self !== window.top)
    }
  },

  computed: {
    currentUser () {
      return this.$store.state.user
    }
  },

  methods: {
    toggleDialog () {
      this.dialogSuggestion = !this.dialogSuggestion
    }
  }
}
</script>

<style lang="scss">
  @use 'style';
</style>
