<template>
  <div class="about-content">
    <section class="static-section theme-section first-section">
      <div class="text-center">

        <!-- Header buttons -->
        <div class="about-header">
          <!-- Contact us -->
          <el-button type="text" class="mr-5" @click.prevent="openContact">
            <strong class="text-white">{{ $t('SW_CONTACT_US') }}</strong>
          </el-button>

          <!-- Language -->
          <span class="ml-5 mr-5 hidden-xs">|</span>
          <app-language class="mr-5"/>

          <!-- Log in -->
          <el-button class="ml-10" type="primary" @click="$router.push({name: 'landing'})">
            <strong>{{ $t('SW_LOGIN') }}</strong>
          </el-button>
        </div>

        <!-- Logo -->
        <router-link to="/" class="inline about-logo mt-30">
          <span class="horizontal-logo"/>
        </router-link>

        <!-- Header -->
        <p class="font-32 mb-20 bold about-slogan">
          {{ $t('SW_SLOGAN_SHORT') }}
        </p>
        <p class="font-20 mb-30 about-slogan">{{ $t('SW_WELCOME_ABOUT') }}</p>

        <!-- Carousel -->
        <el-carousel indicator-position="none" :arrow="carouselArrow" :type="carouselType" :interval="5000" :key="carouselKey"
                     class="carousel invisible hide" v-observe-visibility="{callback: visibilityChanged, throttle: 200, once: true}">
          <el-carousel-item v-for="item in 3" :key="item"/>
        </el-carousel>
      </div>
    </section>

    <!-- Why text -->
    <section class="static-section grey-section hidden-xs">
      <article>
        <h2 class="text-center mb-30">{{ $t('SW_WHY_HEADER', [appName]) }}</h2>
        <p class=" mb-10">{{ $t('SW_WHY_TEXT') }}</p>
        <p class="mb-50">{{ $t('SW_WHY_TEXT2') }}</p>
      </article>
    </section>

    <!-- What makes it unique text -->
    <section class="static-section white-section">
      <h2 class="text-center mb-30">{{ $t('SW_UNIQUE_HEADER', [appName]) }}</h2>

      <article v-for="({icon, color}, index) in uniqTextIcons" class="uniq-text invisible clearfix" :key="icon" v-observe-visibility="{callback: visibilityChanged, throttle: 200, once: true}">
        <div class="pull-left large-icon">
          <i :class="icon" :style="{color}"></i>
        </div>
        <div class="no-overflow">
          <h3 class="mb-10">{{ $t(`SW_UNIQUE_HEADER_${index + 1}`) }}</h3>
          <p class=" mb-20">{{ $t(`SW_UNIQUE_TEXT_${index + 1}`) }}</p>
        </div>
      </article>
    </section>

    <!-- How to use it text -->
    <section class="static-section grey-section">
      <article class="how-to text-center">
        <h2 class="mb-30">{{ $t('SW_HOW_HEADER') }}</h2>
        <p class="mb-10">{{ $t('SW_HOW_TEXT') }}</p>

        <el-button size="large" class="mt-30 mb-30" type="primary" @click.prevent="openContact">
          <i class="icon-email"></i>
          {{ $t('SW_CONTACT_US') }}
        </el-button>
      </article>
    </section>

    <!-- Request access and contact footer -->
    <div class="static-section dark-section">
      <article class="text-center mb-20">
        <h2 class="text-center mb-20">{{ $t('SW_ACCESS_HEADER') }}</h2>
        <p class="font-16 mb-20">{{ $t('SW_ACCESS_TEXT') }}</p>

        <!-- Request demo access -->
        <request-form></request-form>

        <!-- Footer links -->
        <footer-links class="mt-30 mb-20" :signin="true" ref="footerLinks"></footer-links>

        <!-- Credits and terms -->
        <footer-credits></footer-credits>
      </article>
    </div>
  </div>
</template>

<script>
import FooterLinks from '@/edpack-web/components/FooterLinks'
import RequestForm from '@/edpack-web/components/RequestForm'
import FooterCredits from '@/edpack-web/components/FooterCredits'

import throttle from 'lodash/throttle'
import { addListener, removeListener } from 'resize-detector'
export default {
  name: 'About',
  components: { RequestForm, FooterCredits, FooterLinks },

  data () {
    return {
      lang: this.$store.state.lang,
      school: this.$store.state.school,
      appName: this.$config.name,
      email: '',
      carouselType: window.innerWidth > 1200 ? 'card' : 'default',
      carouselKey: 1,
      carouselArrow: window.innerWidth > 1200 ? 'never' : 'hover',
      counts: false,
      businessUrl: this.$config.business.url,
      businessName: this.$config.business.shortName,
      productName: this.$config.name,
      countAnimationDelay: 200,
      uniqTextIcons: [
        { icon: 'icon-search', color: '#156288' },
        { icon: 'icon-time', color: '#d00a3d' },
        { icon: 'icon-bar-graph', color: 'darkorange' },
        { icon: 'icon-chat', color: '#4caf50' }
      ]
    }
  },

  mounted () {
    if (this.counts) this.getCounts()
  },

  beforeMount () {
    addListener(document.body, throttle(this.onResize, 150))
  },

  beforeUnmount () {
    removeListener(document.body, throttle(this.onResize, 150))
  },

  methods: {
    openContact () { this.$store.dispatch('setContactForm', true) },
    visibilityChanged (isVisible, entry, count) {
      const { target } = entry

      if (isVisible) {
        target.classList.remove('invisible')
        target.classList.add('visible')
      } else {
        target.classList.add('invisible')
      }

      if (count && isVisible) {
        setTimeout(() => {
          this.$refs.counts[count.index].start()
        }, this.countAnimationDelay)
      }
    },
    getCounts () {
      this.$http.get('public/counts')
        .then((res) => {
          this.counts = res.data.list[0]
        })
        .catch((err) => { this.$notifyBug(err) })
    },
    onResize () {
      this.carouselArrow = window.innerWidth >= 992 ? 'never' : 'hover'
      this.carouselType = window.innerWidth >= 992 ? 'card' : 'default'
      this.carouselKey++
    }
  }
}
</script>

<style lang="scss">
  @use 'style';
</style>
