<template>
  <div class="apps-list">
    <!-- App tiles -->
    <app-tile v-for="page in apps" :type="type" :app="page" :key="page._id"></app-tile>

    <!-- Show more -->
    <div v-if="limit && total > limit" class="list-more">
      <el-button size="small" @click="showMore">
        <strong>{{ $t('SW_VIEW_ALL') }}</strong>
        &nbsp;<span class="text-muted"> +{{ total - limit }}</span>
      </el-button>
    </div>

    <!-- No results -->
    <span class="text-muted" v-if="status === 'none'">{{ $t('SW_RESULTS_NONE') }}</span>

    <!-- Loading -->
    <spinner v-if="status === 'loading'"></spinner>
  </div>
</template>

<script>
import AppTile from '@/components/AppTile'

export default {
  name: 'AppsList',
  props: ['type', 'appPage', 'pages', 'user', 'startLimit'],
  components: { AppTile },

  data () {
    return {
      status: 'done',
      lastElement: false,
      apps: [],
      total: false,
      limit: this.startLimit || 50,
      school: this.$store.state.school,
      params: false,
      currentUser: this.$store.state.user,
      lang: this.$store.state.user.language
    }
  },

  mounted () {
    // If not in search, query apps
    if (!this.pages) return this.getApps(this.limit)

    this.apps = this.pages
    this.total = this.pages.length
  },

  methods: {
    getApps (limit) {
      if (status === 'loading') return
      this.status = 'loading'

      this.params = {
        amount: limit,
        status: 'published',
        organization: this.school._id
      }

      if ((this.type === 'comparable') && this.appPage) {
        if (this.appPage && this.appPage.categories && this.appPage.categories.length) {
          this.params.categories = []
          for (const category of this.appPage.categories) {
            this.params.categories.push(category._id)
            for (const filter of category.filters) {
              this.params.categories.push(filter._id)
            }
          }

          this.params.exclude = [this.appPage._id]
        } else if (this.appPage.labels && this.appPage.labels[this.lang] && this.appPage.labels[this.lang].length) {
          this.params.labels = this.appPage.labels[this.lang]
          this.params.exclude = [this.appPage._id]
        }
      }

      this.$http.get('pages', { params: this.params })
        .then((res) => {
          this.apps = res.data.list
          if (this.limit) this.apps = this.apps.slice(0, this.limit)
          this.total = res.data.total
          this.status = res.data.done ? 'done' : 'resolved'
          if (!this.total) this.status = 'none'
        })
        .catch((e) => {
          this.$notifyBug(e)
          this.status = 'error'
        })
    },
    showMore () {
      const newLimit = this.limit + 50
      this.limit = newLimit
      this.getApps(newLimit)
    }
  }
}
</script>

<style lang="scss">
  @use 'style';
</style>
