import { defineComponent, h as createElement } from 'vue'

function breakpointValue (mixed, width) {
  const valueAsNum = parseInt(mixed, 10)

  if (valueAsNum > -1) {
    return mixed
  }
  if (typeof mixed !== 'object') {
    return 0
  }

  let matchedBreakpoint = Infinity
  let matchedValue = mixed.default || 0

  const entries = Object.entries(mixed)

  entries.forEach(([key, value]) => {
    const breakpoint = parseInt(key, 10)
    const breakpointValRaw = value
    const breakpointVal = parseInt(breakpointValRaw, 10)

    if (Number.isNaN(breakpoint) || Number.isNaN(breakpointVal)) return

    const isNewBreakpoint = width <= breakpoint && breakpoint < matchedBreakpoint

    if (isNewBreakpoint) {
      matchedBreakpoint = breakpoint
      matchedValue = breakpointValRaw
    }
  })

  return matchedValue
}

export default defineComponent({
  props: {
    tag: { type: [String], default: 'div' },
    cols: { type: [Object, Number, String], default: 2 },
    gutter: { type: [Object, Number, String], default: 0 },
    css: { type: [Boolean], default: true },
    columnTag: { type: [String], default: 'div' },
    columnClass: { type: [String, Array, Object], default: () => [] },
    columnAttr: { type: [Object], default: () => {} }
  },

  data () {
    return {
      wrapperWidth: 0,
      displayColumns: 2,
      displayGutter: 0
    }
  },

  activated () {
    this.$nextTick(this.render)
  },

  mounted () {
    window.addEventListener('resize', this.render)
    this.$nextTick(this.render)
  },

  updated () {
    this.$nextTick(this.render)
  },

  unmounted () {
    window.removeEventListener('resize', this.render)
  },

  methods: {
    // Recalculate how many columns to display based on window width
    // and the value of the passed `:cols=` prop
    render () {
      const windowWidth = window?.innerWidth || Infinity
      const { wrapperWidth } = this

      if (wrapperWidth !== windowWidth) {
        this.wrapperWidth = windowWidth

        this.calculateColumnCount(this.wrapperWidth)
        this.calculateGutterSize(this.wrapperWidth)
      }
    },

    calculateGutterSize (width) {
      this.displayGutter = breakpointValue(this.gutter, width)
    },

    calculateColumnCount (width) {
      let columnLength = breakpointValue(this.cols, width) || 0

      // Make sure we can return a valid value
      columnLength = Math.max(1, Number(columnLength))

      this.displayColumns = columnLength
    },

    getColumnsWithChildItems () {
      const columns = []
      const wrapper = this.$slots.default?.()[0]
      let children = wrapper?.children || []

      if (wrapper && wrapper.type.name === 'TransitionGroup') {
        console.warn(
          'This component does not support <transition-group />. Using child elements.'
        )

        children = wrapper.children?.default()[0].children || []
      }

      if (children.length === 0) return []

      for (let i = 0, visibleItemI = 0; i < children.length; i++, visibleItemI++) {
        if (!children[i].type) visibleItemI--

        const columnIndex = visibleItemI % this.displayColumns

        if (!columns[columnIndex]) {
          columns[columnIndex] = []
        }

        columns[columnIndex].push(children[i])
      }

      return columns
    }
  },

  render () {
    const { displayGutter } = this
    const columnsContainingChildren = this.getColumnsWithChildItems()

    const isGutterSizeUnitless = parseInt(displayGutter.toString(), 10) === displayGutter * 1
    const gutterSize = isGutterSizeUnitless ? `${displayGutter}px` : displayGutter

    const containerConfig = {
      style: {
        display: ['-webkit-box', '-ms-flexbox', 'flex'],
        marginLeft: `-${gutterSize}`
      }
    }

    const columnStyle = {
      boxSizing: 'border-box',
      backgroundClip: 'padding-box',
      width: `${100 / this.displayColumns}%`,
      border: '0px solid transparent',
      borderLeftWidth: gutterSize
    }

    const columns = columnsContainingChildren.map((children, index) => {
      const config = {
        key: `${index}-${columnsContainingChildren.length}`,
        style: this.css ? columnStyle : null,
        class: this.columnClass,
        attrs: this.columnAttr
      }

      // Create column element and inject the children
      return createElement(this.columnTag, config, children)
    })

    // Return wrapper with columns
    return createElement(this.tag, this.css && containerConfig, columns)
  }
})
