<template>
  <div>
    <app-page-header v-if="status === 'done'" :appPage="appPage"/>
    <div class="page-body">
      <el-row :gutter="40" v-if="status === 'done'">
        <el-col :sm="24" :md="14" class="mb-30">
          <!-- Concept -->
          <div v-if="appPage.status === 'draft' && $store.state.isAdmin">
            <el-alert class="alert-large mb-30" :title="$t('SW_DRAFT')" :closable="false" type="info" :description="$t('SW_DRAFT_TEXT', [school.shortName[lang]])" show-icon/>
          </div>

          <!-- Nothing to say about this -->
          <div v-if="appPage.status === 'none' && appPage.adviceLabel !== 'dismissed'">
            <el-alert class="alert-large mb-30" :title="$t('SW_INACTIVE')" :closable="false" type="info" :description="$t('SW_INACTIVE_TEXT', [school.shortName[lang]])" show-icon/>
          </div>

          <!-- Discouraged -->
          <div v-if="appPage.adviceLabel === 'dismissed'">
            <el-alert class="alert-large mb-30" :title="$t('SW_NOT_RECOMMENDED')" :closable="false" type="error" :description="$t('SW_NOT_RECOMMENDED_TEXT', [school.shortName[lang]])" show-icon/>
          </div>

          <!-- Warning -->
          <div v-if="appPage.adviceLabel === 'warning'">
            <el-alert class="alert-large mb-30" :title="$t('SW_ADVICE_WARNING')" :closable="false" type="warning" :description="$t('SW_BE_CAREFUL_TEXT', [school.shortName[lang], this.$term('students', 'lc')])" show-icon/>
          </div>

          <!-- Published -->
          <div v-if="appPage.status === 'published' || ( appPage.status === 'draft' && isAdmin )">
            <!-- Limit height -->
            <div class="advice-text" :class="{'limited': appPage.relHeight[lang] > 4000}">

              <!-- Advice text -->
              <div v-if="appPage.advice[lang] || appPage.advice[altLang]" class="redactor-text redactor-styles mb-30" v-html="renderAdviceHtml"></div>
              <div v-else-if="appPage.status === 'published'" class="text-muted">{{ $t('SW_NO_DESCRIPTION_YET') }}
              </div>

              <!-- Attachments -->
              <h3 class="mb-10">{{ $t('SW_ATTACHMENTS') }}</h3>
              <section class="clearfix attachments">
                <attachment-tile v-for="attachment in appPage.attachments" :key="attachment._id" :attachment="attachment"/>
              </section>
              <span v-if="!appPage.attachments.length" class="text-muted">{{ $t('SW_NO_ATTACHMENTS') }}</span>
            </div>

            <!-- Show more info -->
            <div v-if="appPage.relHeight[lang] > 4000" class="mt-10 text-center visible-xs visible-sm">
              <el-button @click="appPage.relHeight[lang] = false">
                <strong>{{ $t('SW_SHOW_EVERYTHING') }}</strong>
              </el-button>
            </div>
          </div>
        </el-col>

        <el-col :sm="24" :md="10">
          <div v-if="appPage.status !== 'dismissed'" class="mb-30">
            <h3 class="mb-10">{{ $t('SW_EXPERIENCES') }}</h3>

            <!-- Experiences -->
            <experiences :appPage="appPage" :startLimit="3"/>
          </div>

          <!-- Similar apps -->
          <h3 class="mb-20 hidden-xs">{{ $t('SW_COMPARABLE') }}</h3>
          <apps-list type="comparable" :appPage="appPage" :startLimit="3" class="mb-30 comparable-tiles hidden-xs"/>
        </el-col>
      </el-row>

      <!-- Related lesson plans -->
      <div class="mb-10" v-if="school.enablePlans && status === 'done'">
        <h3 class="mb-20">{{ $term('plans') }}</h3>
        <plans-list :appPage="appPage"/>
      </div>
    </div>

    <!-- Loading -->
    <spinner v-if="status === 'loading'" class="mt-30"/>

    <!-- Error -->
    <div v-if="status === 'error'" class="mt-30 text-muted text-center">{{ $t('SW_ERROR_LOADING') }}</div>
  </div>
</template>

<script>
import AppsList from '@/components/AppsList'
import AppPageHeader from '@/components/AppPageHeader'
import AttachmentTile from '@/components/AttachmentTile'
import scrollTop from 'simple-scrolltop'
import Experiences from '@/components/Experiences'
import PlansList from '@/components/PlansList'
import scanHtmlForSecureUrls from '@/edpack-web/utils/filestack/scan-html'

export default {
  name: 'AppPage',
  components: { AppsList, AttachmentTile, AppPageHeader, Experiences, PlansList },

  data () {
    return {
      currentUser: this.$store.state.user,
      lang: this.$store.state.user.language,
      isAdmin: this.$store.state.isAdmin,
      altLang: this.$store.state.user.language === 'en' ? 'nl' : 'en',
      school: this.$store.state.school,
      status: false,
      appPage: {}
    }
  },

  mounted () {
    this.getApp(this.$route.params.id)
  },

  beforeRouteUpdate (to, from, next) {
    this.getApp(to.params.id)
    scrollTop(0)
    next()
  },

  computed: {
    renderAdviceHtml () {
      return scanHtmlForSecureUrls(this.appPage.advice[this.lang] || this.appPage.advice[this.altLang], this.currentUser)
    }
  },

  methods: {
    getApp (id) {
      if (this.status === 'loading') return

      this.status = 'loading'

      this.$http.get(`pages/${id}`)
        .then(res => {
          this.appPage = res.data.list[0]
          this.status = 'done'
        })
        .catch((e) => {
          this.$notifyBug(e)
          this.status = 'error'
        })
    }
  }
}
</script>

<style lang="scss">
  @use 'style';
</style>
