<template>
  <div class="text-center">
    <div class="sk-spinner">
      <div class="sk-bounce1"/>
      <div class="sk-bounce2"/>
      <div class="sk-bounce3"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Spinner'
}
</script>
