<template>
  <div class="header-logo-wrapper">
    <div class="animate-logo header-logo"/>
  </div>
</template>

<script>
export default {
  name: 'HeaderLogo'
}
</script>

<style lang="scss">
  @use 'style';
</style>
