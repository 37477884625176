import config from 'config/default'

export default function updateTitle (location, $t, $tc) {
  let newTitle
  let name = false
  if (location?.meta?.t) name = $t(location.meta.t)
  else if (location?.meta?.tc) name = $tc(location.meta.tc, 1)

  newTitle = name ? name + ' - ' + config.name : config.name

  if (config.releaseStage !== 'production') newTitle = newTitle + ' | ' + config.releaseStage.toUpperCase()

  document.title = newTitle
}
