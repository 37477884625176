import assign from 'object-assign'
import development from './development'
import staging from './staging'
import production from './production'
import _default from './default'

const configVersions = {
  development,
  staging,
  production
}

const config = assign(_default, configVersions[process.env.VUE_APP_MODE || process.env.NODE_ENV])

export default config
