import router from '@/router'
import store from '@/edpack-web/store'

export default {
  install (app, options) {
    const i18n = app.config.globalProperties.$i18n
    const confirm = app.config.globalProperties.$confirm

    router.beforeEach((to, from, next) => {
      // If no changes to a form, continue
      if (!store.state.unsavedChanges) return next()

      // Else, ask for confirmation to discard changes
      confirm(i18n.global.t('SW_DISCARD_CHANGED_TEXT'), i18n.global.t('SW_DISCARD_CHANGED'), {
        confirmButtonText: i18n.global.t('SW_DISCARD_AND_CONTINUE'),
        cancelButtonText: i18n.global.t('SW_CANCEL')
      })
        .then(() => {
          store.dispatch('setUnsavedChanges', false)
          next()
        })
        .catch(() => { return next(new Error('Go back in time')) })
    })
  }
}
