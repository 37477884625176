<template>
  <div>
    <!-- left side -->
    <section class="letter u hidden-xs"><i class="icon-box_view"/></section>
    <section class="letter a hidden-xs"><i class="icon-user-tie"/></section>
    <section class="letter second-p hidden-xs"><i class="icon-pen"/></section>
    <section class="letter left-d hidden-xs"><i class="icon-justice"/></section>
    <section class="letter left-e hidden-xs"><i class="icon-box_view"/></section>
    <section class="letter left-p hidden-xs"><i class="icon-hammer2"/></section>

    <!-- right side -->
    <section class="letter e hidden-xs"><i class="icon-justice"/></section>
    <section class="letter d hidden-xs"><i class="icon-box_view"/></section>
    <section class="letter first-p hidden-xs"><i class="icon-hammer2"/></section>
    <section class="letter right-a hidden-xs"><i class="icon-pen"/></section>
    <section class="letter right-p hidden-xs"><i class="icon-user-tie"/></section>
    <section class="letter right-u hidden-xs"><i class="icon-box_view"/></section>
  </div>
</template>

<script>
export default {
  name: 'AnimatedLanding'
}
</script>

<style lang="scss">
  @use 'style';
</style>
