export default {
  releaseStage: 'staging',
  web_url: 'https://staging.mijnjuridischadvies.nl',
  api_url: 'https://mja-staging.herokuapp.com/api/v1',
  debug: true,

  // Ackee
  ackee: {
    enabledTracking: false,
    domain_id: 'bc2e64df-5ee0-4edf-a496-3530f7cd5b78',
    api_url: 'https://stats-microservice-staging.herokuapp.com/',
    token: '121a5fe9-ea2e-43a4-90a7-83980025fc8a',
    options: {
      ignoreLocalhost: false,
      ignoreOwnVisits: false,
      detailed: true
    }
  }
}
