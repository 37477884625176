<template>
  <el-form label-position="top">
    <p class="mb-20">
      {{ $t('SW_GIVE_SUGGESTION_TEXT', [school.shortName[lang]]) }}
    </p>
    <el-form-item>
      <el-input type="textarea"
                :autosize="{ minRows: 3, maxRows: 8}"
                :placeholder="$t('SW_EMAIL_MESSAGE_PLACEHOLDER')"
                v-model="emailForm.message">
      </el-input>
    </el-form-item>

    <el-form-item>
      <el-button type="primary" @click="sendEmail" :loading="submitting">
        <i v-if="!submitting" class="icon-send"></i>
        {{ $t('SW_SEND_EMAIL') }}
      </el-button>
      <el-button class="ml-10" type="text" @click="closeDialog">{{ $t('SW_CANCEL') }}</el-button>
    </el-form-item>
  </el-form>
</template>

<script>

export default {
  name: 'SuggestionForm',
  props: ['closeDialog'],
  components: {},

  data () {
    return {
      submitting: false,
      user: this.$store.state.user,
      school: this.$store.state.school,
      lang: this.$store.state.user.language,
      emailForm: { message: '' }
    }
  },

  methods: {
    sendEmail () {
      // Basic validation
      this.submitting = true

      if (!this.emailForm.message) {
        return this.$message({ message: this.$t('SW_FILL_REQUIRED'), type: 'error' })
      }

      const message = this.emailForm.message
      const subject = 'New suggestion received on Eduapp'

      this.$http.post('messages/suggestion', { message, subject })
        .then(res => {
          this.submitting = false
          this.emailForm = { message: '' }
          this.$message({ message: this.$t('SW_SUGGESTION_SENT'), type: 'success' })
          this.closeDialog()
        })
        .catch((e) => {
          this.$message({ message: this.$t('SW_SUGGESTION_NOT_SENT'), type: 'error' })
          this.$notifyBug(e)
          this.submitting = false
        })
    }
  }
}
</script>

<style lang="scss">
  @use 'style';
</style>
