<template>
  <div>
    <plan-header v-if="status === 'done'" :plan="plan"></plan-header>
    <div class="page-body" v-if="status === 'done'">
      <el-row :gutter="40">
        <el-col :sm="24" :md="14" class="mb-30">
          <!-- Limit height -->
          <div class="advice-text" :class="{'limited': plan.relHeight[lang] > 400000}">

            <!-- Description text -->
            <div v-if="plan.description[lang]" class="mb-30">
              <h3>{{ $term('plan') }}</h3>
              <div class="redactor-text redactor-styles" v-html="renderHtml(plan.description[lang])"></div>
            </div>

            <!-- Attachments -->
            <h3 class="mb-10">{{ $t('SW_ATTACHMENTS') }}</h3>
            <section class="clearfix attachments">
              <attachment-tile v-for="attachment in plan.attachments" :key="attachment._id" :attachment="attachment"></attachment-tile>
            </section>
            <span v-if="!plan.attachments.length"
                  class="text-muted">{{ $t('SW_NO_ATTACHMENTS') }}</span>
          </div>

          <!-- Show more info -->
          <div v-if="plan.relHeight[lang] > 400000" class="mt-10 text-center visible-xs visible-sm">
            <el-button @click="plan.relHeight[lang] = false">
              <strong>{{ $t('SW_SHOW_EVERYTHING') }}</strong>
            </el-button>
          </div>
        </el-col>

        <el-col :sm="24" :md="10">
          <!-- Relevant apps -->
          <div class="mb-30" v-if="plan.pages.length">
            <h3 class="mb-10">{{ $t('SW_RELEVANT_APPS') }}</h3>
            <apps-list :pages="plan.pages"></apps-list>
          </div>

          <!-- Comments -->
          <div class="mb-30">
            <h3 class="mb-10">{{ $t('SW_COMMENTS') }}</h3>
            <comments :plan="plan" :startLimit="3"></comments>
          </div>
        </el-col>
      </el-row>
    </div>

    <!-- Loading -->
    <spinner v-if="status === 'loading'" class="mt-30"></spinner>

    <!-- Error -->
    <div v-if="status === 'error'" class="mt-30 text-muted text-center">
      {{ $t('SW_ERROR_LOADING') }}
    </div>
  </div>
</template>

<script>
import scrollTop from 'simple-scrolltop'
import Comments from '@/components/Comments'
import AppsList from '@/components/AppsList'
import PlanHeader from '@/components/PlanHeader'
import AttachmentTile from '@/components/AttachmentTile'
import scanHtmlForSecureUrls from '@/edpack-web/utils/filestack/scan-html'
export default {
  name: 'Plan',
  components: { PlanHeader, AttachmentTile, Comments, AppsList },

  data () {
    return {
      currentUser: this.$store.state.user,
      lang: this.$store.state.user.language,
      school: this.$store.state.school,
      status: false,
      plan: {}
    }
  },

  created () {
    this.getPlan(this.$route.params.id)
  },

  beforeRouteUpdate (to, from, next) {
    this.getPlan(to.params.id)
    scrollTop(0)
    next()
  },

  methods: {
    getPlan (id) {
      if (this.status === 'loading') return

      this.status = 'loading'

      this.$http.get(`plans/${id}`)
        .then(res => {
          this.plan = res.data.list[0]
          this.status = 'done'
        })
        .catch((e) => {
          this.$notifyBug(e)
          this.status = 'error'
        })
    },
    renderHtml (content) {
      return scanHtmlForSecureUrls(content, this.currentUser)
    }
  }
}
</script>

<style lang="scss">
  @use 'style';
</style>
