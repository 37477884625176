<template>
  <el-form class="mb-30" id="requestForm" :inline="true" ref="form" name="request" netlify-honeypot="bt-field2" action="thanks-request" netlify>
    <!-- Name of form (hidden) -->
    <input type="hidden" name="form-name" value="request">

    <el-form-item class="email-item">
      <!-- Email -->
      <label for="request-email" class="visually-hidden"/>
      <el-input class="email-input" id="request-email" type="email" name="email" size="large" v-model="email" :placeholder="$t('SW_YOUR_EMAIL')" :required="true">
        <template #prefix>
          <div class="flex align-center">
            <i class="icon-email"/>
          </div>
        </template>

        <!--Submit request -->
        <template #append>
          <el-button class="submit-button" native-type="submit" type="primary" :loading="submitting" @click.prevent="submitRequest">
            <i class="icon-send"/>
            <span class="hidden-xs">{{ $t('SW_REQUEST_ACCESS') }}</span>
          </el-button>
        </template>
      </el-input>
    </el-form-item>

    <!-- Botfield -->
    <el-form-item prop="name" class="hidden">
      <el-input name="bt-field2"/>
    </el-form-item>

  </el-form>
</template>

<script>
export default {
  name: 'RequestForm',
  data () {
    return {
      email: '',
      submitting: false
    }
  },
  methods: {
    submitRequest () {
      // Basic validation
      if (this.submitting) return
      if (this.email.indexOf('@') < 0) return this.$message({ message: this.$t('SW_INCORRECT_EMAIL'), type: 'error' })

      this.submitting = true

      const form = document.forms.namedItem('request')
      const formAction = form.getAttribute('action')
      const formData = new FormData(form)

      // Send to Netlify
      const netlify = this.$http.create({
        baseURL: `${this.$config.web_url}/`,
        timeout: 10000,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
      })

      netlify.post(formAction, formData)
        .then(() => {
          this.$message({ message: this.$t('SW_THANKS_REPLY_SOON'), type: 'success' })
          this.email = ''
        })
        .catch(e => { this.$notifyBug(e) })
        .finally(() => { this.submitting = false })
    }
  }
}
</script>

<style lang="scss">
  @use 'style';
</style>
