<template>
  <div id="app" :key="resetKey">
    <vue-announcer/>
    <vue-progress-bar/>

    <!-- Only activate when currentUser is set -->
    <div v-if="currentUser">
      <ackee-tracker v-if="releaseStage !== 'development'" :user="currentUser"/>
      <app-sidebar v-if="navAvailable" :closeSidebar="closeSidebar" :active="sidebarOpened"/>
      <app-header v-if="navAvailable" :user="currentUser" :openSidebar="openSidebar"/>
      <app-lti-header v-if="showLtiHeader" :user="currentUser"/>
    </div>

    <!-- Main content -->
    <main :class="{ 'page-offset': pageOffset, 'nav-offset': navAvailable || showLtiHeader, 'page-lti': inLTI && !showLtiHeader, 'shrink': drawerType }">
      <!-- Downtime alert -->
      <downtime-alert />

      <!-- Cookie alert -->
      <el-alert type="warning" class="no-border-radius text-center" effect="dark" show-icon :title="$t('SW_COOKIE_WARNING', [appName])" v-if="cookieWarning"/>

      <!-- Router view -->
      <router-view/>
    </main>

    <section>
      <router-view name="rightDrawer"/>
    </section>

    <!-- Navigation stuff -->
    <app-footer v-if="showFooter"/>
    <div class="dimmer" v-if="sidebarOpened" :class="{'active': obfuscatorActive}" @click="closeSidebar"/>
    <freshchat v-if="loadFreshchat"/>
    <reload-after-deploy/>

    <!-- Welcome dialog -->
    <!--    <focus-trap>-->
    <el-dialog v-model="dialogWelcome" custom-class="welcome-dialog" append-to-body>
      <SuspenseSpinner>
        <welcome-dialog @closeDialog="toggleDialog"/>
      </SuspenseSpinner>
    </el-dialog>
    <!--    </focus-trap>-->

    <!-- Contact dialog -->
    <!--    <focus-trap>-->
    <el-dialog :model-value="contactFormOpened" @close="() => setContactForm(false)" :title="$t('SW_CONTACT_US')" custom-class="medium-dialog" append-to-body>
      <SuspenseSpinner>
        <contact-form v-if="contactFormOpened" :closeDialog="() => setContactForm(false)"/>
      </SuspenseSpinner>
    </el-dialog>
    <!--    </focus-trap>-->

  </div>
</template>

<script>
import Bugsnag from '@bugsnag/js'
import { mapActions, mapState } from 'vuex'
import updateTitle from '@/edpack-web/utils/update-title'
import AppHeader from '@/edpack-web/components/AppHeader'
import AppFooter from '@/edpack-web/components/AppFooter'
import AppSidebar from '@/edpack-web/components/AppSidebar'
import AppLtiHeader from '@/edpack-web/components/AppLtiHeader'
import DowntimeAlert from '@/edpack-web/components/DowntimeAlert'
import ReloadAfterDeploy from '@/edpack-web/components/ReloadAfterDeploy'

import { defineAsyncComponent } from 'vue'
import updateBodyClass from '@/edpack-web/utils/update-body-class'
const Freshchat = defineAsyncComponent(() => import('@/edpack-web/components/Freshchat'))
const WelcomeDialog = defineAsyncComponent(() => import('@/edpack-web/components/WelcomeDialog'))
const ContactForm = defineAsyncComponent(() => import('@/edpack-web/components/ContactForm'))
const AckeeTracker = defineAsyncComponent(() => import('@/edpack-web/components/AckeeTracker'))

export default {
  name: 'App',
  components: { ContactForm, WelcomeDialog, AppHeader, AppLtiHeader, AppSidebar, AppFooter, ReloadAfterDeploy, DowntimeAlert, Freshchat, AckeeTracker },

  data () {
    return {
      inLTI: this.$store.state.inLTI,
      lang: this.$store.state.lang,
      dialogWelcome: false,
      resetKey: 1,
      disableWelcome: false,
      appName: this.$config.name,
      releaseStage: this.$config.releaseStage,
      cookieWarning: this.$config.cookieWarning && !navigator.cookieEnabled
    }
  },

  created () {
    this.$router.beforeEach((to, from, next) => {
      this.$Progress.start()
      next()
    })
    this.$router.afterEach(() => {
      this.$Progress.finish()
    })
  },

  mounted () {
    // Handle resize events
    window.addEventListener('resize', this.handleResize)
  },

  computed: {
    ...mapState({
      language: state => state.user.language,
      currentUser: state => state.user,
      school: state => state.school,
      showFooter: state => state.route.meta.footer,
      sidebarOpened: state => state.sidebarOpened,
      contactFormOpened: state => state.contactFormOpened,
      obfuscatorActive: state => state.obfuscatorActive,
      pageOffset: state => state.route.meta.auth,
      showLtiHeader: state => state.user && state.inLTI && (window.self === window.top),
      loadFreshchat: (state) => {
        return state.user && state.school && state.school.enableFreshChat && state.user.role !== 'student'
      },
      navAvailable: (state) => {
        // Hide when not authenticated
        if (!state.route.meta.auth || state.route.meta.hideNav) return false
        // Hide when in LTI iframe
        return !state.inLTI
      }
    }),
    drawerType () { return this.$store.state.drawerType }
  },

  watch: {
    currentUser (user) {
      // Show welcome dialog to new user
      if (user && !user.checks.welcome && !this.disableWelcome) this.toggleDialog()
      // Update bugsnag session with user data
      if (user) this.updateBugsnag(user)
      if (user?.contrastMode) updateBodyClass('contrast-mode', true)
    },
    language (language) {
      if (this.lang === language) return
      this.lang = language

      // Only reset the interface when not in a form with unsaved changes
      if (!this.$store.state.unsavedChanges) this.resetKey++
    },
    school (school) {
      if (school && school.slug) return document.body.classList.add(school.slug + '-styling')
    },
    // control page title in browser tab
    $route: {
      immediate: true,
      handler (value) { updateTitle(value, this.$t, this.$tc) }
    }
  },

  methods: {
    updateBugsnag (user) {
      if (this.releaseStage === 'development') return
      Bugsnag.setUser(user._id, user.email)
      Bugsnag.addMetadata('school', { name: user.organization.slug, id: user.organization._id })
    },
    toggleDialog () {
      this.dialogWelcome = !this.dialogWelcome
      this.disableWelcome = true
    },
    ...mapActions(['handleResize', 'openSidebar', 'closeSidebar', 'setContactForm'])
  }
}
</script>

<style lang="scss">
@use 'style';
</style>
