import localeEn from '@/en.json'
import localeNl from '@/nl.json'
import config from 'config'
import { createI18n } from 'vue-i18n'

let localeEnBasic
let localeNlBasic

try {
  localeEnBasic = require('@/edpack-web/en.json')
  localeNlBasic = require('@/edpack-web/nl.json')
} catch (ex) {
  localeEnBasic = {}
  localeNlBasic = {}
}

export default {
  install (app, options) {
    const i18n = createI18n({
      locale: config.defaultLanguage,
      fallbackLocale: config.defaultLanguage,
      globalInjection: true,
      messages: {
        en: { ...localeEnBasic, ...localeEn },
        nl: { ...localeNlBasic, ...localeNl }
      }
    })

    app.use(i18n)
    app.config.globalProperties.$i18n = i18n
  }
}
