<template>
  <div class="thumbnail" :class="colorId"
       :style="{'background-image': 'url(' + thumbUrl + ')',
                'color': textColor ? textColor: '#fff',
                'border': borderColor ? `1px solid ${borderColor}` : null}">
    <span v-if="!thumbUrl">{{ initials }}</span>
  </div>
</template>

<script>
import getColorId from '@/edpack-web/utils/get-color-id'
import secureFilestackUrl from '@/edpack-web/utils/filestack/secure-url'

export default {
  name: 'Thumbnail',
  props: ['model', 'borderColor', 'textColor', 'showFullName'],

  computed: {
    thumbUrl () {
      if (!this.model) return false
      let thumb
      if (this.model.thumbnailUrl) thumb = this.model.thumbnailUrl
      if (this.model.logoUrl) thumb = this.model.logoUrl
      if (this.model.toolLogoUrl) thumb = this.model.toolLogoUrl
      if (typeof this.model === 'string') thumb = this.model
      if (!thumb) return ''
      return secureFilestackUrl(thumb, this.$store.state.user)
    },

    initials () {
      if (!this.model.name || this.model.thumbnailUrl) return
      if (this.showFullName) return this.model.name

      const cleanName = this.model.name.replace(/\s*\(.*?\)\s*/g, '').replace(/\@.*/, '')
      const names = cleanName.split(/[\s.]+/)

      let initials = names[0].substring(0, 1).toUpperCase()

      if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase()
      }

      return initials
    },
    colorId () {
      if (!this.model._id || this.model.thumbnailUrl) return
      return getColorId(this.model._id)
    }
  }
}
</script>

<style lang="scss">
  @use 'style';
</style>
