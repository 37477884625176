<template>
  <header class="app-header clearfix">
    <div class="header-container">

      <!-- Menu icon -->
      <el-button type="text" class="menu-button visible-xs visible-sm" @click="openSidebar" aria-label="Toggle menu">
        <i class="icon-menu"/>
      </el-button>

      <skip-to-main/>

      <!-- Menu buttons-->
      <div class="h-full hidden-xs hidden-sm">
        <el-menu class="header-tabs h-full" mode="horizontal" :default-active="activeTab">
          <el-menu-item v-if="!hideHomeTab" index="home" @click="tabClick('home')">
            <span>{{ $t('SW_HOME') }}</span>
          </el-menu-item>
          <el-menu-item v-if="isAdmin" index="admin" @click="tabClick('admin')">
            <span>{{ $t('SW_DASHBOARD') }}</span>
          </el-menu-item>

          <!-- Credits button -->
          <credits v-if="showCreditsButton" :user="user"/>
        </el-menu>
      </div>

      <section class="logo-wrapper h-full">
        <transition mode="out-in" name="fade-up">
          <div class="org-select visible-xs" v-if="showSchoolSelect">
            <el-select v-model="selectedOrg" :filterable="userOrgs.length > 2" :placeholder="$t('SW_SELECT_SCHOOL')" @change="changeOrg">
              <el-option v-for="org in userOrgs" :key="org._id" :label="org.name[lang]" :value="org._id">
                <i class="icon-school"/>
                <span>{{ org.name[lang] }}</span>
              </el-option>
            </el-select>
          </div>

          <!-- Header logo or text -->
          <router-link class="h-full logo-inner-wrapper" v-else :to="logoClickPath" aria-hidden="true">
            <div v-if="school.appName" class="app-header-title">{{ school.appName }}</div>
            <header-logo v-else/>
          </router-link>
        </transition>
      </section>

      <!-- User dropdown menu -->
      <div class="header-user-container" v-if="user">
        <!-- Toggle organization -->
        <div class="org-select hidden-xs" v-if="userOrgs.length > 1">
          <el-select v-model="selectedOrg" :filterable="userOrgs.length > 2" :placeholder="$t('SW_SELECT_SCHOOL')" @change="changeOrg">
            <el-option v-for="org in userOrgs" :key="org._id" :label="org.name[lang]" :value="org._id">
              <i class="icon-school"/>
              <span>{{ org.name[lang] }}</span>
            </el-option>
          </el-select>
        </div>

        <!-- Show school selection -->
        <div class="org-select visible-xs" v-if="userOrgs.length > 1">
          <el-button type="primary" class="button-square" plain @click="showSchoolSelect = !showSchoolSelect">
            <i class="icon-school"/>
            <span class="hidden-xs">{{ $tc('SW_ORGANIZATION', 2) }}</span>
          </el-button>
        </div>

        <!-- User account -->
        <router-link class="user-account-link" :to="{ name: showProfile ? 'profile' : 'account', params: { slug: school.slug, id: user._id } }" aria-label="Account page">
            <thumbnail tabindex="0" :model="user" class="thumb-user mr-5 thumb-24"/>
            <strong class="hidden-sm-inline hidden-md-inline user-name">{{ user.name }}</strong>
        </router-link>
      </div>
    </div>
  </header>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import HeaderLogo from '@/components/HeaderLogo'
const Credits = defineAsyncComponent(() => import('@/edpack-web/components/Credits'))

export default {
  name: 'AppHeader',
  props: ['openSidebar', 'user'],
  components: { Credits, HeaderLogo },

  data () {
    return {
      showSchoolSelect: false,
      school: this.user.organization,
      activeTab: this.$route.name || 'admin',
      lang: this.$store.state.lang,
      showProfile: this.$config.hasUserProfiles,
      showCreditsButton: this.user.organization.creditsEnabled && this.user.credits && (this.user.role !== 'student'),
      hideHomeTab: this.$config.hideHomeTab,
      selectedOrg: this.user.organization.name[this.user.language]
    }
  },

  computed: {
    userOrgs () {
      const orgs = this.user.organizations
      return orgs.sort((a, b) => { return a.name.en.toLowerCase().localeCompare(b.name.en.toLowerCase()) })
    },
    logoClickPath () { return this.hideHomeTab ? '/admin' : '/home' },
    isAdmin () { return this.user.systemAdmin || this.user.role === 'admin' }
  },

  watch: {
    $route (to) {
      if (['admin', 'home'].includes(to.name)) this.activeTab = to.name
      else this.activeTab = ''
    }
  },

  methods: {
    tabClick (tab) {
      this.activeTab = tab
      const route = { name: tab, params: { slug: this.school.slug } }
      if (this.$route.name !== tab) this.$router.push(route)
    },
    changeOrg (orgID) {
      this.$http.put(`session/context?organization=${orgID}`)
        .then(() => { window.location = window.location.origin })
        .catch((e) => {
          this.$notifyBug(e)
          this.$message({ type: 'error', message: this.$t('SW_GENERIC_ERROR') })
        })
    }
  }
}
</script>
