// Check if app is started using LTI
import { loadCsrfToken, removeCsrfToken } from '@/edpack-web/utils/csrf-handling'
import Axios from 'axios'
import config from 'config'
import router from '@/router'
import store from '@/edpack-web/store'

export default {
  install (app, options) {
    const inLTI = store.state.inLTI
    const i18n = app.config.globalProperties.$i18n
    const $ls = app.config.globalProperties.$ls

    // Directly call load csrf token
    if (!inLTI) loadCsrfToken($ls)

    // Axios for http requests
    Axios.defaults.baseURL = config.api_url
    Axios.defaults.headers.common.Accept = 'application/json'
    Axios.defaults.withCredentials = true
    Axios.defaults.timeout = 30000

    if (inLTI) this.addLtiOrigin()

    // Global error code, others we do in component
    Axios.interceptors.response.use(
      response => response,
      (error) => {
        let errorMessage = false
        let errorType = 'error'
        const status = error.response ? error.response.status : false

        // Unauthenticated
        if (status === 401) {
          removeCsrfToken($ls)
          if (inLTI) router.push({ name: 'error', query: { type: 'LTI_ERROR' } })
          if (store.state.user) {
            Axios.post('users/logout', {})
              .then(() => {
                if (window.self === window.top) sessionStorage.removeItem('origin')
                if (window.fcWidget) window.fcWidget.destroy()
                else router.push({ name: 'landing', query: { redirect: window.location.pathname } })
              })
          }
        }

        // Session timeout
        if (status === 440) {
          removeCsrfToken($ls)
          errorMessage = i18n.global.t('SW_NO_USER_SESSION')
          errorType = 'error'

          app.config.globalProperties.$store.dispatch('setUnsavedChanges', false)
            .then(() => {
              Axios.post('users/logout', {})
                .then(() => {
                  if (window.fcWidget) window.fcWidget.destroy()
                  if (inLTI) router.push({ name: 'error', query: { type: 'LTI_ERROR' } })
                  else router.push({ name: 'landing', query: { redirect: window.location.pathname } })
                  app.config.globalProperties.$message({ message: errorMessage, type: errorType, duration: 6000 })
                })
            })
          return
        }

        // Server crashed
        if (status === 503) errorMessage = i18n.global.t('SW_SERVER_MAINTENANCE', [config.name])

        // Server error
        if (status >= 500 && status !== 503) errorMessage = i18n.global.t('SW_ERROR_LOADING')

        // Return error
        if (errorMessage) app.config.globalProperties.$message({ message: errorMessage, type: errorType, duration: 6000 })
        throw error.response
      }
    )

    // Set axios as $http service
    app.config.globalProperties.$http = Axios
  },

  addLtiOrigin () {
    Axios.interceptors.request
      .use((response) => {
        if (!response.params) response.params = {}
        response.params.origin = 'lti'
        return response
      })
  }
}
