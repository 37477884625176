<template>
  <el-card :body-style="{ padding: '0px' }" class="plan-tile">
    <router-link :to="{ name: 'plan', params: { id: plan._id, slug: school.slug } }" class="plan-link">
      <div v-if="plan.pages && plan.pages.length" class="tile-cover" @mouseleave="hover = false" @mouseover="hover = true" :style="'background-image: url(' + getResizeUrl(plan.coverUrl, { width: 431, height: 140 }) + ')'">
        <span v-for="(page, index) in plan.pages" :key="page._id" class="plan-app" v-show="index < 2">
          <thumbnail :model="page" class="thumb-42 app-thumb"/>
        </span>
        <span class="plan-app-extras" v-if="plan.pages.length > 2"> +{{ plan.pages.length - 2}}</span>
      </div>
      <div v-else class="tile-cover" @mouseleave="hover = false" @mouseover="hover = true" :style="'background-image: url(' + getResizeUrl(plan.coverUrl, { width: 431, height: 140 }) + ')'"/>

      <!-- Title -->
      <div class="tile-title text-ellipsis" :class="{hovered: hover}" @mouseleave="hover = false" @mouseover="hover = true">
        <span v-if="plan.title[lang]">{{ plan.title[lang] }}</span>
        <span v-else-if="plan.title[altLang]">{{ plan.title[lang] }}</span>
        <span v-else>{{ $t('SW_NO_TITLE') }}</span>
      </div>
    </router-link>
    <div class="tile-info">
      <!-- Show description -->
      <p class="tile-desc" v-if="plan.shortDescription[lang]">{{ $filters.truncate(plan.shortDescription[lang], 100) }}</p>
      <p class="tile-desc" v-else-if="plan.shortDescription[altLang]">{{ $filters.truncate(plan.shortDescription[altLang], 100) }}</p>
      <p class="tile-desc text-muted" v-else>{{ $t('SW_NO_DESCRIPTION_YET') }}</p>
    </div>
  </el-card>
</template>

<script>
import getResizeUrl from '@/edpack-web/utils/filestack/get-resize-url'

export default {
  name: 'PlanTile',
  props: ['plan'],

  data () {
    return {
      lang: this.$store.state.user.language,
      school: this.$store.state.school,
      user: this.$store.state.user,
      altLang: this.$store.state.user.language === 'en' ? 'nl' : 'en',
      hover: false
    }
  },
  methods: {
    getResizeUrl (url, options) {
      return getResizeUrl(url, options, this.user)
    }
  }
}
</script>

<style lang="scss">
  @use 'style';
</style>
