<template>
  <el-collapse-transition>
    <section v-show="!collapsed" class="app-info">
      <!-- Categories -->
      <p class="aside-field text-ellipsis" v-for="(category, index) in app.categories"
         :key="index">
        <strong class="mr-10 text-muted">{{ category[lang] }}</strong>
        <span v-for="(filter, filterIndex) in category.filters" :key="filterIndex">
            <router-link
              :to="{name: 'search', query: {categories: [filter._id]}, params: {type: 'apps', slug: school.slug}}"
              class="category">
              {{ filter[lang] }}
            </router-link>
            <span v-if="category.filters.length > (filterIndex + 1)"> & </span>
          </span>
      </p>
      <span v-if="!app.categories.length"
            class="text-muted">{{ $t('SW_NO_CATEGORY') }}</span>

      <p class="aside-field text-ellipsis" v-if="app.websiteUrl">
        <a :href="app.websiteUrl" class="bold" target="_blank">
          <i class="icon-newtab"></i>
          {{cleanUrl}}
        </a>
      </p>

      <!-- Labels -->
      <p class="aside-field aside-tags mt-10" v-if="app.labels[lang].length">
          <span v-for="(label, index) in app.labels[lang]" :key="index">
              <router-link
                :to="{name: 'search', query: {query: label}, params: {type: 'apps', slug: school.slug }}">
                <el-tag class="header-tag">{{label}}</el-tag>
              </router-link>
          </span>
      </p>

      <!-- Badges & rating -->
      <div class="no-overflow">
        <p class="aside-field mt-10" v-if="reviewCount">
          <el-rate v-model="rating" disabled :allow-half="true" show-score text-color="#ff9900"
                   score-template="{value}"></el-rate>
          <span class="review-text">{{reviewCount}} {{ $t('SW_APP_REVIEWS') }}</span>
        </p>
      </div>

      <p class="aside-field mt-5">
        {{ $t('SW_APP_LAST_UPDATE') }}
        <time>{{ $filters.fromNow(app.modifiedDate) }}</time>
      </p>
    </section>
  </el-collapse-transition>
</template>

<script>
import cleanUrl from '@/edpack-web/utils/clean-url'
import numeral from 'numeral'

export default {
  name: 'AppPageInfo',
  props: ['app', 'collapsed'],

  data () {
    return {
      lang: this.$store.state.user.language,
      school: this.$store.state.school
    }
  },

  computed: {
    cleanUrl () {
      return cleanUrl(this.app.websiteUrl)
    },
    reviewCount () {
      const reviews = (this.app.reviews || 0) + (this.app.reviews || 0)
      return reviews ? numeral(reviews).format('0a') : false
    },
    rating () {
      let rating = (this.app.score || 0) + (this.app.score || 0)
      if (this.app.score && this.app.score) rating = rating / 2
      return rating
    }
  }
}
</script>

<style lang="scss">
  @use 'style';
</style>
