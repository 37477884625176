<template>
  <div :class="{ 'multi-lang': school.languages.en && school.languages.nl }">
    <!-- Status -->
    <el-form-item :label="$t('SW_STATUS')">
      <el-select v-model="form.status" class="block" :placeholder="$t('SW_STATUS')">
        <el-option v-for="status in statusOptions" :key="status.value" :label="$t('SW_' + status.value.toUpperCase())" :value="status.value">
          <span :style="{color: status.color}">{{ $t('SW_' + status.value.toUpperCase())}}</span>
        </el-option>
      </el-select>
    </el-form-item>

    <!-- Name -->
    <el-form-item label="Name" prop="name">
      <el-input type="text" v-model="form.name" name="name" id="name"/>
    </el-form-item>

    <!-- Website URL -->
<!--    <el-form-item label="Website URL">-->
<!--      <el-input type="url" v-model="form.websiteUrl" name="websiteUrl" id="websiteUrl">-->
<!--        <template #prepend>-->
<!--          <i class="icon-earth"/>-->
<!--        </template>-->
<!--      </el-input>-->
<!--    </el-form-item>-->

    <el-form-item label="Image">
      <el-input type="text" v-model="form.logoUrl" name="logoUrl" id="logoUrl">
        <template #prepend>
          <el-button type="primary" @click="uploadImage()" :loading="uploadingImage">
            <i class="icon-images"/> <span v-show="form.logoUrl">Click here to store</span>
          </el-button>
        </template>
      </el-input>
    </el-form-item>

    <el-form-item label="Description" class="form-en" v-if="school.languages.en">
      <el-input type="textarea" :rows="3" maxlength="200" show-word-limit v-model="form.description.en" name="descriptionEN" id="descriptionEN"/>
      <el-button type="text" class="mt10" @click="translate('en', 'nl', form.description.en)" v-if="school.languages.nl" :loading="translating.nl">
        Translate to NL
      </el-button>
      <el-input v-if="translation.nl" type="textarea" :rows="3" v-model="translation.nl"/>
    </el-form-item>

    <el-form-item label="Beschrijving" :rows="6" class="form-nl" v-if="school.languages.nl">
      <el-input type="textarea" :rows="3" maxlength="200" show-word-limit v-model="form.description.nl" name="descriptionNL" id="descriptionNL"/>
      <el-button type="text" class="mt10" @click="translate('nl', 'en', form.description.nl)" v-if="school.languages.en" :loading="translating.en">
        Translate to EN
      </el-button>
      <el-input v-if="translation.en" type="textarea" :rows="3" v-model="translation.en"/>
    </el-form-item>

    <el-form-item label="Labels" class="form-en" v-if="school.languages.en">
      <el-select class="block" v-model="form.labels.en" multiple filterable allow-create placeholder="Choose labels ...">
        <el-option v-for="item in form.labels.en" :key="item" :label="item" :value="item"/>
      </el-select>
    </el-form-item>

    <el-form-item label="Labels" class="form-nl" v-if="school.languages.nl">
      <el-select class="block" v-model="form.labels.nl" multiple filterable allow-create placeholder="Kies labels ...">
        <el-option v-for="item in form.labels.nl" :key="item" :label="item" :value="item"/>
      </el-select>
    </el-form-item>

    <!-- Categories -->
    <el-form-item v-for="category in availableCategories" :key="category._id" :label="category[lang]">
      <el-select v-model="form.selectedFilters[category._id]" class="block" multiple filterable :placeholder="$t('SW_SELECT_CATEGORIES')">
        <el-option v-for="filter in category.filters" :key="filter._id" :label="filter[lang]" :value="filter._id"/>
      </el-select>
    </el-form-item>

    <!-- Advice texts -->
    <el-row :gutter="20" class="mb-30" v-if="school.languages.en && (school.explainer.en || school.explainer.nl)">
      <el-col :span="16">
        <!-- Advice EN -->
        <el-form-item :label="$t('SW_ADVICE_TEXT_EN')" class="form-en" id="_formAdviceEn">
          <p>
            {{ $t('SW_TYPE_PLAN_HERE', [$term('plan', 'lc')]) }}
            <el-button size="small" type="text" @click="confirmReset('en')">
              <i class="icon-repeat"/>
              <span>{{ $t('SW_BACK_TO_DEFAULT') }}</span>
            </el-button>
          </p>
          <redactor :config="editorOptions" ref="AdviceEn" v-model="form.advice.en"/>
        </el-form-item>
      </el-col>

      <el-col :span="8">
        <p class="form-help-text">{{ $t('SW_TEMPLATE_EXPLAINER') }}</p>
        <div v-if="school.explainer.en" class="redactor-in mb-30 font-13" v-html="school.explainer.en"/>
        <div class="text-muted" v-else>{{ $t('SW_NO_DATA') }}</div>
      </el-col>
    </el-row>

    <el-row :gutter="20" class="mb-30" v-if="school.languages.nl && (school.explainer.en || school.explainer.nl)">
      <el-col :span="16">
        <!-- Advice NL -->
        <el-form-item :label="$t('SW_ADVICE_TEXT_NL')" class="form-nl" id="_formAdviceNl">
          <p>
            {{ $t('SW_TYPE_PLAN_HERE', [$term('plan', 'lc')]) }}
            <el-button size="small" type="text" @click="confirmReset('nl')">
              <i class="icon-repeat"/>
              <span>{{ $t('SW_BACK_TO_DEFAULT') }}</span>
            </el-button>
          </p>
          <redactor :config="editorOptions" ref="AdviceNl" v-model="form.advice.nl"/>
        </el-form-item>
      </el-col>

      <el-col :span="8">
        <p class="form-help-text">{{ $t('SW_TEMPLATE_EXPLAINER') }}</p>
        <div v-if="school.explainer.nl" class="redactor-in mb-30 font-13" v-html="school.explainer.nl"/>
        <div class="text-muted" v-else>{{ $t('SW_NO_DATA') }}</div>
      </el-col>
    </el-row>

    <div v-if="!school.explainer.en && !school.explainer.nl" class="mb-20">
      <!-- Advice EN -->
      <el-form-item :label="$t('SW_ADVICE_TEXT_EN')" v-if="school.languages.en" class="form-en" id="formAdviceEn">
        <redactor :config="editorOptions" ref="AdviceEn" v-model="form.advice.en"/>
      </el-form-item>
      <!-- Advice NL -->
      <el-form-item :label="$t('SW_ADVICE_TEXT_NL')" v-if="school.languages.nl" class="form-nl" id="formAdviceNl">
        <redactor :config="editorOptions" ref="AdviceNl" v-model="form.advice.nl"/>
      </el-form-item>
    </div>

    <!-- Attachments -->
    <form-attachments :form="form"/>
  </div>
</template>

<script>
import FormAttachments from '@/components/FormAttachments'

import { defineAsyncComponent } from 'vue'
import * as filestack from 'filestack-js'
const Redactor = defineAsyncComponent(() => import('@/edpack-web/components/Redactor'))

export default {
  name: 'AppPageForm',
  props: ['form'],

  components: { FormAttachments, Redactor },

  data () {
    return {
      translating: { nl: false, en: false },
      translation: { nl: '', en: '' },
      uploadingImage: false,
      filestack: filestack.init(this.$config.filestack.key),
      statusOptions: [
        { value: 'draft', color: '#bd7b18' },
        { value: 'published', color: 'green' }
      ],
      editorOptions: {},
      school: this.$store.state.school,
      lang: this.$store.state.user.language,
      availableCategories: this.$store.state.school.categories
    }
  },

  created () {
    if (this.form.status === 'none') this.form.status = 'draft'
  },

  methods: {
    uploadImage () {
      if (!this.form.logoUrl) return

      const security = { policy: this.$store.state.user.filestack.policy, signature: this.$store.state.user.filestack.signature }
      this.filestack.setSecurity(security)

      this.uploadingImage = true
      const transformedUrl = this.filestack.transform(this.form.logoUrl, { resize: { width: 200, height: 200 } })

      this.filestack.storeURL(transformedUrl)
        .then((result) => {
          this.uploadingImage = false

          if (result) {
            this.form.logoUrlFilstackInfo = result
            this.form.logoUrl = result.url
          }
        })
        .catch((e) => {
          this.$notifyBug(err)
          this.uploadingImage = false
        })
    },
    translate (from, to, message) {
      this.translating[to] = true
      this.$http.post('users/translate', { from, to, message })
        .then(res => {
          this.translating[to] = false
          this.translation[to] = res.data.list[0]
        })
        .catch((e) => {
          this.$notifyBug(e)
          this.translating[to] = false
          this.$message({ type: 'error', message: this.$t('SW_GENERIC_ERROR') })
        })
    },
    confirmReset (language) {
      this.$confirm(this.$t('SW_BACK_TO_DEFAULT_CONFIRM'), this.$t('SW_BACK_TO_DEFAULT'), {
        confirmButtonText: this.$t('SW_RESET'),
        cancelButtonText: this.$t('SW_CANCEL')
      }).then(() => {
        this.form.advice[language] = this.school.template[language]
        this.$message({ type: 'success', message: this.$t('SW_BACK_TO_DEFAULT_DONE') })
      })
    }
  }
}
</script>

<style lang="scss">
  @use 'style';
</style>
